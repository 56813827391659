//@flow
import * as React from 'react';
import css from './style.css';
import GdprService from '../../services/gdprService';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';
import { IMAGE_VERSION, PLATFORM_GSNCOM, AWS_URL, APP_VERSION } from '../../constants/matcher';
type PropsT = {
    OptinResponse: any,
    fbUserId: any,
    proceedAfterConsent: func,
    closeModal: func,
    country: string,
    operatingSystem: string,
};
type StateT = {};
export default class Gdpr extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            OptinResponse: props.OptinResponse,
            fbUserId: props.fbUserId,
            SeamUser: props.SeamUser,
        };
        this.continueFromGDPR = this.continueFromGDPR.bind(this);
    }
    componentDidMount() {}
    async continueFromGDPR() {
        const platform = this.props.platform;
        const fromWhere = platform === PLATFORM_GSNCOM ? 'loginToGSNCom' : 'fb_login'
        //Analytics Start
        const logData = {
            eventGroup: 'STARTUP',
            eventCode: 'BUTTON',
            eventSubCode: 'CLICK',
            sessionToken: this.props.SeamUser && this.props.SeamUser.session_token,
            eventAttributes: { ui_element: 'gdpr_consent', from_where: fromWhere, fb_uid: this.state.fbUserId, reference_value_4: APP_VERSION, reference_value_6: this.state.fbUserId, },
            deviceId: this.state.fbUserId,
            country: this.props.country,
            operatingSystem: this.props.operatingSystem,
            app_session_id: this.props.appSessionId,
            eventName: "cs.rewards_and_ui",
        };
        const log = new analyticslog();
        analyticsManagerInstance.logAnalytics(log.setAnalyticsData(logData)).then(() => {});
        //Analytics End
        const data = await GdprService.optInHandler(this.props.fbUserId, platform);
        this.props.proceedAfterConsent();
        this.props.closeModal();
    }
    render() {
        const Gdpr_sprite =  AWS_URL + 'gdpr-sprite-sheet-1.png?ver=' + IMAGE_VERSION;
        const fbUserId = this.state.fbUserId;
        const mesmoPlayerId = this.state.SeamUser && this.state.SeamUser.id;
        const buttonStyle = css['GDPR-sprite-sheet-btn-yes-active'];
        return (
            <div
                id="gdpr-model-popup"
                className={`${css['GDPR-sprite-sheet-popup']} ${css['gdpr-model-popup']}`}
                style={{backgroundImage: `url(${Gdpr_sprite})`}}
            >
                <div id="privacyInformation">
                    <div className={`${css['privacy-header']} ${css['GDPR-sprite-sheet-title']}`} style={{backgroundImage: `url(${Gdpr_sprite})`}}/>
                    <div className={css['information-text']}>
                        We collect data when you play GSN Casino, including name, email, gender,
                        country, device/Facebook ID and IP address.<br/><br/> We use the data, with your
                        consent, to provide the game and send you news and promotions. You can
                        withdraw it at any time, but we need your data to provide you access to the
                        game. <br/><br/>Please provide your consent below:
                    </div>
                    <a
                        className={`${buttonStyle} ${css.buttonClass}`}
                        id="buttonClass"
                        onClick={this.continueFromGDPR}
                        style={{backgroundImage: `url(${Gdpr_sprite})`}}
                    />

                    {/* <div className={css['withdraw-link']}>
                        You can always withdraw your consent &nbsp;
                        <a
                            style={{ color: 'white', textDecoration: 'underline' }}
                            href={`https://gsnsocial.wufoo.com/forms/sq7ogl21ko7e8z/def/field10=${fbUserId}&field12=${mesmoPlayerId}`}
                            target="_blank"
                        >
                            here
                        </a>
                    </div> */}
                </div>
            </div>
        );
    }
}
