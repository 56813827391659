//@flow
import * as React from 'react';
import css from './style.css';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';
import {
    IMAGE_VERSION,
    PLATFORM_GSNCOM,
    AWS_URL,
    GSNCOM_APP_VERSION,
    APP_VERSION,
} from '../../constants/matcher';

 type PropsT = {
    ccpaResponse: any,
    fbUserId: any,
    proceedAfterCCPAConsent: func,
    closeModal: func,
    country: string,
    operatingSystem: string,
};
type StateT = {};

export default class Ccpa extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            isToSChecked: false,
            isCountryChecked: false,
            ccpaResponse: props.ccpaResponse,
            fbUserId: props.fbUserId,
            SeamUser: props.SeamUser,
        };
        this.toggleCheckedResident = this.toggleCheckedResident.bind(this);
        this.continueCcpaAcept = this.continueCcpaAcept.bind(this);
    }
    componentDidMount() {}
    toggleCheckedResident() {
        this.setState({ isCountryChecked: !this.state.isCountryChecked });
    }
    continueCcpaAcept() {
        const fromWhere = this.props.platform === PLATFORM_GSNCOM ? 'loginToGSNCom' : 'fb_login';
        const appVersion = this.props.platform === PLATFORM_GSNCOM ? GSNCOM_APP_VERSION : APP_VERSION;
        //Analytics Start
        const tosLogData = {
            eventGroup: 'STARTUP',
            eventCode: 'CCPA',
            eventSubCode: 'CLICK',
            sessionToken: this.props.SeamUser && this.props.SeamUser.session_token,
            eventAttributes: { ui_element: 'ccpa_tc', from_where: fromWhere, fb_uid: this.state.fbUserId },
            deviceId: this.state.fbUserId,
            country: this.props.country,
            operatingSystem: this.props.operatingSystem,
            eventName: "cs.rewards_and_ui",
        };
        const ccpaLogData = {
            eventGroup: 'STARTUP',
            eventCode: 'CCPA',
            eventSubCode: 'ACCEPT',
            sessionToken: this.props.SeamUser && this.props.SeamUser.session_token,
            eventAttributes: { ui_element: 'ccpa_consent', from_where: fromWhere, fb_uid: this.state.fbUserId },
            deviceId: this.state.fbUserId,
            country: this.props.country,
            operatingSystem: this.props.operatingSystem,
            eventName: "cs.rewards_and_ui",
        };
        const log = new analyticslog();
        analyticsManagerInstance.logAnalytics(log.setAnalyticsData(tosLogData)).then(() => {});
        this.state.isCountryChecked && analyticsManagerInstance.logAnalytics(log.setAnalyticsData(ccpaLogData)).then(() => {});
        //Analytics End
        const consentPayload = [{
                consentType: 'CCPA',
                userInput: this.state.isCountryChecked ? 'ACCEPTED' : 'REJECTED',
                consentVersion: this.state.ccpaResponse.CCPA.version,
                appVersion: appVersion,
            },
            {
                consentType: "PI",
                userInput: this.state.isCountryChecked ? 'ACCEPTED' : 'REJECTED',
                consentVersion: this.state.ccpaResponse.CCPA.version,
                appVersion: appVersion,
            },
          {
            consentType: "TOS",
            userInput: "ACCEPTED",
            consentVersion: this.state.ccpaResponse.TOS.version,
            appVersion: appVersion,
          }];
          const updatedConsentPayload = {
              pi: this.state.isCountryChecked ? 'ACCEPTED' : 'REJECTED',
              tos: "ACCEPTED",
              ccpa: this.state.isCountryChecked ? 'ACCEPTED' : 'REJECTED'
            }
        this.props.proceedAfterCCPAConsent(consentPayload, updatedConsentPayload, true);
        this.props.closeModal();
    }
    render() {
        const ccpaSpriteSheet =  AWS_URL + 'ccpa-sprite-sheet-1.png?ver=' + IMAGE_VERSION;
        const buttonStyle = css['ccpa-sprite-sheet-btn-yes-active'];
        return (
            <div
                id="ccpa-model-popup"
                className={`${css['ccpa-sprite-sheet-popup']} ${css['ccpa-model-popup']}`}
                style={{backgroundImage: `url(${ccpaSpriteSheet})`}}
            >
                <div id="privacyInformation">
                    <div className={`${css['privacy-header']} ${css['ccpa-sprite-sheet-title']}`}
                         style={{backgroundImage: `url(${ccpaSpriteSheet})`}}/>
                    <div style={{ position: 'relative', textAlign: 'left', marginTop:'1%' }}>
                        <div className={css['select-text']}>
                            <span>By continuing to play, you agree to our
                                <a style={{ color: '#04fc0d', textDecoration: 'underline' }}
                                    href={this.state.ccpaResponse.urls.terms}
                                    target="_blank">
                                    Terms of Service
                                </a> and to our use of cookies and similar technology. For more information see our
                                <a style={{ color: '#04fc0d', textDecoration: 'underline' }}
                                    href={this.state.ccpaResponse.urls.privacy}
                                    target="_blank">
                                    Privacy Policy.
                                </a>
                            </span>
                        </div>
                    </div>
                    <div style={{ position: 'relative', textAlign: 'right', marginLeft:'15%', marginTop:'5%' }}>
                        <div
                            className={`${css['select-check-box']} ${
                                css['ccpa-sprite-sheet-check-box']
                            }`}
                            onClick={this.toggleCheckedResident}
                            style={{backgroundImage: `url(${ccpaSpriteSheet})`}}
                        />

                        {this.state.isCountryChecked && (
                            <div
                                id="select-tick"
                                className={`${css['select-tick']} ${
                                    css['ccpa-sprite-sheet-tick-mark']
                                } `}
                                onClick={this.toggleCheckedResident}
                                style={{backgroundImage: `url(${ccpaSpriteSheet})`}}
                            />
                        )}
                        <div className={css['select-text']}>
                            <span>I am a California resident. </span>
                        </div>
                    </div>

                    <a
                        className={`${buttonStyle} ${css.buttonClass}`}
                        id="buttonClass"
                        onClick={this.continueCcpaAcept}
                        style={{backgroundImage: `url(${ccpaSpriteSheet})`}}
                    />
                </div>
            </div>
        );
    }
}
