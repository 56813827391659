'use strict';
import CryptoJS from 'crypto-js';
import {
    CASINO_GSNCOM_APPID_TRACKJS,
    GSN_CASINO_APP_S,
    GSNCOM_APP_VERSION,
} from '../../constants/matcher';

export default function loginToGSN(platform, cb) {
    if (!window.gsnAsyncInit) cb('GSN Login Not initialized', null);
    else {
        const getUrlParameter = (name) => {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        };
        const pload = getUrlParameter('pload');
        const decryptedPLoad = pload && CryptoJS.AES.decrypt(pload, GSN_CASINO_APP_S).toString(CryptoJS.enc.Utf8);
        const decodedPLoad = decryptedPLoad && JSON.parse(decryptedPLoad);
        const userId = decodedPLoad && decodedPLoad.userName;
        const gsnEmail = decodedPLoad && decodedPLoad.emailAddress;
        const password = decodedPLoad && decodedPLoad.password;
        const autoLogin = decodedPLoad && decodedPLoad.autoLogin;
        const sessiontoken = decodedPLoad && decodedPLoad.session_token;
        const deviceToken = decodedPLoad && decodedPLoad.deviceToken;
        let iswebstore = false;
        let credential;
        if(decodedPLoad && decodedPLoad.webstore) {
            iswebstore = true;
        }
        if(sessiontoken) {
            credential = {
                authResponse : {
                    autoLogin:autoLogin,
                    seedUserId: '',
                    userID : userId,
                    webstore : iswebstore,
                    deviceToken,
                    userModel:{
                        profile: {
                            hasGsnPortal:true, // This value will be true only when coming platform as GSN otherwise make false
                            gsnEmail: gsnEmail ? gsnEmail : null  // This value and field required only when new registration otherwise null
                        },
                        credentials: [{
                            type:'session',
                            sessionToken:sessiontoken
                        }],
                    },
                }
            }
        }else{
            credential = {
                authResponse : {
                    autoLogin:autoLogin,
                    seedUserId: '',
                    userID : userId,
                    webstore : iswebstore,
                    userModel:{
                        profile: {
                            hasGsnPortal:true, // This value will be true only when coming platform as GSN otherwise make false
                            gsnEmail: gsnEmail ? gsnEmail : null  // This value and field required only when new registration otherwise null
                        },
                        credentials: [{
                            type:'gsncom',
                            password : password,
                            username : userId,
                        }],
                    },
                }
            }
        }
        window.TrackJS &&  TrackJS.install({
            token: "6b297f00f8d94f0e95795e2ae3c6aca1",
            application: CASINO_GSNCOM_APPID_TRACKJS.toString(),
            userId: userId,
            version: GSNCOM_APP_VERSION
        });
        cb(null, credential);
    }
}

export function getGSNUserProfile(credentials, cb) {
    if (!window.gsnAsyncInit) cb('GSN Login not defined', null);
    else {
        cb(null, credentials);
    }
}
