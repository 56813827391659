/* eslint-disable no-undef */

'use strict';
import PubSub from 'pubsub-js';
import analyticsManagerInstance from './analyticsManager';
import { webglCommunicator } from '../managers/webglCommunicator';
import { UNITY_URL } from '../constants/matcher';

export default function WebglManager(webglPayload) {
    const {facebookUserObject, analyticsLogs, FBResForDevice,logEngineeringEvents,country,operatingSystem,webAppVersion,browserName, loginTimeSyncDelay, appVersionRollOut, analyticSessionId } = webglPayload;
    let webglError;
    window.webglClearIntervalError = function(){
        return clearInterval(webglError)
    };
    const sendLog = function (str){
        //Analytics - ENGINEERING-start
        const log = {
            eventGroup: 'ENGINEERING',
            eventCode: 'ERROR_UNITY_LOAD',
            eventSubCode: str,
            deviceId: FBResForDevice && FBResForDevice.authResponse && FBResForDevice.authResponse.userID,
            eventAttributes: {
                ui_element: 'mini_lobby',
                from_where: 'webgl_manager',
                app_session_id: analyticSessionId,
            },
            info: str,
            sessionToken: facebookUserObject && facebookUserObject.sessionToken,
            country: country,
            operatingSystem: operatingSystem,
            appVersion: appVersionRollOut,
            eventName: "sys.logging",
        };
        logEngineeringEvents && analyticsManagerInstance
            .logAnalytics(analyticsLogs.setAnalyticsData(log))
            .then(() => {});
    };
    //Loading WEBGL
    let webGlUrl = UNITY_URL;
    if(appVersionRollOut){
        const unityUrl = UNITY_URL.split('WebGLBuilds/')[0];
        webGlUrl = unityUrl + 'WebGLBuilds/' + appVersionRollOut;
        console.log('check url for userID::', webGlUrl);
    }

    if (webAppVersion) {
        const unityUrl = UNITY_URL.split('WebGLBuilds/')[0];
        webGlUrl = unityUrl + 'WebGLBuilds/' + webAppVersion;
    }

    const progressJS = document.createElement('script');
    progressJS.type = 'application/javascript';
    progressJS.src = `${webGlUrl}/TemplateData/UnityProgress.js`;
    document.head.appendChild(progressJS);

    const loaderJS = document.createElement('script');
    let gameInstance = {};
    loaderJS.type = 'application/javascript';
    loaderJS.src = `${webGlUrl}/Build/UnityLoader.js`;
    document.head.appendChild(loaderJS);
    loaderJS.onload = function () {
        let count =0;
        HTMLCanvasElement.prototype.getContext = (function(oldFn) {
                return function(type) {
                    window.webglApply  =  oldFn.apply(this, arguments);
                    return  window.webglApply;
                };
        }(HTMLCanvasElement.prototype.getContext));
        console.info("Unity WebGL Loader version=V1");
        gameInstance = UnityLoader.instantiate('gameContainer', `${webGlUrl}/Build/WebGL.json`, {
            onProgress: function (instance, progress) {
                if(count ===0){
                    PubSub.publish('UNITY_PROGRESS_INDICATOR', progress);
                    count=1;
                }
            },
        });
        webglError = setInterval(()=>{
            const errorValue =  window.webglApply && window.webglApply.getError();
            if(parseInt(errorValue)>=0){
                switch(parseInt(errorValue)){
                    case window.webglApply.INVALID_OPERATION:
                                    sendLog('INVALID_OPERATION ERROR');
                                    webglClearIntervalError();
                                    // Due to loading issue as invalid operation error will occur in other cases as well which is not harmful.
                                    // if(browserName==='Chrome'){
                                    //     PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                    // }
                                    break;
                    case window.webglApply.INVALID_ENUM :
                                    sendLog('INVALID_ENUM ERROR');
                                    webglClearIntervalError();
                                    //PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                    break;
                    case window.webglApply.INVALID_VALUE :
                                    sendLog('INVALID_VALUE ERROR');
                                    webglClearIntervalError();
                                    //PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                    break;
                    case window.webglApply.INVALID_FRAMEBUFFER_OPERATION :
                                    sendLog('INVALID_FRAMEBUFFER_OPERATION ERROR');
                                    webglClearIntervalError();
                                    //PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                    break;
                    case window.webglApply.OUT_OF_MEMORY :
                                    sendLog('OUT_OF_MEMORY ERROR');
                                    webglClearIntervalError();
                                    //PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                    break;
                    case window.webglApply.CONTEXT_LOST_WEBGL :
                                    sendLog('CONTEXT_LOST_WEBGL ERROR');
                                    webglClearIntervalError();
                                    //PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                    break;
                    case window.webglApply.NO_ERROR:
                                    console.info('NO ERROR from WebGL');
                }
            }
        }, 2000);
        if(!gameInstance.Module){
            //Analytics - ENGINEERING-start
            const log = {
                eventGroup: 'ENGINEERING',
                eventCode: 'UNITY_LOADER_START_FAIL',
                deviceId: FBResForDevice && FBResForDevice.authResponse && FBResForDevice.authResponse.userID,
                eventAttributes: {
                    ui_element: 'mini_lobby',
                    from_where: 'unity_loader',
                    app_session_id: analyticSessionId,
                },
                sessionToken: facebookUserObject.sessionToken,
                country: country,
                operatingSystem: operatingSystem,
                appVersion: appVersionRollOut,
                eventName: "sys.logging",
            };
            logEngineeringEvents && analyticsManagerInstance
                .logAnalytics(analyticsLogs.setAnalyticsData(log))
                .then(() => {});
            //Analytics - ENGINEERING-End
        }else{
            window.WebGLCommunicator = webglCommunicator.callSelf(webglPayload,gameInstance, loginTimeSyncDelay, appVersionRollOut);
            //window.webglCommunicator
            // setTimeout(()=>{
            //     WebglCommunicator.requestForLoginData('CMD');
            // },3000)

            //Analytics - ENGINEERING-start
            const log = {
                eventGroup: 'ENGINEERING',
                eventCode: 'UNITY_LOADER_START_SUCCESS',
                deviceId: FBResForDevice && FBResForDevice.authResponse && FBResForDevice.authResponse.userID,
                eventAttributes: {
                    ui_element: 'mini_lobby',
                    from_where: 'unity_loader',
                    app_session_id: analyticSessionId,
                },
                sessionToken: facebookUserObject.sessionToken,
                country: country,
                operatingSystem: operatingSystem,
                appVersion: appVersionRollOut,
                eventName: "sys.logging",
            };
            logEngineeringEvents && analyticsManagerInstance
                .logAnalytics(analyticsLogs.setAnalyticsData(log))
                .then(() => {});
            //Analytics - ENGINEERING-End
        }
    };

    loaderJS.onerror = function (message){
        window.TrackJS && TrackJS.track("Failed to load url "+this.src + message);
    };

    window.onerror = function (message, source, lineno, colno, error) {
        console.error('Error ', {
            message:message,
            url: source,
            line:lineno,
            column: colno,
            error:error,
        });
        window.alert = () => '';
        //Analytics - ENGINEERING-start
        const log = {
            eventGroup: 'ENGINEERING',
            eventCode: 'WINDOW_ERROR_UNITY',
            deviceId: FBResForDevice && FBResForDevice.authResponse && FBResForDevice.authResponse.userID,
            eventAttributes: {
                ui_element: 'mini_lobby',
                from_where: message,
                app_session_id: analyticSessionId,
            },
            sessionToken: facebookUserObject && facebookUserObject.sessionToken,
            country: country,
            operatingSystem: operatingSystem,
            appVersion: appVersionRollOut,
            eventName: "sys.logging",
        };
        logEngineeringEvents && analyticsManagerInstance
            .logAnalytics(analyticsLogs.setAnalyticsData(log))
            .then(() => {});
        //Analytics - ENGINEERING-End
        PubSub.publish('UNITY_LOADER_ERROR', true);
    }
}

