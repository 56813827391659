'use strict';
import SeamServices from '../services/seamService';
import logger from '../services/common/logger';
import { APP_VERSION } from '../constants/matcher';

class analyticsManager {
    constructor() {
        if (!analyticsManager.analyticsManagerInstance) {
            analyticsManager.analyticsManagerInstance = this;
        }
        return analyticsManager.analyticsManagerInstance;
    }

    logAnalytics(data) {
        return new Promise((resolve, reject) => {
            if(data.app_session_id && data.eventAttributes) {
                data.eventAttributes.app_session_id = data.app_session_id
            }
            SeamServices.getLogAnalytics(data)
                .then(resolve)
                .catch((error) => {
                    logger.error('LOG ANALYTICS',{error: error.stack});
                    reject(error);
                });
                const playgamiEventAttributes = {
                    app_version: data.appVersion ? data.appVersion : APP_VERSION,
                    country: data.country ? data.country : '',
                    event_group: data.eventGroup ? data.eventGroup : 'MINILOBBY',
                    event_code: data.eventCode ? data.eventCode : '',
                    event_subcode: data.eventSubCode ? data.eventSubCode : '',
                    event_time_client: data.eventTimeClient ? data.eventTimeClient : '',
                    client_session_id: data.app_session_id ? data.app_session_id : '',
                    user_id: data.userId ? data.userId : '',
                    reference: data.eventCode ? data.eventCode : '',
                    message: data.eventCode && data.eventSubCode ? `${data.eventCode} - ${data.eventSubCode}` : data.eventCode ? data.eventCode : '',
                    ...(data.eventName !== "sys.logging" ? data.eventAttributes : {}),
                    // Common Properties
                    cs_loyalty_points: null,
                    cs_loyalty_tier: null,
                    cs_level: null,
                    cs_session_id: data.app_session_id ? data.app_session_id : '',
                    cs_platform: data.eventAttributes && data.eventAttributes.platform === "gsncom" ? "webstore" : "web",
                };

                console.log("Dispatching analytics event:", playgamiEventAttributes);

                window.dispatchEvent(new CustomEvent("logAnalyticsEvent", { detail: { eventName: data.eventName, ...playgamiEventAttributes } }));
        });
    }
}

const analyticsManagerInstance = new analyticsManager();
export default analyticsManagerInstance;
