// @flow
import * as React from 'react';
import css from './style.css';
import PubSub from 'pubsub-js';

import { IMAGE_VERSION, AWS_URL } from '../../constants/matcher';

type PropsT = {
    noOfTimesPlayed: number,
    switchToContainer: func,
    isWebGlLoaded: boolean,
    gameIsOver: boolean,
    isMiniGameOver: func,
    gameRoundNo: number,
    updateTokenBalance: func,
};
type StateT = {};

export default class Navigation extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        if (this.props.isWebGlLoaded) {
            this.props.switchToContainer('auto');
        }
        PubSub.subscribe('WEBGL_LOADED', (target, msg) => {
            if (msg) {
                this.props.switchToContainer('auto');
            }
        });
    }
    render() {
        const isWebGlLoaded = this.props.isWebGlLoaded;
        const text_03 =  AWS_URL + 'text-03.png?ver=' + IMAGE_VERSION;
        return (
            <div>
                {!isWebGlLoaded &&
                    this.props.gameRoundNo === 3 && (
                        <div className={`row  ${css.text_placeholder}`}>
                            <div className={css.almost_there} style={{backgroundImage: `url(${text_03})`}}/>
                        </div>
                    )}
            </div>
        );
    }
}
