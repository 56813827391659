'use strict';
import uuid from 'uuid';
import { GSNCOM_APP_VERSION, APP_VERSION } from './matcher';
const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

class analyticslog {
    constructor() {
        this.analyticsPayload = {
            eventGroup: 'MINILOBBY',
            eventCode: '',
            eventSubCode: '',
            uuid: '',
            timestamp: Date.now(),
            sessionToken: '',
            deviceId: `WEB_ID:`,
            appVersion: APP_VERSION,
            eventAttributes: '',
            country: '',
            operating_system: '',
            eventName: '',
            eventTimeClient: '',
            userId: '',
        };
        this.setAnalyticsData = this.setAnalyticsData.bind(this);
    }

    getFormattedUTCTime() {
        const now = new Date();

        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, '0');
        const day = String(now.getUTCDate()).padStart(2, '0');

        const hours = String(now.getUTCHours()).padStart(2, '0');
        const minutes = String(now.getUTCMinutes()).padStart(2, '0');
        const seconds = String(now.getUTCSeconds()).padStart(2, '0');

        const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');
        const microseconds = '000';

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${microseconds} UTC`;
      }

    setAnalyticsData(data) {
        this.analyticsPayload.app_session_id = data.app_session_id
        this.analyticsPayload.eventGroup = data.eventGroup || 'MINILOBBY';
        this.analyticsPayload.eventCode = data.eventCode || null;
        this.analyticsPayload.eventSubCode = data.eventSubCode || null;
        this.analyticsPayload.uuid = uuid.v1();
        this.analyticsPayload.timestamp = Date.now();
        this.analyticsPayload.sessionToken = data.sessionToken || null;
        this.analyticsPayload.eventAttributes = data.eventAttributes || null;
        this.analyticsPayload.deviceId = `WEB_ID:${data.deviceId || null}`;
        this.analyticsPayload.country = data.country || null;
        this.analyticsPayload.operating_system = data.operatingSystem || null;
        this.analyticsPayload.appVersion = data.appVersion ? data.appVersion : (getUrlParameter('appversion') === 'gsncom' ? GSNCOM_APP_VERSION : APP_VERSION);
        this.analyticsPayload.eventName = data.eventName || null;
        this.analyticsPayload.eventTimeClient = this.getFormattedUTCTime();
        this.analyticsPayload.userId = data.userId ? data.userId : data.deviceId;
        return this.analyticsPayload;
    }
}
export default analyticslog;
