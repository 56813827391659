// @flow
import * as React from 'react';
import css from './style.css';
import { IMAGE_VERSION, AWS_URL } from '../../constants/matcher';

type PropsT = {};
type StateT = {};

export default class NextRound extends React.Component<PropsT, StateT> {
    state = {};

    render() {
        const bgIcon = AWS_URL + 'bg.png?ver=' + IMAGE_VERSION;
        const nxt_round = AWS_URL + 'nxt_round.png?ver=' + IMAGE_VERSION;

        return (
            <div className={css['high-low-game-placeholder']}>
                <div className={css['high-low-game']} style={{backgroundImage: `url(${bgIcon})`}}>
                    <div className={css['nxt-round-placeholder']}>
                        <div className={css['next-round-icon']} style={{backgroundImage: `url(${nxt_round})`}}/>
                    </div>
                </div>
            </div>
        );
    }
}
