/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
// @flow
import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import _ from 'lodash';
import countriesTimezones from 'countries-and-timezones';
import { PROPS } from '../../constants/props';
import audioManager from '../../managers/audioManager';
import loginToFacebook, { getFacebookUserProfile } from '../../services/common/facebook';
import loginToGSN, { getGSNUserProfile } from '../../services/common/gsnLogin';
import {
    FC_ADS_TOGGLE,
    FC_ADS_REFRESH_RATE,
    COUNTRY_LOOKUP,
    RUSSIAN_COUNTRY_LOOKUP,
    ZONE_ID_ADS_FB,
    FC_MINI_GAME_MATH,
    FC_MINI_GAME_MAX_TOKENS,
    FC_EVENTS_ENGINEERING,
    FC_ADS_WHITELIST,
    FC_CONSENT_CONFIG,
    ENG_ERROR,
    ZOOM_REST_LIST,
    PLATFORM_GSNCOM,
    PLATFORM_FB,
    FC_SHOW_HILO,
    FC_LOADING_GAMESLIST,
    FC_WEBGL_ROLLOUT,
    FC_ISPLAYGAMI_PAYMENT,
    ENG_EVENT,
    FC_LOADING_MIGRATIONPOPUP,
    Environment,
    IMAGE_VERSION,
    AWS_URL,
    GSNCOM_APP_VERSION,
    APP_VERSION,
    FACEBOOK_APP_ID
} from '../../constants/matcher';
import SeamServices from '../../services/seamService';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';
import NetworkServices from '../../services/networkServices';
import Gdpr from '../Gdpr';
import Redirection from '../Redirection';
//import Ccpa from '../Ccpa';
import CommonError from '../CommonError';
import AccountInformation from '../AccountInformation';
import PayPalCheckOut from '../PayPalCheckOut';
import IncompatibleBrowser from '../IncompatibleBrowser';
import IncompatibleCountry from '../IncompatibleCountry';
import MiniLobbyLoader from '../MiniLobbyLoader';
import Promises from 'bluebird';
import css from './style.css';
import webglManager from '../../managers/webGlManager';
import webglManagerV2 from '../../managers/webGlManagerV2';
import AdsHome from '../AdsHome';
import AdsGame from '../AdsGame';
import Ads300X50_1 from '../Ads300X50_1';
import Ads300X50_2 from '../Ads300X50_2';
import AdsVideo from '../AdsVideo';
import GoogleVideoAds from '../GoogleVideoAds';
import Settings from '../Settings';
import PreLobbyLoader from '../PreLobbyLoader';
import ProgressBar from '../ProgressBar';
import HighLowGame from '../HighLowGame';
import Navigation from '../Navigation';
import SuccesPurchase from "../SuccesPurchase";
import {
    getOS,
    getCanvasResolutionOnLoad,
    checkZoom,
    getScreenDetails,
    getOnResize,
    setCustomResolution,
} from '../../managers/resolutionManager';
import { getOptionalBuild } from '../../constants/loadOptionalBuild';
import { getBrowserSupport } from '../../managers/browserDetails';
import contactSupport from '../../managers/contactSupport';
import header from './images/Header.png';
import letsgobuuton from './images/Green_Button.png';
import closeButton from './images/Close_button.png'
import seamService from "../../services/seamService";
import loadable from '@loadable/component';
import uuid from 'uuid';

// @ts-ignore
const PaymentComponent = loadable(() => import('../Analytics/Analytics'), { ssr: false });

type PropsT = PROPS;
type StateT = {};
const analyticsLogs = new analyticslog();
const findItem = (data, criteria) => _.find(data, ['_key', criteria]);
export default class Lobby extends Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            webglLoaded: false,
            showWebGL: false,
            authenticateComplete: false,
            adsRefreshRate: 0,
            showAds: false,
            showAds_ccpa_users: true,
            logEngineeringEvents: true,
            userProfileImage: '',
            showGameAds: true,
            adsToken: 'games_highlow',
            adsTargetArguments: { dept: 'games', title: 'highlow', pgid: 'play' },
            adsChannel: ZONE_ID_ADS_FB,
            showGoToLobby: false,
            userFbData: '',
            displayHiLoGame: false,
            displayPreLoader: false,
            FBResForDevice: '',
            seamUser: '',
            isFullScreen: false,
            fullscreenTrueCount: 0,
            isDisconnected: false,
            isContainerError: 0,
            isCommonError: false,
            gameIsOver: props.gameIsOver,
            osName: '',
            renderHeight: '100%',
            renderWidth: '100%',
            maxTokensAllowed: 0,
            tokensUserWon: 0,
            displayMaxTokensReached: false,
            startTime: 0,
            loginTimeSyncDelay: 0,
            screenW: 0,
            screenH: 0,
            miniLobby_debug_popup: false,
            miniH: '',
            miniW: '',
            userAgent: '',
            browserDetails: {},
            webGLSupport: {},
            lobbyStartTime: 0,
            refreshEnabled: false,
            lobbyMusicCached: '',
            fbUserCountryCode: '',
            operatingSystem: '',
            browserName: '',
            consentConfig: '',
            osVersion: '',
            browserVersion: '',
            isEnablePayment: false,
            isAccountInfo: false,
            gameLoadCount: 0,
            currentGameloading: '',
            gameLoading: 0,
            showHighLow: false,
            webglRolloutConfig: '',
            webglRolloutUnityVersion: '',
            userIdToSendToRegister: '',
            countryCode:'',
            showIncompatibleCountry: false,
            showMigrationPopup: false,
            showForceRedirectionPopup: false,
            showCloseButtonOnMigratePopup: false,
            iswebstore: false,
            isPlaygamipayment: true,
            rediPaySes: '',
            rediPayOparam: '',
            rediPayPparam: '',
            redirectUserDisplayPayment: false,
            showRedirectSuccesfulPurchase: false,
            messageToShowInRedirectedPopup: '',
            fromwhereInTos: '',
            userIdForTos: '',
            needToClearIndexedDB: false,
            userSpend: 0,
        };
        this.fbEventHandler = this.fbEventHandler.bind(this);
        this.loginAsync = Promises.promisify(loginToFacebook);
        this.authProceed = Promises.promisify((loginResponse, cb) => {
            cb(null, loginResponse);
        });
        this.getFacebookUserProfileAsync = Promises.promisify(getFacebookUserProfile);
        this.handleConnectionChange = this.handleConnectionChange.bind(this);
        this.showContainer = this.showContainer.bind(this);
        this.proceedAfterConsent = this.proceedAfterConsent.bind(this);
        this.proceedAfterGDPRConsent = this.proceedAfterGDPRConsent.bind(this);
        this.proceedToSeamAuth = this.proceedToSeamAuth.bind(this);
        this.optOutConsent = this.optOutConsent.bind(this);
        this.switchToGame = this.switchToGame.bind(this);
        this.escWinFullScreen = this.escWinFullScreen.bind(this);
        this.exitFromFullScreen = this.exitFromFullScreen.bind(this);
        this.registerEventsESCFullScreen = this.registerEventsESCFullScreen.bind(this);
        this.onInactive = this.onInactive.bind(this);
        this.setScreenDetails = this.setScreenDetails.bind(this);
        this.onresize = this.onresize.bind(this);
        this.getUserTokenMiniGame = this.getUserTokenMiniGame.bind(this);
        this.getFeatureConfigGlobalToggle = this.getFeatureConfigGlobalToggle.bind(this);
        this.getFeatureConfigEngineeringFlag = this.getFeatureConfigEngineeringFlag.bind(this);
        this.getFeatureConfigRefreshRate = this.getFeatureConfigRefreshRate.bind(this);
        this.getToken = this.getToken.bind(this);
        this.updateInputValueH = this.updateInputValueH.bind(this);
        this.updateInputValueW = this.updateInputValueW.bind(this);
        this.setBrowserDetails = this.setBrowserDetails.bind(this);
        this.setResolution = this.setResolution.bind(this); //TEST CODE FOR CHECK RESOLUTION
        this.showDebugger = this.showDebugger.bind(this);
        this.proceedAfterCCPAConsent = this.proceedAfterCCPAConsent.bind(this);
        this.setPubSub = this.setPubSub.bind(this);

        this.gsnEventHandler = this.gsnEventHandler.bind(this);
        this.gsnLoginAsync = Promises.promisify(loginToGSN);
        this.getGSNUserProfileAsync = Promises.promisify(getGSNUserProfile);
        this.proceedAfterConsentForGSNCom = this.proceedAfterConsentForGSNCom.bind(this);
        this.proceedAfterGDPRConsentForGSNCom = this.proceedAfterGDPRConsentForGSNCom.bind(this);
        this.proceedToSeamAuthForGSNCom = this.proceedToSeamAuthForGSNCom.bind(this);
        this.proceedAfterCCPAConsentForGSNCom = this.proceedAfterCCPAConsentForGSNCom.bind(this);
        this.showAccountInfo = this.showAccountInfo.bind(this);
        this.closeAccountInfo = this.closeAccountInfo.bind(this);
        this.logout = this.logout.bind(this);
        this.paymentStatus = this.paymentStatus.bind(this);
        this.paymentCallback = this.paymentCallback.bind(this);
        this.incrementGameNameCounter = this.incrementGameNameCounter.bind(this);
        this.proceedForGDPR = this.proceedForGDPR.bind(this);
        this.checkForRussianCountries = this.checkForRussianCountries.bind(this);
        this.checkForRussianCountriesGsncom = this.checkForRussianCountriesGsncom.bind(this);
        this.proceedForGDPRForGSNCom = this.proceedForGDPRForGSNCom.bind(this);
        this.getHighLowDisplayGameDetails = this.getHighLowDisplayGameDetails.bind(this);
        this.continueCcpaAccept = this.continueCcpaAccept.bind(this);
        this.checkTOSOthers = this.checkTOSOthers.bind(this);
        this.hideMigrationPopup = this.hideMigrationPopup.bind(this);
        this.showCloseButtonOnMigrate = this.showCloseButtonOnMigrate.bind(this);
        this.redirectToNewSite = this.redirectToNewSite.bind(this);
        this.updateTokensInClient = this.updateTokensInClient.bind(this);
        this.openHelpshiftApp = this.openHelpshiftApp.bind(this);
        this.forceredirectToNewSite = this.forceredirectToNewSite.bind(this);
        this.removeJsLayer = this.removeJsLayer.bind(this);

    }
    componentDidMount() {
        this.props.setAppSessionId(uuid.v1());
        const musicEnabled = window.localStorage.getItem('musicEnabled');
        localStorage.setItem(
            'musicEnabled',
            musicEnabled && musicEnabled === 'true' ? 'true' : 'false'
        );
        this.setState({ lobbyStartTime: new Date().getTime() });
        this.setBrowserDetails();
        this.setScreenDetails();
        this.registerEventsESCFullScreen();
        console.log('WHAT IS COUNTRY FOR countriesTimezones = ', this.getCountryCodeForFallBack());
        window.isMainLobby = true;
        audioManager.init({
            baseUrl: this.props.baseUrl,
            isSfxEnabled: this.props.sfxEnabled,
        });
        const { cookies } = this.props;
        let cookieForLobbyAudio = cookies.get('__gsn__lobbymusic');
        if (cookieForLobbyAudio) cookieForLobbyAudio = cookieForLobbyAudio === 'true';
        if (cookieForLobbyAudio) audioManager.playLobbyMusic();
        this.setPubSub();
    }
    componentWillUnmount() {
        document.removeEventListener('FBObjectReady', this.fbEventHandler);
        document.removeEventListener('GSNObjectReady', this.gsnEventHandler);
        document.removeEventListener('fullscreenchange', this.escWinFullScreen);
        document.removeEventListener('webkitfullscreenchange', this.escWinFullScreen);
        document.removeEventListener('mozfullscreenchange', this.escWinFullScreen);
        document.removeEventListener('MSFullscreenChange', this.escWinFullScreen);
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        //Unsubscribe all pubsubs
        PubSub.unsubscribe('UPDATE_BALANCE');
        PubSub.unsubscribe('NETWORK_ERROR');
        PubSub.unsubscribe('SEAM_SERVICE_ERROR');
        PubSub.unsubscribe('UNITY_LOADER_GAME');
        PubSub.unsubscribe('NEW_GAME');
        // PubSub.unsubscribe('WEBGL_LOADED');
        PubSub.unsubscribe('WEBGL_IS_LOADED');
        PubSub.unsubscribe('ESC_FULL_SCREEN');
        PubSub.unsubscribe('UNITY_LOADER_ERROR');
        PubSub.unsubscribe('BLANK_UNITY_LOADER_ERROR');
        PubSub.unsubscribe('OPEN_CONTACT_SUPPORT');
        PubSub.unsubscribe('BLOCK_USER_TYPING');
    }

    updateTokensInClient() {
        PubSub.publish('ADD_TOKENS_METHOD', 'true');
    }

    openHelpshiftApp() {
        HelpshiftWidget("open");
    }

    redirectToNewSite() {
        //analytic log for redirecting in migration popup
        const lodData = {
            eventGroup: 'STARTUP',
            eventCode: 'BUTTON',
            eventSubCode: 'CLICK',
            sessionToken: this.state.seamUser && this.state.seamUser.sessionToken,
            eventAttributes: {
                ui_element: 'New_website_Lets_go',
                reference_value_4: APP_VERSION,
                reference_value_6: this.state.seamUser.profile.gsnUsername,
            },
            deviceId: this.state.seamUser.profile.gsnUsername,
            country: this.state.fbUserCountryCode,
            operatingSystem: this.state.operatingSystem,
            appVersion: GSNCOM_APP_VERSION,
            app_session_id: this.props.appSessionId,
            eventName: "cs.rewards_and_ui",
        };
        const payload = analyticsLogs.setAnalyticsData(lodData);
        analyticsManagerInstance.logAnalytics(payload).then(() => {
        });
        const messageToSend = {
            type : 'redirect',
            msg : {
                data : 'https://classiccasino.com/play?gsnu={{gsnu}}&gsnp={{gsnp}}',
                ingamescreen: true
            }
        };
        window.parent.postMessage(JSON.stringify(messageToSend), '*');
    }

    removeJsLayer() {
        let x = document.getElementById("topSectionOnUnity");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    forceredirectToNewSite() {
        //analytic log for redirecting in migration popup
        const lodData = {
            eventGroup: 'STARTUP',
            eventCode: 'BUTTON',
            eventSubCode: 'CLICK',
            sessionToken: this.state.seamUser && this.state.seamUser.sessionToken,
            eventAttributes: {
                ui_element: 'New_website_auto_redirection',
                reference_value_4: APP_VERSION,
                reference_value_6: this.state.seamUser.profile.gsnUsername,
            },
            deviceId: this.state.seamUser.profile.gsnUsername,
            country: this.state.fbUserCountryCode,
            operatingSystem: this.state.operatingSystem,
            appVersion: GSNCOM_APP_VERSION,
            app_session_id: this.props.appSessionId,
            eventName: "cs.rewards_and_ui",
        };
        const payload = analyticsLogs.setAnalyticsData(lodData);
        analyticsManagerInstance.logAnalytics(payload).then(() => {
        });
        const messageToSend = {
            type : 'redirect',
            msg : {
                data : 'https://classiccasino.com/play?gsnu={{gsnu}}&gsnp={{gsnp}}',
                ingamescreen: true
            }
        };
        window.parent.postMessage(JSON.stringify(messageToSend), '*');
    }

    hideMigrationPopup() {
        //analytic log for hiding migration popup
        const lodData = {
            eventGroup: 'STARTUP',
            eventCode: 'BUTTON',
            eventSubCode: 'CLICK',
            sessionToken: this.state.seamUser && this.state.seamUser.sessionToken,
            deviceId: this.state.seamUser.profile.gsnUsername,
            eventAttributes: {
                ui_element: 'New_website_close',
                fb_uid: this.state.userIdToSendToRegister,
                reference_value_4: APP_VERSION,
                reference_value_6: this.state.userIdToSendToRegister,
            },
            country: this.state.fbUserCountryCode,
            operatingSystem: this.state.operatingSystem,
            appVersion: GSNCOM_APP_VERSION,
            app_session_id: this.props.appSessionId,
            eventName: "cs.rewards_and_ui",
        };
        const payload = analyticsLogs.setAnalyticsData(lodData);
        analyticsManagerInstance.logAnalytics(payload).then(() => {
        });
        this.setState({showMigrationPopup: false});
    }

    showCloseButtonOnMigrate() {
        setTimeout(() => {
            this.setState({showCloseButtonOnMigratePopup: true});
        },30000)
    }

    /////////////////////////////// FOR FB /////////////////////////
    fbEventHandler() {
        console.log('CURTRENT PLATFORM = ', this.props.platform);
        document.body.style.backgroundColor = '#010B40';
        this.setState({ userAgent: window && window.navigator.userAgent });
        const browserDetails = this.state.webGLSupport && this.state.webGLSupport.browserDetails;
        if (window && window.location.href.search(`sjhfgjsdhgfjsgjfgshjfgjsgfjsgjbgcjwuahaiajldjaklsjklahjkasdfhjkahdjkashdfjkahsfkjhakjf`) >= 0) {
            //Do Nothing
        } else {
            this.loginAsync(FACEBOOK_APP_ID)
                .then((loginResponse)=>{
                    const getCountryCodeByIPHandler = function() {
                        return function() {
                            return SeamServices.getCountryCodeByIPHandler();
                        };
                    };
                    const getLoginResponse = function() {
                        return function() {
                            return SeamServices.getPromisifyData(loginResponse);
                        };
                    };
                    //call country code by ip handler here and once its resolved or call back then do login response
                    SeamServices.getCountryCodeByIPHandler().then((countryres) => {
                        const respContry = countryres;
                        const gdprCountry = respContry ? respContry.toUpperCase() : this.getCountryCodeForFallBack();
                        this.setState({ countryCode: gdprCountry });
                    }).catch((err) => {
                        const gdprCountry = this.getCountryCodeForFallBack();
                        this.setState({ countryCode: gdprCountry });
                    })
                    return Promises.resolve([
                        getLoginResponse()
                    ]).mapSeries((method) => {
                        return method();
                    });
                })
                .then((resArray) => {
                    const loginResponse = resArray[0];
                    this.setState({ FBResForDevice: loginResponse });

                    //Analytics End
                    console.log('STEP 1: FB LOGIN ::', loginResponse);
                    const fbUserId =
                        loginResponse &&
                        loginResponse.authResponse &&
                        loginResponse.authResponse.userID;
                    this.props.setFBUserId(fbUserId);
                    this.setState({ userIdToSendToRegister: fbUserId });

                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'BEGIN',
                        eventAttributes: {
                            ui_element: 'loginToFacebook',
                            web_user_agent: !browserDetails.userAgent
                                ? 'UNKNOWN'
                                : browserDetails.userAgent,
                            os_family: !browserDetails.osName ? 'UNKNOWN' : browserDetails.osName,
                            os_version: !browserDetails.osVersion
                                ? 'UNKNOWN'
                                : browserDetails.osVersion,
                            browser_family: !browserDetails.browserName
                                ? 'UNKNOWN'
                                : browserDetails.browserName,
                            browser_version: !browserDetails.browserVersion
                                ? 'UNKNOWN'
                                : browserDetails.browserVersion,
                            fb_uid: fbUserId,
                            os_bit_architecture:
                                browserDetails && browserDetails.osBit
                                    ? browserDetails && browserDetails.osBit
                                    : '',
                            platform: 'web',
                            app_session_id: this.props.appSessionId,
                            reference_value_1: !browserDetails.userAgent
                                ? 'UNKNOWN'
                                : browserDetails.userAgent,
                            reference_value_2: !browserDetails.osName ? 'UNKNOWN' : browserDetails.osName,
                            reference_value_3: !browserDetails.osVersion
                                ? 'UNKNOWN'
                                : browserDetails.osVersion,
                            reference_value_4: !browserDetails.browserName
                                ? 'UNKNOWN'
                                : browserDetails.browserName,
                            reference_value_5: !browserDetails.browserVersion
                                ? 'UNKNOWN'
                                : browserDetails.browserVersion,
                            reference_value_6: fbUserId,
                            reference_value_7: browserDetails && browserDetails.osBit
                                ? browserDetails && browserDetails.osBit
                                : '',
                        },
                        country: this.state.countryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: fbUserId,
                        appVersion: APP_VERSION,
                        app_session_id: this.props.appSessionId,
                        eventName: "cs.rewards_and_ui",
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                        console.log('STEP 2: STARTUP BEGIN LOGGED ::', loginResponse);
                    });
                    //Analytics End
                    const getFeatureConfigData = function(y) {
                        return function() {
                            return SeamServices.getFeatureConfigConsentConfig(y);
                        };
                    };

                    return Promises.resolve([
                        getFeatureConfigData(loginResponse),
                    ]).mapSeries((method) => {
                        return method();
                    });


                })
                .then((resp) => {
                    console.log('STEP 3: CONSENT CONFIG DATA ::', resp);
                    const consentConfig = resp && JSON.parse(resp);
                    this.setState({ consentConfig: consentConfig });
                    return this.getFacebookUserProfileAsync();
                })
                .then((respUserProfile) => {
                    console.log('STEP 4: FB PROFILE SYNC ::', respUserProfile);
                    const fbUserId = respUserProfile && respUserProfile.id;
                    this.setState({
                        userProfileImage:
                            respUserProfile &&
                            respUserProfile.picture &&
                            respUserProfile.picture.data &&
                            respUserProfile.picture.data.url,
                    });
                    this.setState({ userFbData: respUserProfile });
                    SeamServices.getCountryCodeByIPHandler()
                        .then((res) => {
                            const gdprCountry = res ? res.toUpperCase() : '';
                            this.setState({ fbUserCountryCode: gdprCountry });
                            console.log(`STEP 4-0: USER COUNTRY `, gdprCountry);
                            this.checkForRussianCountries(gdprCountry, fbUserId, this.props.platform);
                        })
                        .catch((error) => {
                            //Fallback for GDPR,
                            const gdprCountry = this.getCountryCodeForFallBack();
                            this.setState({ fbUserCountryCode: gdprCountry });
                            this.checkForRussianCountries(gdprCountry, fbUserId, this.props.platform);
                            console.error('Error: Seam getCountryCodeByIPHandler', {
                                message: error,
                            });
                            //Analytics - ENGINEERING-start
                            const errorLog = {
                                eventGroup: ENG_ERROR.eventGroup,
                                eventCode: ENG_ERROR.eventCode,
                                deviceId: fbUserId,
                                eventAttributes: {
                                    ui_element: 'getCountryCodeByIPHandler',
                                    from_where: 'loginToFacebook',
                                    fb_uid: fbUserId,
                                },
                                country: gdprCountry,
                                operatingSystem: this.state.operatingSystem,
                                app_session_id: this.props.appSessionId,
                                eventName: "sys.logging",
                            };
                            this.state.logEngineeringEvents &&
                                analyticsManagerInstance
                                    .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                                    .then(() => {});
                            //Analytics - ENGINEERING-End
                        });
                })
                .catch((error) => {
                    console.error('Error: Seam Service loginAsync', { message: error });
                    const fbUserId =
                        this.state.FBResForDevice &&
                        this.state.FBResForDevice.authResponse &&
                        this.state.FBResForDevice.authResponse.userID;
                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'LOGIN',
                        eventSubCode: 'FAIL',
                        sessionToken: '',
                        eventAttributes: {
                            ui_element: 'loginToFacebook',
                            from_where: 'fb_login',
                            attr1: 'seam',
                            attr2: '1',
                            fb_uid: fbUserId ? null : fbUserId,
                            platform: "web",
                            error_string: error,
                            key_attr3: error,
                            reference_value_5: error,
                            reference_value_6: fbUserId ? null : fbUserId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: fbUserId ? null : fbUserId,
                        appVersion: this.state.webglRolloutConfig,
                        app_session_id: this.props.appSessionId,
                        eventName: "cs.rewards_and_ui",
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {});
                    //Analytics End
                });
        }
    }

    checkForRussianCountries(gdprCountry, fbUserId, platform) {
        if(RUSSIAN_COUNTRY_LOOKUP.includes(this.state.countryCode)) {
            this.setState({showIncompatibleCountry: true});
        }else{
            this.proceedForGDPR(gdprCountry, fbUserId, platform);
        }
    }

    proceedForGDPR(gdprCountry, fbUserId, platform) {
        if (COUNTRY_LOOKUP.includes(gdprCountry)) {
            console.log('STEP 4-1: GDPR STATUS - Required for Country ', gdprCountry);
            SeamServices.validateAndRetrieveHandler(fbUserId, platform)
                .then((data) => {
                    console.log(
                        'STEP 4-1-1: GDPR Consent Need to Accept',
                        data && data.requiresOptIn
                    );
                    if (data && data.requiresOptIn) {
                        //Analytics Start
                        const lodData = {
                            eventGroup: 'STARTUP',
                            eventCode: 'SCREEN',
                            eventSubCode: 'OPEN',
                            sessionToken: this.state.seamUser && this.state.seamUser.session_token,
                            eventAttributes: {
                                ui_element: 'gdpr',
                                from_where: 'authorizeAppWithSeam',
                                fb_uid: fbUserId,
                                platform: "web",
                                reference_value_6: fbUserId,
                            },
                            country: gdprCountry,
                            operatingSystem: this.state.operatingSystem,
                            deviceId: fbUserId,
                            appVersion: this.state.webglRolloutConfig,
                            app_session_id: this.props.appSessionId,
                            eventName: "cs.rewards_and_ui",
                        };
                        const payload = analyticsLogs.setAnalyticsData(lodData);
                        analyticsManagerInstance.logAnalytics(payload).then(() => {
                            console.log('STEP 4-1-2: GDPR STARTUP SCREEN OPEN EVENT TRIGGRED');
                        });
                        //Analytics End
                        this.props.openModal('Gdpr', {
                            OptinResponse: data,
                            fbUserId: fbUserId,
                            SeamUser: this.state.seamUser,
                            proceedAfterConsent: this.proceedAfterGDPRConsent,
                            country: gdprCountry,
                            operatingSystem: this.state.operatingSystem,
                            platform: platform,
                            appSessionId: this.props.appSessionId
                        });
                    } else {
                        this.proceedAfterGDPRConsent();
                    }
                })
                .catch((error) => {
                    console.error('Error: Seam gdpr validateAndRetrieveHandler', {
                        message: error,
                    });
                    //Analytics - ENGINEERING-start
                    const errorLog = {
                        eventGroup: ENG_ERROR.eventGroup,
                        eventCode: ENG_ERROR.eventCode,
                        deviceId: fbUserId,
                        eventAttributes: {
                            ui_element: 'validateAndRetrieveHandler',
                            from_where: 'loginToFacebook',
                            fb_uid: fbUserId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        appVersion: this.state.webglRolloutConfig,
                        app_session_id: this.props.appSessionId,
                        eventName: "sys.logging",
                    };
                    this.state.logEngineeringEvents &&
                        analyticsManagerInstance
                            .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                            .then(() => {});
                    //Analytics - ENGINEERING-End
                    PubSub.publish('SEAM_SERVICE_ERROR', true);
                });
        } else {
            console.log('STEP 4-2: GDPR STATUS - NOT Required for Country ', gdprCountry);
            this.proceedAfterGDPRConsent();
        }
    }
    proceedAfterGDPRConsent() {
        const fbUserId = this.state.userFbData && this.state.userFbData.id;
        if (
            this.state.consentConfig &&
            this.state.consentConfig.CCPA &&
            this.state.consentConfig.CCPA.enabled
        ) {
            SeamServices.userConsentInfo(this.state.FBResForDevice, this.state.fbUserCountryCode)
                .then((resp) => {
                    console.log('STEP 5: CCPA CONSENT REQUIRED ::', resp);
                    const isShowAds = resp && resp.pi.toUpperCase() === 'ACCEPTED' ? false : true;
                    this.setState({ showAds_ccpa_users: isShowAds });
                    if (resp.usa === 'PENDING' || resp.usa ==='REJECTED') {
                        //Analytics Start
                        const logData = {
                            eventGroup: 'STARTUP',
                            eventCode: 'CPRA',
                            eventSubCode: 'REQUEST',
                            sessionToken: this.state.seamUser && this.state.seamUser.session_token,
                            eventAttributes: {
                                ui_element: 'cpra',
                                from_where: 'fb_login',
                                fb_uid: fbUserId,
                                platform: "web",
                            },
                            country: this.state.fbUserCountryCode,
                            operatingSystem: this.state.operatingSystem,
                            deviceId: fbUserId,
                            appVersion: this.state.webglRolloutConfig,
                            app_session_id: this.props.appSessionId,
                            eventName: "cs.rewards_and_ui",
                        };
                        const payload = analyticsLogs.setAnalyticsData(logData);
                        analyticsManagerInstance.logAnalytics(payload).then(() => {
                            console.log('STEP 5-1: STARTUP CCPA REQUEST EVENT TRIGGRED');
                        });
                        //Analytics End
                        this.continueCcpaAccept(resp,'fb', logData);

                    } else {
                        console.log('STEP 5-1: CCPA CONSENT NOT REQUIRED ::', resp.ccpa);
                        this.proceedToSeamAuth(resp, resp, false, this.state.FBResForDevice);
                    }
                    //For other than GDPR and CCPA
                    //NO need to handle for facebook as no as such login page
                })
                .catch((error) => {
                    console.error('Error: Seam Error proceedAfterGDPRConsent', { message: error });
                    //Analytics - ENGINEERING-start
                    const errorLog = {
                        eventGroup: ENG_ERROR.eventGroup,
                        eventCode: ENG_ERROR.eventCode,
                        deviceId: fbUserId,
                        eventAttributes: {
                            ui_element: 'ccpa_consent',
                            from_where: 'fb_login',
                            fb_uid: fbUserId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        appVersion: this.state.webglRolloutConfig,
                        app_session_id: this.props.appSessionId,
                        eventName: "sys.logging",
                    };
                    this.state.logEngineeringEvents &&
                        analyticsManagerInstance
                            .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                            .then(() => {});
                    //Analytics - ENGINEERING-End
                    PubSub.publish('SEAM_SERVICE_ERROR', true);
                });
        } else {
            //this.proceedAfterConsent({});
            this.proceedToSeamAuth({}, {}, false, this.state.FBResForDevice);
        }
    }
    proceedAfterCCPAConsent(consentPayload, updatedConsentPayload, isRecordConsent) {
        //this.proceedAfterConsent(updatedConsentPayload);
        const isShowAds =
            updatedConsentPayload && updatedConsentPayload.pi && updatedConsentPayload.pi.toUpperCase() === 'ACCEPTED'
                ? false
                : true;
        this.setState({ showAds_ccpa_users: isShowAds });
        console.log('STEP 6-1: AFTER CCPA - RECEIVED - consentPayload? ', consentPayload);
        console.log(
            'STEP 6-2: AFTER CCPA - RECEIVED - updatedConsentPayload? ',
            updatedConsentPayload
        );
        console.log('STEP 6-3: AFTER CCPA - RECEIVED - isRecordConsent? ', isRecordConsent);
        this.proceedToSeamAuth(
            consentPayload,
            updatedConsentPayload,
            isRecordConsent,
            this.state.FBResForDevice
        );
    }
    proceedToSeamAuth(consentPayload, updatedConsentPayload, isRecordConsent, fbLogin) {
        console.log('STEP 7: AFTER CCPA PROCEED TO SEAM AUTH');
        const fbUserId = fbLogin && fbLogin.authResponse && fbLogin.authResponse.userID;
        //New Registration Call
        SeamServices.registerDevice(fbUserId, this.state.countryCode).then(() => {
            this.getHighLowDisplayGameDetails();

            this.authProceed(fbLogin)
                .then((loginResponse) => {
                    const authorizeAppWithSeam = function (v) {
                        return function () {
                            return SeamServices.authorizeSeam(v);
                        };
                    };
                    return Promises.resolve([authorizeAppWithSeam(loginResponse)]).mapSeries(
                        (method) => {
                            return method();
                        }
                    );
                })
                .then((authResp) => {
                    const loginTimeSyncDelay = new Date().getTime();
                    this.setState({loginTimeSyncDelay: loginTimeSyncDelay});
                    this.getFeatureConfigMiniGameMath();
                    const seamObj = authResp[0]; //;NEED TO CHANGE AUTH
                    console.log('STEP 7: SEAM AUTH RESPONSE ? ', authResp[0]);
                    this.setState({seamUser: seamObj});

                    //Update HelpshiftWidget Object with the User Id

                    if(seamObj._id){
                        let tags = [];
                        if(seamObj.profile.isPayer) {
                            tags.push('casino_payer');
                        }
                        //get user btds
                        seamService.getUserBTDs(this.state.seamUser._id, this.state.seamUser.sessionToken).then((res) => {
                            this.setState({userSpend: res.value})
                            if(seamObj.profile.isVipPlayer || Number(this.state.userSpend)>5000) {
                                tags.push('casino_vip');
                            }
                            if (this.state.seamUser.meta.psToken) {
                                console.log("Updating seam user id in helpshift widget");
                                helpshiftWidgetConfig.userId = this.state.seamUser._id;
                                window.helpshiftWidgetConfig.userId = this.state.seamUser._id;
                                helpshiftWidgetConfig.userAuthToken = this.state.seamUser.meta.psToken;
                                window.helpshiftWidgetConfig.userAuthToken = this.state.seamUser.meta.psToken;
                                helpshiftWidgetConfig.cifs = {
                                    // Key of the Custom Issue Field
                                    user_id: {
                                        type: "singleline",
                                        value: this.state.seamUser._id,
                                    },
                                    app_version_number: {
                                        type: "singleline",
                                        value: APP_VERSION,
                                    },
                                    scopely_lifetime_spend: {
                                        type: "number",
                                        value: res.value,
                                    }
                                }
                                window.helpshiftWidgetConfig.cifs = {
                                    // Key of the Custom Issue Field
                                    user_id: {
                                        type: "singleline",
                                        value: this.state.seamUser._id,
                                    },
                                    app_version_number: {
                                        type: "singleline",
                                        value: APP_VERSION,
                                    },
                                    scopely_lifetime_spend: {
                                        type: "number",
                                        value: res.value,
                                    }
                                }
                            }
                            helpshiftWidgetConfig.tags = tags;
                            window.helpshiftWidgetConfig.tags = tags;
                            HelpshiftWidget("updateHelpshiftWidgetConfig");
                        })
                    }

                    const seamUserId = seamObj && seamObj._id;
                    const sessionToken = seamObj && seamObj.sessionToken;
                    isRecordConsent &&
                    SeamServices.recordUserConsent(consentPayload, seamUserId, sessionToken).then(
                        () => {
                            const fromWhere = this.state.fromwhereInTos;
                            //Analytics Start
                            const ccpaLogData = {
                                eventGroup: 'STARTUP',
                                eventCode: 'TOS',
                                eventSubCode: 'ACCEPT',
                                sessionToken: this.state.seamUser && this.state.seamUser.session_token,
                                eventAttributes: { ui_element: 'tos', from_where: fromWhere, fb_uid: this.state.userIdForTos, reference_value_4: APP_VERSION },
                                deviceId: this.state.fbUserId,
                                country: this.props.country,
                                operatingSystem: this.props.operatingSystem,
                                app_session_id: this.props.appSessionId,
                            };
                            analyticsManagerInstance.logAnalytics(analyticsLogs.setAnalyticsData(ccpaLogData)).then(() => {});
                            //Analytics End
                        }
                    ).catch((e) => {
                        window.TrackJS && TrackJS.track("TOS Failed "+ e);
                    });
                    SeamServices.linkDevice(sessionToken, fbUserId)
                        .then((resp) => {
                            console.log('STEP 8-1: SEAM LINK DEVICE FOR WEB_ID:', fbUserId);
                            //Analytics Start
                            const lodData = {
                                eventGroup: 'STARTUP',
                                eventCode: 'LOGIN',
                                eventSubCode: 'SUCCESS',
                                sessionToken: sessionToken,
                                eventAttributes: {
                                    ui_element: 'authorizeAppWithSeam',
                                    from_where: 'fb_login',
                                    attr1: 'seam',
                                    attr2: '1',
                                    fb_uid: fbUserId,
                                    platform: "web",
                                    reference_value_6: fbUserId,
                                },
                                country: this.state.fbUserCountryCode,
                                operatingSystem: this.state.operatingSystem,
                                deviceId: fbUserId,
                                userId: seamUserId,
                                appVersion: this.state.webglRolloutConfig,
                                app_session_id: this.props.appSessionId,
                                eventName: "cs.rewards_and_ui",
                            };
                            const payload = analyticsLogs.setAnalyticsData(lodData);
                            analyticsManagerInstance.logAnalytics(payload).then(() => {
                            });
                            //Analytics End
                            //Get fcvale to check if db clear is needed
                            SeamServices.getIsClearDbRequired(sessionToken).then((data) => {
                                this.setState({needToClearIndexedDB: data})
                            }).catch((e) => {
                                console.log("exception occured while reading fc", e);
                            })
                            SeamServices.getRollOutFC(sessionToken)
                                .then((data) => {
                                    let appVersion;
                                    let loaderVersion;
                                    const appVersionValue = data;
                                    const appVersionObj =
                                        appVersionValue && JSON.parse(appVersionValue);
                                    if (appVersionObj && appVersionObj.enabled) {
                                        appVersion = appVersionObj.version;
                                        loaderVersion = appVersionObj.loaderVersion;
                                        const contryCode = this.state.countryCode;
                                        SeamServices.registerDeviceWithAppid(fbUserId, appVersion, contryCode).then(
                                            (res) => {
                                                SeamServices.linkDevice(sessionToken, fbUserId)
                                                    .then((res) => {
                                                        this.proceedAfterConsent(
                                                            updatedConsentPayload,
                                                            loginTimeSyncDelay,
                                                            appVersion,
                                                            loaderVersion,
                                                            appVersionObj
                                                        );
                                                    })
                                                    .catch((e) => {
                                                        console.error("Register Link failed")
                                                        this.proceedAfterConsent(
                                                            updatedConsentPayload,
                                                            loginTimeSyncDelay,
                                                            appVersion,
                                                            loaderVersion,
                                                            appVersionObj
                                                        );
                                                    });
                                            }
                                        ).catch((e) => {
                                            console.error("Register service failed")
                                            this.proceedAfterConsent(
                                                updatedConsentPayload,
                                                loginTimeSyncDelay,
                                                appVersion,
                                                loaderVersion,
                                                appVersionObj
                                            );
                                        });
                                        this.setState({webglRolloutConfig: appVersion});
                                        this.setState({webglRolloutUnityVersion: loaderVersion});

                                        //Analytics Start
                                        const lodData = {
                                            eventGroup: ENG_EVENT.eventGroup,
                                            eventCode: ENG_EVENT.eventCode,
                                            eventSubCode: ENG_EVENT.eventSubCode,
                                            sessionToken: sessionToken,
                                            eventAttributes: {
                                                ui_element: 'authorizeAppWithSeam',
                                                from_where: 'fb_login',
                                                attr1: 'seam',
                                                attr2: '1',
                                                fb_uid: fbUserId,
                                                platform: "web",
                                            },
                                            country: this.state.fbUserCountryCode,
                                            operatingSystem: this.state.operatingSystem,
                                            deviceId: fbUserId,
                                            appVersion: appVersion,
                                            app_session_id: this.props.appSessionId,
                                            eventName: "sys.logging",
                                        };
                                        const payload = analyticsLogs.setAnalyticsData(lodData);
                                        analyticsManagerInstance.logAnalytics(payload).then(() => {
                                        });
                                        //Analytics End
                                    }
                                    window.TrackJS &&
                                    TrackJS.configure({
                                        userId: seamUserId,
                                        sessionId: sessionToken,
                                        version: appVersion ? appVersion : APP_VERSION,
                                    });

                                    setTimeout(() => {
                                        const gl = document
                                            .createElement('canvas')
                                            .getContext('webgl2');
                                        if (!gl) {
                                            if (typeof WebGL2RenderingContext !== 'undefined') {
                                                console.log(
                                                    'your browser appears to support WebGL2 but it might be disabled. Try updating your OS and/or video card drivers'
                                                );
                                                const log = {
                                                    eventGroup: 'ENGINEERING',
                                                    eventCode: 'WEB',
                                                    eventSubCode: 'STATUS',
                                                    deviceId: seamObj && seamObj._id,
                                                    eventAttributes: {
                                                        webgl2_support: 'disabled',
                                                        attr1: 'disabled',
                                                    },
                                                    sessionToken: sessionToken,
                                                    analyticSessionId: this.props.appSessionId,
                                                    eventName: "sys.logging",
                                                };
                                                const payload = analyticsLogs.setAnalyticsData(log);
                                                analyticsManagerInstance.logAnalytics(payload).then(() => {
                                                });
                                                window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "disabled");
                                            } else {
                                                console.log(
                                                    'your browser has no WebGL2 support at all'+seamUserId
                                                );
                                                const log = {
                                                    eventGroup: 'ENGINEERING',
                                                    eventCode: 'WEB',
                                                    eventSubCode: 'STATUS',
                                                    deviceId: seamObj && seamObj._id,
                                                    eventAttributes: {
                                                        webgl2_support: 'false',
                                                        attr1: 'false',
                                                    },
                                                    sessionToken: sessionToken,
                                                    analyticSessionId: this.props.appSessionId,
                                                    eventName: "sys.logging",
                                                };
                                                const payload = analyticsLogs.setAnalyticsData(log);
                                                analyticsManagerInstance.logAnalytics(payload).then(() => {
                                                });
                                                window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "false");

                                            }
                                        } else {
                                            console.log('webgl2 works!');
                                            const log = {
                                                eventGroup: 'ENGINEERING',
                                                eventCode: 'WEB',
                                                eventSubCode: 'STATUS',
                                                deviceId: seamObj && seamObj._id,
                                                eventAttributes: {
                                                    webgl2_support: 'true',
                                                    attr1: 'true',
                                                },
                                                sessionToken: sessionToken,
                                                analyticSessionId: this.props.appSessionId,
                                                eventName: "sys.logging",
                                            };
                                            const payload = analyticsLogs.setAnalyticsData(log);
                                            analyticsManagerInstance.logAnalytics(payload).then(() => {
                                            });
                                            window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "true");
                                        }
                                    }, 100);

                                })
                                .catch((error) => {
                                    //Analytics Start
                                    const lodData = {
                                        eventGroup: ENG_EVENT.eventGroup,
                                        eventCode: ENG_EVENT.eventCode,
                                        eventSubCode: ENG_EVENT.eventSubCode,
                                        sessionToken: sessionToken,
                                        eventAttributes: {
                                            ui_element: 'authorizeAppWithSeam',
                                            from_where: 'fb_login',
                                            attr1: 'seam',
                                            fb_uid: fbUserId,
                                            platform: "web",
                                        },
                                        country: this.state.fbUserCountryCode,
                                        operatingSystem: this.state.operatingSystem,
                                        deviceId: fbUserId,
                                        appVersion: this.state.webglRolloutConfig,
                                        app_session_id: this.props.appSessionId,
                                        eventName: "sys.logging",
                                    };
                                    const payload = analyticsLogs.setAnalyticsData(lodData);
                                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                                    });

                                    setTimeout(() => {
                                        const gl = document
                                            .createElement('canvas')
                                            .getContext('webgl2');
                                        if (!gl) {
                                            if (typeof WebGL2RenderingContext !== 'undefined') {
                                                console.log(
                                                    'your browser appears to support WebGL2 but it might be disabled. Try updating your OS and/or video card drivers'
                                                );
                                                const log = {
                                                    eventGroup: 'ENGINEERING',
                                                    eventCode: 'WEB',
                                                    eventSubCode: 'STATUS',
                                                    deviceId: fbUserId,
                                                    eventAttributes: {
                                                        webgl2_support: 'disabled',
                                                        attr1: 'disabled',
                                                    },
                                                    sessionToken: sessionToken,
                                                    analyticSessionId: this.props.appSessionId,
                                                    eventName: "sys.logging",
                                                };
                                                const payload = analyticsLogs.setAnalyticsData(log);
                                                analyticsManagerInstance.logAnalytics(payload).then(() => {
                                                });
                                                window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "disabled");
                                            } else {
                                                console.log(
                                                    'your browser has no WebGL2 support at all'+seamUserId
                                                );
                                                const log = {
                                                    eventGroup: 'ENGINEERING',
                                                    eventCode: 'WEB',
                                                    eventSubCode: 'STATUS',
                                                    deviceId: fbUserId,
                                                    eventAttributes: {
                                                        webgl2_support: 'false',
                                                        attr1: 'false',
                                                    },
                                                    sessionToken: sessionToken,
                                                    analyticSessionId: this.props.appSessionId,
                                                    eventName: "sys.logging",
                                                };
                                                const payload = analyticsLogs.setAnalyticsData(log);
                                                analyticsManagerInstance.logAnalytics(payload).then(() => {
                                                });
                                                window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "false");

                                            }
                                        } else {
                                            console.log('webgl2 works!');
                                            const log = {
                                                eventGroup: 'ENGINEERING',
                                                eventCode: 'WEB',
                                                eventSubCode: 'STATUS',
                                                deviceId: fbUserId,
                                                eventAttributes: {
                                                    webgl2_support: 'true',
                                                    attr1: 'true',
                                                },
                                                sessionToken: sessionToken,
                                                analyticSessionId: this.props.appSessionId,
                                                eventName: "sys.logging",
                                            };
                                            const payload = analyticsLogs.setAnalyticsData(log);
                                            analyticsManagerInstance.logAnalytics(payload).then(() => {
                                            });
                                            window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "true");
                                        }
                                    }, 100);

                                    //Analytics End
                                    window.TrackJS &&
                                    TrackJS.configure({
                                        userId: seamUserId,
                                        sessionId: sessionToken,
                                        version: APP_VERSION,
                                    });
                                    this.proceedAfterConsent(
                                        updatedConsentPayload,
                                        loginTimeSyncDelay,
                                        undefined
                                    );
                                });
                        })
                        .catch((error) => {
                            console.error('Error: Seam linkDevice', {message: error});
                            //Analytics - ENGINEERING-start
                            const errorLog = {
                                eventGroup: ENG_ERROR.eventGroup,
                                eventCode: ENG_ERROR.eventCode,
                                deviceId: fbUserId,
                                eventAttributes: {
                                    ui_element: 'authorizeAppWithSeam',
                                    from_where: 'linkDevice',
                                    fb_uid: fbUserId,
                                },
                                country: this.state.fbUserCountryCode,
                                operatingSystem: this.state.operatingSystem,
                                appVersion: this.state.webglRolloutConfig,
                                app_session_id: this.props.appSessionId,
                                eventName: "sys.logging",
                            };
                            this.state.logEngineeringEvents &&
                            analyticsManagerInstance
                                .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                                .then(() => {
                                });
                            //Get fcvale to check if db clear is needed
                            SeamServices.getIsClearDbRequired(sessionToken).then((data) => {
                                this.setState({needToClearIndexedDB: data})
                            }).catch((e) => {
                                console.log("exception occured while reading fc", e);
                            })
                            SeamServices.getRollOutFC(sessionToken)
                                .then((data) => {
                                    let appVersion;
                                    let loaderVersion;
                                    const appVersionValue = data;
                                    const appVersionObj =
                                        appVersionValue && JSON.parse(appVersionValue);
                                    if (appVersionObj && appVersionObj.enabled) {
                                        appVersion = appVersionObj.version;
                                        loaderVersion = appVersionObj.loaderVersion;
                                        const contryCode = this.state.countryCode;
                                        SeamServices.registerDeviceWithAppid(fbUserId, appVersion, contryCode).then(
                                            (res) => {
                                                SeamServices.linkDevice(sessionToken, fbUserId)
                                                    .then((res) => {
                                                        this.proceedAfterConsent(
                                                            updatedConsentPayload,
                                                            loginTimeSyncDelay,
                                                            appVersion,
                                                            loaderVersion
                                                        );
                                                    })
                                                    .catch((e) => {
                                                        this.proceedAfterConsent(
                                                            updatedConsentPayload,
                                                            loginTimeSyncDelay,
                                                            appVersion,
                                                            loaderVersion
                                                        );
                                                    });
                                            }
                                        );
                                        this.setState({webglRolloutConfig: appVersion});
                                        this.setState({webglRolloutUnityVersion: loaderVersion});
                                        //Analytics Start
                                        const lodData = {
                                            eventGroup: ENG_EVENT.eventGroup,
                                            eventCode: ENG_EVENT.eventCode,
                                            eventSubCode: ENG_EVENT.eventSubCode,
                                            sessionToken: sessionToken,
                                            eventAttributes: {
                                                ui_element: 'authorizeAppWithSeam',
                                                from_where: 'fb_login',
                                                attr1: 'seam',
                                                attr2: '1',
                                                fb_uid: fbUserId,
                                                platform: "web",
                                            },
                                            country: this.state.fbUserCountryCode,
                                            operatingSystem: this.state.operatingSystem,
                                            deviceId: fbUserId,
                                            appVersion: appVersion,
                                            app_session_id: this.props.appSessionId,
                                            eventName: "sys.logging",
                                        };
                                        const payload = analyticsLogs.setAnalyticsData(lodData);
                                        analyticsManagerInstance.logAnalytics(payload).then(() => {
                                        });
                                        //Analytics End
                                    }
                                    window.TrackJS &&
                                    TrackJS.configure({
                                        userId: seamUserId,
                                        sessionId: sessionToken,
                                        version: appVersion ? appVersion : APP_VERSION,
                                    });

                                    setTimeout(() => {
                                        const gl = document
                                            .createElement('canvas')
                                            .getContext('webgl2');
                                        if (!gl) {
                                            if (typeof WebGL2RenderingContext !== 'undefined') {
                                                console.log(
                                                    'your browser appears to support WebGL2 but it might be disabled. Try updating your OS and/or video card drivers'
                                                );
                                                const log = {
                                                    eventGroup: 'ENGINEERING',
                                                    eventCode: 'WEB',
                                                    eventSubCode: 'STATUS',
                                                    deviceId: fbUserId,
                                                    eventAttributes: {
                                                        webgl2_support: 'disabled',
                                                        attr1: 'disabled',
                                                    },
                                                    sessionToken: sessionToken,
                                                    analyticSessionId: this.props.appSessionId,
                                                    eventName: "sys.logging",
                                                };
                                                const payload = analyticsLogs.setAnalyticsData(log);
                                                analyticsManagerInstance.logAnalytics(payload).then(() => {
                                                });
                                                window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "disabled");
                                            } else {
                                                console.log(
                                                    'your browser has no WebGL2 support at all'+seamUserId
                                                );
                                                const log = {
                                                    eventGroup: 'ENGINEERING',
                                                    eventCode: 'WEB',
                                                    eventSubCode: 'STATUS',
                                                    deviceId: fbUserId,
                                                    eventAttributes: {
                                                        webgl2_support: 'false',
                                                        attr1: 'false',
                                                    },
                                                    sessionToken: sessionToken,
                                                    analyticSessionId: this.props.appSessionId,
                                                    eventName: "sys.logging",
                                                };
                                                const payload = analyticsLogs.setAnalyticsData(log);
                                                analyticsManagerInstance.logAnalytics(payload).then(() => {
                                                });
                                                window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "false");

                                            }
                                        } else {
                                            console.log('webgl2 works!');
                                            const log = {
                                                eventGroup: 'ENGINEERING',
                                                eventCode: 'WEB',
                                                eventSubCode: 'STATUS',
                                                deviceId: fbUserId,
                                                eventAttributes: {
                                                    webgl2_support: 'true',
                                                    attr1: 'true',
                                                },
                                                sessionToken: sessionToken,
                                                analyticSessionId: this.props.appSessionId,
                                                eventName: "sys.logging",
                                            };
                                            const payload = analyticsLogs.setAnalyticsData(log);
                                            analyticsManagerInstance.logAnalytics(payload).then(() => {
                                            });
                                            window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "true");
                                        }
                                    }, 100);

                                })
                                .catch((error) => {
                                    //Analytics Start
                                    const lodData = {
                                        eventGroup: ENG_EVENT.eventGroup,
                                        eventCode: ENG_EVENT.eventCode,
                                        eventSubCode: ENG_EVENT.eventSubCode,
                                        sessionToken: sessionToken,
                                        eventAttributes: {
                                            ui_element: 'authorizeAppWithSeam',
                                            from_where: 'fb_login',
                                            attr1: 'seam',
                                            fb_uid: fbUserId,
                                            platform: "web",
                                        },
                                        country: this.state.fbUserCountryCode,
                                        operatingSystem: this.state.operatingSystem,
                                        deviceId: fbUserId,
                                        appVersion: this.state.webglRolloutConfig,
                                        app_session_id: this.props.appSessionId,
                                        eventName: "sys.logging",
                                    };
                                    const payload = analyticsLogs.setAnalyticsData(lodData);
                                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                                    });


                                    setTimeout(() => {
                                        const gl = document
                                            .createElement('canvas')
                                            .getContext('webgl2');
                                        if (!gl) {
                                            if (typeof WebGL2RenderingContext !== 'undefined') {
                                                console.log(
                                                    'your browser appears to support WebGL2 but it might be disabled. Try updating your OS and/or video card drivers'
                                                );
                                                const log = {
                                                    eventGroup: 'ENGINEERING',
                                                    eventCode: 'WEB',
                                                    eventSubCode: 'STATUS',
                                                    deviceId: fbUserId,
                                                    eventAttributes: {
                                                        webgl2_support: 'disabled',
                                                        attr1: 'disabled',
                                                    },
                                                    sessionToken: sessionToken,
                                                    analyticSessionId: this.props.appSessionId,
                                                    eventName: "sys.logging",
                                                };
                                                const payload = analyticsLogs.setAnalyticsData(log);
                                                analyticsManagerInstance.logAnalytics(payload).then(() => {
                                                });
                                                window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "disabled");
                                            } else {
                                                console.log(
                                                    'your browser has no WebGL2 support at all'+seamUserId
                                                );
                                                const log = {
                                                    eventGroup: 'ENGINEERING',
                                                    eventCode: 'WEB',
                                                    eventSubCode: 'STATUS',
                                                    deviceId: fbUserId,
                                                    eventAttributes: {
                                                        webgl2_support: 'false',
                                                        attr1: 'false',
                                                    },
                                                    sessionToken: sessionToken,
                                                    analyticSessionId: this.props.appSessionId,
                                                    eventName: "sys.logging",
                                                };
                                                const payload = analyticsLogs.setAnalyticsData(log);
                                                analyticsManagerInstance.logAnalytics(payload).then(() => {
                                                });
                                                window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "false");

                                            }
                                        } else {
                                            console.log('webgl2 works!');
                                            const log = {
                                                eventGroup: 'ENGINEERING',
                                                eventCode: 'WEB',
                                                eventSubCode: 'STATUS',
                                                deviceId: fbUserId,
                                                eventAttributes: {
                                                    webgl2_support: 'true',
                                                    attr1: 'true',
                                                },
                                                sessionToken: sessionToken,
                                                analyticSessionId: this.props.appSessionId,
                                                eventName: "sys.logging",
                                            };
                                            const payload = analyticsLogs.setAnalyticsData(log);
                                            analyticsManagerInstance.logAnalytics(payload).then(() => {
                                            });
                                            window.TrackJS && TrackJS.addMetadata("is_Webgl_supported", "true");
                                        }
                                    }, 100);

                                    //Analytics End
                                    window.TrackJS &&
                                    TrackJS.configure({
                                        userId: seamUserId,
                                        sessionId: sessionToken,
                                        version: APP_VERSION,
                                    });
                                    this.proceedAfterConsent(
                                        updatedConsentPayload,
                                        loginTimeSyncDelay,
                                        undefined
                                    );
                                });
                            //Analytics - ENGINEERING-End
                        });
                    console.log('STEP 8-2: SEAM AUTH SUCCESSFULL COMPLETED');
                })
                .catch((error) => {
                    window.TrackJS && TrackJS.track(error);
                    console.error('Error: SEAM AUTH FAILED RESPONSE', error, fbLogin);
                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'LOGIN',
                        eventSubCode: 'FAIL',
                        sessionToken: '',
                        eventAttributes: {
                            ui_element: 'authorizeAppWithSeam',
                            from_where: 'fb_login',
                            attr1: 'seam',
                            attr2: '1',
                            fb_uid: fbUserId,
                            platform: "web",
                            error_string: error,
                            key_attr3: error,
                            reference_value_5: error,
                            reference_value_6: fbUserId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: fbUserId,
                        appVersion: this.state.webglRolloutConfig,
                        app_session_id: this.props.appSessionId,
                        eventName: "cs.rewards_and_ui",
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                    });
                    //Analytics End
                    PubSub.publish('SEAM_SERVICE_ERROR', true);
                });
        })
    }
    proceedAfterConsent(consentPayload, loginTimeSyncDelay, appVersionRollOut, loaderVersion, appVersionObj) {
        const fbUserId =
            this.state.FBResForDevice &&
            this.state.FBResForDevice.authResponse &&
            this.state.FBResForDevice.authResponse.userID;
        const seamObject = this.state.seamUser;
        console.log('STEP 10: SEAM OBJECT AFTER LOGIN', seamObject);
        this.props.setSeamSession(seamObject);
        this.setState({ authenticateComplete: true });
        this.getFeatureConfigRefreshRate();
        this.getCountriesWhereAdsCanRefresh();
        this.getFeatureConfigGlobalToggle();
        this.getToken('mini_lobby_load');
        this.getUserTokenMiniGame();
        this.getFeatureConfigEngineeringFlag();
        const webglPayload = {
            facebookUserObject: {
                seamId: seamObject._id,
                name: seamObject.profile.fbName,
                email: seamObject.profile.fbEmail,
                pictureUrl: seamObject.profile.fbPictureUrl,
                sessionToken: seamObject.sessionToken,
                firstName: seamObject.profile.fbFirstName,
                countryCode: this.state.fbUserCountryCode
                    ? this.state.fbUserCountryCode
                    : 'DEFAULT',
                platformUserId: fbUserId,
                lastLoginDate: seamObject.profile.lastLoginDate,
                previousLogin: seamObject.profile.previousLogin,
                firstTimePlayer: seamObject.profile.firstTimePlayer ? true : false,
                outOfToken: seamObject.profile.outOfToken,
                consentPayload: consentPayload,
                platform: this.props.platform,
                loginTimeSyncDelay: 0,
                profile: seamObject.profile,
                userData: seamObject.userData,
                analyticSessionId: this.props.appSessionId
            },
            analyticsLogs: analyticsLogs,
            FBResForDevice: this.state.FBResForDevice,
            logEngineeringEvents: this.state.logEngineeringEvents,
            country: this.state.fbUserCountryCode,
            operatingSystem: this.state.operatingSystem,
            webAppVersion: this.props.unityAppVersion,
            browserName: this.state.browserName,
            loginTimeSyncDelay: loginTimeSyncDelay,
            appVersionRollOut: appVersionRollOut,
            appVersionObj: appVersionObj,
            analyticSessionId: this.props.appSessionId
        };
        const log = {
            eventGroup: 'ENGINEERING',
            eventCode: 'SessionId',
            deviceId: fbUserId,
            eventAttributes: {
                ui_element: 'mini_lobby',
                analyticSessionId: this.props.appSessionId,
            },
            sessionToken: seamObject.sessionToken,
            analyticSessionId: this.props.appSessionId,
            eventName: "sys.logging",
        };
        const payload = analyticsLogs.setAnalyticsData(log);
        analyticsManagerInstance.logAnalytics(payload).then(() => {
        });
        console.log('STEP 11: WEBGL PAYLOAD ', webglPayload);
        this.setState({ startTime: new Date().getTime() });
        setTimeout(() => {
            if (
                this.state.webGLSupport &&
                this.state.webGLSupport.flag &&
                !this.state.isCommonError
            ) {
                if (loaderVersion === 'v2'){
                    //check if need to clear indexed db
                    if (this.state.needToClearIndexedDB === 'true') {
                        //clear db
                        console.log("Clearing Indexed DB")
                        const promise = indexedDB.databases();
                        promise.then((databases) => {
                            console.log("indexed dbs ",databases);
                            databases.forEach((eachDb)=> {
                                indexedDB.deleteDatabase(eachDb);
                                console.log("deleting indexed db",databases);
                            })
                            console.info(`================================== Unity V2 Version :  ${loaderVersion}===========================`);
                            webglManagerV2(webglPayload);
                        }).catch((e) => {
                            console.info(`================================== Unity V2 Version :  ${loaderVersion}===========================`);
                            webglManagerV2(webglPayload);
                        });

                    }else{
                        console.log("Not Clearing Indexed DB")
                        console.info(`================================== Unity V2 Version :  ${loaderVersion}===========================`);
                        webglManagerV2(webglPayload);
                    }
                } else {
                    console.info(`================================== Unity V1 Version :  ${loaderVersion}===========================`);
                    webglManager(webglPayload);
                }

            }
        }, 2000);
        this.incrementGameNameCounter();
    }
    getHighLowDisplayGameDetails() {
        SeamServices.getFeatureConfigDisplayHighLowGame().then((data) => {
            const showHighLow = data;
            this.setState({
                showHighLow: showHighLow && showHighLow.toLowerCase() === 'true' ? true : false,
            });
        });
    }
    ///////////////////////////// ALL COMMON FUNCTIONS

    setPubSub() {
        PubSub.subscribe('REDIRECT_PAYMENT', (target, e) => {
            if (e) {
                this.setState({ rediPaySes : e.st });
                this.setState({ rediPayOparam : e.oparam });
                this.setState({ rediPayPparam : e.pparam });
                window.addEventListener("message", this.paymentCallback);
                this.setState({ redirectUserDisplayPayment : true });
            }
        });
          PubSub.subscribe('CLOSE_PAYMENT_REDIRECTION_POPUP', (target, e) => {
            if (e) {
                this.setState({ redirectUserDisplayPayment : false });
                PubSub.publish('ERROR_OCCURED_IN_PAYMENT', 'true');
            }
          });
          PubSub.subscribe('CLOSE_PAYMENT_REDIRECTION_SUCCESS_POPUP', (target, e) => {
            if (e) {
                this.setState({ showRedirectSuccesfulPurchase : false });
                //call new addtokens method in client
                PubSub.publish('ADD_TOKENS_METHOD', 'true');
            }
          });

        PubSub.subscribe('WEBGL_LOADED', (target, msg) => {
            if (msg) {
                this.setState({ webglLoaded: true });
                // this.setState({showMigrationPopup: false});
                this.props.checkifWebglLoaded(true);
                if (
                    this.props.noOfTimesPlayed === 0 &&
                    this.props.gameRoundNo === 0 &&
                    !this.state.isCommonError
                ) {
                    this.showContainer('auto');
                }
                if (this.props.gameRoundNo !== 0 && !this.state.isCommonError) {
                    this.onInactive(10000, () => {
                        this.showContainer('auto');
                    });
                }
                if (this.state.isAccountInfo == true) {
                    PubSub.publish('BLOCK_USER_TYPING', 'false');
                }
            }
        });

        PubSub.subscribe('USER_ACTIVE', (target, msg) => {
            if (msg) {
                clearTimeout(this.wait);
                this.onInactive(10000, () => {
                    this.showContainer('auto');
                });
            }
        });

        PubSub.subscribe('UPDATE_BALANCE', (target, msg) => {
            if (msg) {
                this.getToken('pubsub_update_balance');
            }
        });
        PubSub.subscribe('NEW_GAME', (target, msg) => {
            const name = msg === 'HOME' ? 'home_home' : 'games_' + msg;
            this.setState({ adsToken: name });
            if (name === 'home_home') {
                this.setState({
                    adsTargetArguments: { dept: 'home', title: 'home', pgid: 'home' },
                });
                this.setState({ showGameAds: false });
            } else {
                this.setState({ adsTargetArguments: { dept: 'games', title: msg, pgid: 'play' } });
                this.setState({ showGameAds: true });
            }
        });
        PubSub.subscribe('NETWORK_ERROR', (target, e) => {
            if (e) {
                this.setState({ isContainerError: 1 });
                this.setState({ isCommonError: true });
            }
        });

        PubSub.subscribe('SEAM_SERVICE_ERROR', (target, e) => {
            if (e) {
                this.setState({ isContainerError: 2 });
                this.setState({ isCommonError: true });
            }
        });
        PubSub.subscribe('UNITY_LOADER_ERROR', (target, e) => {
            if (e) {
                this.setState({ isContainerError: 3 });
                this.setState({ isCommonError: true });
            }
        });

        PubSub.subscribe('BLANK_UNITY_LOADER_ERROR', (target, e) => {
            if (e) {
                this.setState({ isContainerError: 4 });
                this.setState({ isCommonError: true });
            }

        });
        PubSub.subscribe('GSN_PAYPAL_PAYMENT', (target, msg) => {
            if (msg && msg.paymentInit) {
                this.isEnablePayment
                    ? this.setState({ isEnablePayment: false })
                    : this.setState({ isEnablePayment: true });

               if(this.state.isPlaygamipayment) {
                   console.log("call 3");
                   const messageToSend = {
                       type: 'paymentinitiated',
                       msg: {
                           data: msg.item,
                       },
                   };
                   window.parent.postMessage(JSON.stringify(messageToSend), '*');
                   console.log("call 4");
                   window.addEventListener("message", this.paymentCallback);
               }else{
                   this.props.openModal('PayPalCheckOut', {
                       product: msg.item,
                       seamUser: this.state.seamUser,
                       userId: this.state.FBResForDevice.authResponse.userID,
                       paymentStatus: this.paymentStatus,
                   });
               }

            }
        });

        PubSub.subscribe('OPEN_CONTACT_SUPPORT', (target, msg) => {
            if (msg === 'NeedHelp') {

                this.openHelpshiftApp();

                let messageToSend;

                if(this.state.seamUser && this.state.seamUser._id) {

                    messageToSend = {
                        type: 'NeedHelp',
                        msg: {
                            data: {
                                userid: this.state.seamUser._id,
                                userAuthToken: this.state.seamUser.meta.psToken,
                                isPayer: this.state.seamUser.profile.isPayer,
                                isVipPayer: this.state.seamUser.profile.isVipPlayer || Number(this.state.userSpend)>5000,
                                userSpend: this.state.userSpend,
                            },
                        },
                    };
                }else{
                     messageToSend = {
                        type: 'NeedHelp',
                        msg: {
                            data: '',
                        },
                    };

                }
                window.parent.postMessage(JSON.stringify(messageToSend), '*');


                // const url = this.state.seamUser
                //     ? contactSupport(
                //           this.state.seamUser,
                //           this.state.FBResForDevice,
                //           this.state.userAgent,
                //           this.props.seamTokenBalance,
                //           this.state.browserName,
                //           this.state.browserVersion,
                //           this.state.operatingSystem,
                //           this.state.osVersion,
                //           this.props.platform
                //       )
                //     : contactSupport(
                //           '',
                //           this.state.FBResForDevice,
                //           this.state.userAgent,
                //           this.props.seamTokenBalance,
                //           this.state.browserName,
                //           this.state.browserVersion,
                //           this.state.operatingSystem,
                //           this.state.osVersion,
                //           this.props.platform
                //       );
                // window.open(url, '_blank');
            }
        });
    }

    registerEventsESCFullScreen() {
        document.addEventListener('FBObjectReady', this.fbEventHandler);
        document.addEventListener('GSNObjectReady', this.gsnEventHandler);
        document.addEventListener('fullscreenchange', this.escWinFullScreen);
        document.addEventListener('webkitfullscreenchange', this.escWinFullScreen);
        document.addEventListener('mozfullscreenchange', this.escWinFullScreen);
        document.addEventListener('MSFullscreenChange', this.escWinFullScreen);
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener('resize', this.onresize);
    }
    setScreenDetails() {
        const isUserFBgameroom =
            this.state.webGLSupport &&
            this.state.webGLSupport.browserDetails &&
            this.state.webGLSupport.browserDetails.browserName &&
            this.state.webGLSupport.browserDetails.browserName === 'Facebook Gameroom';
        const resolution = getScreenDetails(isUserFBgameroom, this.props.platform);
        this.setState({ renderWidth: resolution.renderWidth });
        this.setState({ renderHeight: resolution.renderHeight });
        this.setState({ screenW: screen.width });
        this.setState({ screenH: screen.height });
        setTimeout(() => {
            console.log('screen adjusting -- 3');
            let a = parseInt(this.state.renderHeight.replace('px','')) + 1;
            let b = parseInt(this.state.renderWidth.replace('px','')) + 1;
            this.setState({ renderHeight: a+'px' });
            this.setState({ renderWidth: b+'px' });
        }, 1000);
    }
    onresize() {
        const browserName =
            this.state.webGLSupport &&
            this.state.webGLSupport.browserDetails &&
            this.state.webGLSupport.browserDetails.browserName;
        const zoom = checkZoom();
        const zoomMaxLevel = this.props.platform === PLATFORM_FB ? 1 : 2;
        if (
            (zoom.zoomLevel <= zoomMaxLevel && zoom.zoomLevel >= 0) ||
            ZOOM_REST_LIST.includes(browserName)
        ) {
            if (this.state.showWebGL) {
                this.setCanvasResolutionOnLoad();
            } else {
                if (!this.state.isFullScreen) {
                    setTimeout(() => {
                        const isUserFBgameroom =
                            this.state.webGLSupport &&
                            this.state.webGLSupport.browserDetails &&
                            this.state.webGLSupport.browserDetails.browserName &&
                            this.state.webGLSupport.browserDetails.browserName ===
                                'Facebook Gameroom';
                        const resolution = getOnResize(isUserFBgameroom, this.props.platform);
                        this.setState({ renderWidth: resolution.renderWidth });
                        this.setState({ renderHeight: resolution.renderHeight });
                        setTimeout(() => {
                            console.log('screen adjusting -- 1');
                            let a = parseInt(this.state.renderHeight.replace('px','')) + 1;
                            this.setState({ renderHeight: a+'px' });
                        }, 1000);
                    }, 100);
                }
            }
        }
    }
    setResolution() {
        const resolution = setCustomResolution(this.state.miniW, this.state.miniH);
        this.setState({ renderWidth: resolution.renderWidth });
        this.setState({ renderHeight: resolution.renderHeight });
    }

    setCanvasResolutionOnLoad() {
        const isUserFBgameroom =
            this.state.webGLSupport &&
            this.state.webGLSupport.browserDetails &&
            this.state.webGLSupport.browserDetails.browserName &&
            this.state.webGLSupport.browserDetails.browserName === 'Facebook Gameroom';
        setTimeout(() => {
            if (!this.state.isFullScreen) {
                const { renderWidth, renderHeight } = getCanvasResolutionOnLoad(
                    isUserFBgameroom,
                    this.props.platform
                );
                this.setState({ renderWidth: renderWidth });
                this.setState({ renderHeight: renderHeight });
                setTimeout(() => {
                    console.log('screen adjusting -- 2');
                    let a = parseInt(this.state.renderHeight.replace('px','')) + 1;
                    let b = parseInt(this.state.renderWidth.replace('px','')) + 1;
                    this.setState({ renderHeight: a+'px' });
                    this.setState({ renderWidth: b+'px' });
                    setTimeout(() => {
                        console.log('screen adjusting -- 2');
                        let a = parseInt(this.state.renderHeight.replace('px','')) + 1;
                        this.setState({ renderHeight: a+'px' });
                    }, 4000);
                }, 1000);
            }
        }, 1000);
    }
    showDebugger() {
        if (this.props.usersToHandle) {
            const miniLobby_debug_popup = this.state.miniLobby_debug_popup;
            this.setState({ miniLobby_debug_popup: !miniLobby_debug_popup });
        } else {
            // NO ACTION NEEDED
        }
    }
    updateInputValueH(evt) {
        this.setState({
            miniH: evt.target.value,
        });
    }
    updateInputValueW(evt) {
        this.setState({
            miniW: evt.target.value,
        });
    }

    exitFromFullScreen() {
        if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement
        ) {
            if (document.exitFullscreen) {
                this.setState({ isFullScreen: false });
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                /* Firefox */
                this.setState({ isFullScreen: false });
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.setState({ isFullScreen: false });
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE/Edge */
                this.setState({ isFullScreen: false });
                document.msExitFullscreen();
            }
        }
    }

    setBrowserDetails() {
        const objWebGLSupport = getBrowserSupport(this.props.fbGameRoomUser);
        const operatingSystem =
            objWebGLSupport &&
            objWebGLSupport.browserDetails &&
            objWebGLSupport.browserDetails.osName;
        const osVersion =
            objWebGLSupport &&
            objWebGLSupport.browserDetails &&
            objWebGLSupport.browserDetails.osVersion;
        const browserName =
            objWebGLSupport &&
            objWebGLSupport.browserDetails &&
            objWebGLSupport.browserDetails.browserName;
        const browserVersion =
            objWebGLSupport &&
            objWebGLSupport.browserDetails &&
            objWebGLSupport.browserDetails.browserVersion;
        this.setState({ operatingSystem: operatingSystem });
        this.setState({ webGLSupport: objWebGLSupport });
        this.setState({ browserName: browserName });
        this.setState({ browserVersion: browserVersion });
        this.setState({ osVersion: osVersion });
    }

    escWinFullScreen(event) {
        event.preventDefault();

        const fullscreenElement =
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement;
        console.log('called OnFullScreenChange Value : ', fullscreenElement ? true : false);
        this.setState({
            isFullScreen: fullscreenElement ? true : false,
        });
        PubSub.publish('ESC_FULL_SCREEN', fullscreenElement ? 'true' : 'false');
    }
    reloadApplication() {
        window && window.location.reload();
    }
    showContainer(triggerType) {
        this.setState({ adsToken: 'home_home' });
        this.setState({ adsTargetArguments: { dept: 'home', title: 'home', pgid: 'home' } });
        this.setState({ showWebGL: this.state.webglLoaded });
        this.setState({ showGameAds: false });
        this.setCanvasResolutionOnLoad();
        this.props.checkContainer(true);
        console.log('onDashboardEnter', this.props.seamTokenBalance);
        PubSub.publish('WEBGL_IS_LOADED', {
            seamTokenBalance: this.props.seamTokenBalance,
            timeSpent: new Date().getTime() - this.state.startTime,
            musicEnabled: window.localStorage.getItem('musicEnabled'),
            loadType: triggerType,
        });
        clearTimeout(this.wait);
        PubSub.unsubscribe('WEBGL_LOADED');
        PubSub.unsubscribe('USER_ACTIVE');
    }
    getUserTokenMiniGame() {
        const userId =
            this.props.platform === PLATFORM_GSNCOM
                ? this.state.FBResForDevice.authResponse.userID
                : this.state.FBResForDevice.authResponse.userID;
        SeamServices.getUserGameToken(this.state.seamUser)
            .then((data) => {
                console.log('DATA FROM GAME TOKEN', data);
                this.setState({ tokensUserWon: data });
                this.getFeatureConfigMaxTokens();
            })
            .catch((error) => {
                console.error('Error:: Seam getUserGameToken', {
                    message: error,
                });
                //Analytics - ENGINEERING-start
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    eventSubCode: 'GET_USER_MINI_TOKEN',
                    eventAttributes: {
                        ui_element: 'proceedAfterConsentMethod',
                        from_where: 'mini_lobby',
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    deviceId: userId,
                    appVersion: this.state.webglRolloutConfig,
                    app_session_id: this.props.appSessionId,
                    eventName: "sys.logging",
                };
                this.state.logEngineeringEvents &&
                    analyticsManagerInstance
                        .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                        .then(() => {});
                //Analytics - ENGINEERING-End

                PubSub.publish('SEAM_SERVICE_ERROR', error);
            });
    }
    getToken(type) {
        const userId =
            this.props.platform === PLATFORM_GSNCOM
                ? this.state.FBResForDevice.authResponse.userID
                : this.state.FBResForDevice.authResponse.userID;
        SeamServices.tokenBalance(this.state.seamUser)
            .then((data) => {
                this.props.updateTokenBalance(data);
            })
            .catch((error) => {
                console.error('Error:: Seam tokenBalance', {
                    message: error,
                });
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    eventSubCode: 'GET_SEAM_TOKEN',
                    eventAttributes: {
                        ui_element: 'proceedAfterConsentMethod',
                        from_where: type,
                    },
                    deviceId: userId,
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    appVersion: this.state.webglRolloutConfig,
                    app_session_id: this.props.appSessionId,
                    eventName: "sys.logging",
                };
                this.state.logEngineeringEvents &&
                    analyticsManagerInstance
                        .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                        .then(() => {});
                //Analytics - ENGINEERING-End

                PubSub.publish('SEAM_SERVICE_ERROR', error);
            });
    }
    getFeatureConfigGlobalToggle() {
        SeamServices.getFeatureConfigGlobalToggle().then((data) => {
            const adsShow = data;
            this.setState({ showAds: adsShow && adsShow.toLowerCase() === 'true' ? true : false });
        });
    }
    getFeatureConfigEngineeringFlag() {
        const userId =
            this.props.platform === PLATFORM_GSNCOM
                ? this.state.FBResForDevice.authResponse.userID
                : this.state.FBResForDevice.authResponse.userID;
        SeamServices.getFeatureConfigEngineering()
            .then((data) => {
                const legEggnEvent = data;
                this.setState({
                    logEngineeringEvents:
                        legEggnEvent && legEggnEvent.toLowerCase() === 'true' ? true : false,
                });
            })
            .catch((error) => {
                console.error('Error:: Seam getFeatureConfigEngineering', {
                    message: error,
                });
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    eventSubCode: 'FC_EVENTS_ENGINEERING',
                    eventAttributes: {
                        ui_element: 'getFeatureConfigEngineering',
                        from_where: 'getConfigurationWithParams',
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    deviceId: userId,
                    appVersion: this.state.webglRolloutConfig,
                    app_session_id: this.props.appSessionId,
                    eventName: "sys.logging",
                };
                this.state.logEngineeringEvents &&
                    analyticsManagerInstance
                        .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                        .then(() => {});
            });
    }
    getFeatureConfigRefreshRate() {
        SeamServices.getFeatureConfigRefreshRate().then((resp) => {
            const adsRate = resp ? resp : 60;
            this.setState({ adsRefreshRate: adsRate });
            this.props.setRefreshRate(adsRate);
        });
    }
    getCountriesWhereAdsCanRefresh() {
        const userId =
            this.props.platform === PLATFORM_GSNCOM
                ? this.state.FBResForDevice.authResponse.userID
                : this.state.FBResForDevice.authResponse.userID;
        SeamServices.getCountriesWhereAdsCanRefresh()
            .then((data) => {
                const countryList =
                    data &&
                    JSON.parse(data);
                this.setState({
                    refreshEnabled:
                        countryList && countryList.countries.includes(this.state.fbUserCountryCode),
                });
            })
            .catch((error) => {
                console.error('Error:: Seam getCountriesWhereAdsCanRefresh', {
                    message: error,
                });
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    eventSubCode: 'FC_ADS_WHITELIST',
                    eventAttributes: {
                        ui_element: 'getCountriesWhereAdsCanRefresh',
                        from_where: 'getConfigurationWithParams',
                    },
                    deviceId: userId,
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    appVersion: this.state.webglRolloutConfig,
                    app_session_id: this.props.appSessionId,
                    eventName: "sys.logging",
                };
                this.state.logEngineeringEvents &&
                    analyticsManagerInstance
                        .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                        .then(() => {});
            });
    }
    getFeatureConfigMiniGameMath() {
        SeamServices.getFeatureConfigMiniGameMath().then((data) => {
            const gameMathModel = data;
            this.props.setGameMathModel(gameMathModel);
        });
    }
    getFeatureConfigMaxTokens() {
        const userId =
            this.props.platform === PLATFORM_GSNCOM
                ? this.state.FBResForDevice.authResponse.userID
                : this.state.FBResForDevice.authResponse.userID;
        const seamAuth = {
            id: this.state.seamUser._id,
            sessionToken: this.state.seamUser.sessionToken,
        };
        SeamServices.getFeatureConfigMaxTokens()
            .then((data) => {
                let maxTokensAllowed = data;
                maxTokensAllowed = maxTokensAllowed && parseInt(maxTokensAllowed);
                if (this.state.tokensUserWon >= maxTokensAllowed) {
                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'SCREEN',
                        eventSubCode: 'OPEN',
                        eventAttributes: {
                            ui_element: 'max_minigame_win_reached',
                            from_where: 'authorizeAppWithSeam',
                            attr2: new Date().getTime() - this.state.startTime,
                            platform: "web",
                            reference_value_6: userId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        sessionToken: seamAuth.sessionToken,
                        deviceId: userId,
                        appVersion: this.state.webglRolloutConfig,
                        app_session_id: this.props.appSessionId,
                        eventName: "cs.rewards_and_ui",
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {});
                    //Analytics End
                    this.setState({ displayMaxTokensReached: true });
                    this.setState({ displayPreLoader: false });
                } else {
                    this.setState({ displayPreLoader: true });
                    this.setState({ displayMaxTokensReached: false });
                }
            })
            .catch((error) => {
                console.error('Error: Seam getFeatureConfigMaxTokens', {
                    message: error,
                });
                //Analytics - ENGINEERING-start
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    eventSubCode: 'GET_FC_MAX_TOKEN',
                    eventAttributes: {
                        ui_element: 'proceedAfterConsentMethod',
                        from_where: 'mini_lobby',
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    deviceId: userId,
                    appVersion: this.state.webglRolloutConfig,
                    app_session_id: this.props.appSessionId,
                    eventName: "sys.logging",
                };
                this.state.logEngineeringEvents &&
                    analyticsManagerInstance
                        .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                        .then(() => {});
                //Analytics - ENGINEERING-End

                PubSub.publish('SEAM_SERVICE_ERROR', error);
            });
    }
    optOutConsent() {
        SeamServices.optOutHandler(this.state.userFbData)
            .then((data) => {
                console.log('GDPR OptOut resp', { message: data });
                return data;
            })
            .catch((error) => {
                console.error('Error: Seam gdpr optOutHandler', { error: error.stack });
                PubSub.publish('SEAM_SERVICE_ERROR', error);
            });
    }
    switchToGame() {
        this.setState({ displayHiLoGame: true });
        this.setState({ displayPreLoader: false });
    }
    onInactive(ms, cb) {
        this.wait = setTimeout(cb, ms);
        document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = function() {
            PubSub.publish('USER_ACTIVE', true);
        };
    }
    handleConnectionChange = () => {
        console.log('navigator.onLine ? ', navigator.onLine);
        const condition = navigator.onLine ? 'online' : 'offline';
        //Commented to check firefox bug
        /*if (condition === 'online') {
            const webPing = setInterval(() => {
                NetworkServices.checkNetwork()
                    .then((_) => {
                        this.setState({ isDisconnected: false }, () => {
                            return clearInterval(webPing);
                        });
                    })
                    .catch((_) => {
                        this.setState({ isDisconnected: true });
                        PubSub.publish('NETWORK_ERROR', true);
                    });
            }, 2000);
            return;
        }*/
        this.setState({ gameIsOver: true });
        this.setState({ displayHiLoGame: false });
        this.setState({ displayPreLoader: false });
        PubSub.publish('NETWORK_ERROR', true);
        return this.setState({ isDisconnected: true });
    };
    getClassFooter(w) {
        if (getOS() !== 'Windows') {
            if (w >= 1920) {
                return css['footer-placeholder-container-1920'];
            } else if (w >= 1680) {
                return css['footer-placeholder-container'];
            } else if (w >= 1440) {
                return css['footer-placeholder-container-1440'];
            } else if (w >= 1280) {
                return css['footer-placeholder-container-1280'];
            }
            return css['footer-placeholder-container'];
        }
        return css['footer-placeholder-container'];
    }
    incrementGameNameCounter() {
        SeamServices.getFeatureConfigGamesList().then((data) => {
            const GAMES_TOUPDATE = data.replace(/'/g, '').split(',');
            this.setState({
                gameLoading: setInterval(() => {
                    const gameLoadCount = this.state.gameLoadCount;
                    if (gameLoadCount <= GAMES_TOUPDATE.length) {
                        this.setState({ gameLoadCount: gameLoadCount + 1 });
                        this.setState({ currentGameloading: GAMES_TOUPDATE[gameLoadCount] });
                        console.log(' GAME LOADING =', GAMES_TOUPDATE[gameLoadCount]);
                    } else {
                        console.log(
                            '========================== CLEAR GAME LOADING, ITS DONE============='
                        );
                        clearInterval(this.gameLoading);
                    }
                }, 5000),
            });
        });
    }

    getCountryCodeForFallBack() {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const countryForTimezone = countriesTimezones.getCountryForTimezone(timeZone);
        const gdprCountry =
            countryForTimezone && countryForTimezone.id ? countryForTimezone.id.toUpperCase() : '';
        console.log('WHAT IS COUNTRY FOR countriesTimezones = ', gdprCountry);
        return gdprCountry;
    }
    ////////////////////////////// FOR GSN.COM ///////////////////
    gsnEventHandler() {
        console.log('CURTRENT PLATFORM = ', this.props.platform);
        document.body.style.backgroundColor = '#032c6E';
        document.body.style.overflow = 'hidden';
        this.setState({ userAgent: window && window.navigator.userAgent });
        const browserDetails = this.state.webGLSupport && this.state.webGLSupport.browserDetails;
        window.addEventListener("message", this.paymentCallback);
        if (window && window.location.href.search(`sjhfgjsdhgfjsgjfgshjfgjsgfjsgjbgcjwuahaiajldjaklsjklahjkasdfhjkahdjkashdfjkahsfkjhakjf`) >= 0) {
            //Do Nothing
        } else {
            this.gsnLoginAsync(PLATFORM_GSNCOM)
                .then((loginResponse)=>{
                    document.body.style.backgroundColor = '#210B5D';
                    this.setState({iswebstore: true});
                    // if(loginResponse.authResponse.webstore){
                    //     document.body.style.backgroundColor = '#210B5D';
                    //     this.setState({iswebstore: true});
                    // }

                    const getCountryCodeByIPHandler = function() {
                        return function() {
                            return SeamServices.getCountryCodeByIPHandler();
                        };
                    };
                    const getLoginResponse = function() {
                        return function() {
                            return SeamServices.getPromisifyData(loginResponse);
                        };
                    };
                    SeamServices.getCountryCodeByIPHandler().then((countryres) => {
                        const respContry = countryres;
                        const gdprCountry = respContry ? respContry.toUpperCase() : this.getCountryCodeForFallBack();
                        this.setState({ countryCode: gdprCountry });
                    }).catch((err) => {
                        const gdprCountry = this.getCountryCodeForFallBack();
                        this.setState({ countryCode: gdprCountry });
                    })
                    return Promises.resolve([
                        getLoginResponse(loginResponse)
                    ]).mapSeries((method) => {
                        return method();
                    });
                })
                .then((resArray) => {
                    console.log("See the response>>>>>>>>>>>",resArray)
                    const loginResponse = resArray[0];
                    this.setState({ FBResForDevice: loginResponse });
                    this.props.setGSNCredentials(loginResponse);
                    //Analytics End
                    console.log('STEP 1: GSN LOGIN ::', loginResponse);
                    const userId =
                        loginResponse &&
                        loginResponse.authResponse &&
                        loginResponse.authResponse.userID;
                    console.log('STEP 1: USER ID ::', userId);
                    this.props.setFBUserId(userId);

                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'BEGIN',
                        eventAttributes: {
                            ui_element: 'loginToGSNCom',
                            web_user_agent: !browserDetails.userAgent
                                ? 'UNKNOWN'
                                : browserDetails.userAgent,
                            os_family: !browserDetails.osName ? 'UNKNOWN' : browserDetails.osName,
                            os_version: !browserDetails.osVersion
                                ? 'UNKNOWN'
                                : browserDetails.osVersion,
                            browser_family: !browserDetails.browserName
                                ? 'UNKNOWN'
                                : browserDetails.browserName,
                            browser_version: !browserDetails.browserVersion
                                ? 'UNKNOWN'
                                : browserDetails.browserVersion,
                            fb_uid: userId,
                            os_bit_architecture:
                                browserDetails && browserDetails.osBit
                                    ? browserDetails && browserDetails.osBit
                                    : '',
                            platform: 'gsncom',
                            app_session_id: this.props.appSessionId,
                            reference_value_1: !browserDetails.userAgent
                            ? 'UNKNOWN'
                            : browserDetails.userAgent,
                            reference_value_2: !browserDetails.osName ? 'UNKNOWN' : browserDetails.osName,
                            reference_value_3: !browserDetails.osVersion
                                ? 'UNKNOWN'
                                : browserDetails.osVersion,
                            reference_value_4: !browserDetails.browserName
                                ? 'UNKNOWN'
                                : browserDetails.browserName,
                            reference_value_5: !browserDetails.browserVersion
                                ? 'UNKNOWN'
                                : browserDetails.browserVersion,
                            reference_value_6: userId,
                            reference_value_7: browserDetails && browserDetails.osBit
                                ? browserDetails && browserDetails.osBit
                            : '',
                        },
                        country: this.state.countryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: userId,
                        appVersion: GSNCOM_APP_VERSION,
                        app_session_id: this.props.appSessionId,
                        eventName: "cs.rewards_and_ui",
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                        console.log('STEP 2: STARTUP BEGIN LOGGED ::', loginResponse);
                    });
                    //Analytics End
                    const getFeatureConfigData = function(y) {
                        return function() {
                            return SeamServices.getFeatureConfigConsentConfig(y);
                        };
                    };
                    this.setState({ userIdToSendToRegister: userId });
                    return Promises.resolve([
                        getFeatureConfigData(loginResponse),
                        // registerDevice(userId, gdprCountry),
                    ]).mapSeries((method) => {
                        return method();
                    });
                })
                .then((resp) => {
                    console.log('STEP 3: GSN CONSENT CONFIG DATA ::', resp);
                    const gsnUserCredentials = this.state.FBResForDevice;
                    const userId =
                        gsnUserCredentials &&
                        gsnUserCredentials.authResponse &&
                        gsnUserCredentials.authResponse.userID;
                    const consentConfig = resp && JSON.parse(resp);
                    this.setState({ consentConfig: consentConfig });
                    this.setState({
                        userProfileImage:
                            gsnUserCredentials &&
                            gsnUserCredentials.picture &&
                            gsnUserCredentials.picture.data &&
                            gsnUserCredentials.picture.data.url,
                    });
                    this.setState({ userFbData: gsnUserCredentials });
                    SeamServices.getCountryCodeByIPHandler()
                        .then((res) => {
                            const gdprCountry = res ? res.toUpperCase() : '';
                            this.setState({ fbUserCountryCode: gdprCountry });
                            console.log(`STEP 4-0: GSN USER COUNTRY `, gdprCountry);
                            this.checkForRussianCountriesGsncom(gdprCountry, userId, this.props.platform);
                        })
                        .catch((error) => {
                            console.error('Error : Seam getCountryCodeByIPHandler', {
                                message: error,
                            });
                            const gdprCountry = this.getCountryCodeForFallBack();
                            this.setState({ fbUserCountryCode: gdprCountry });
                            this.checkForRussianCountriesGsncom(gdprCountry, userId, this.props.platform);
                            //Analytics - ENGINEERING-start
                            const errorLog = {
                                eventGroup: ENG_ERROR.eventGroup,
                                eventCode: ENG_ERROR.eventCode,
                                deviceId: userId,
                                eventAttributes: {
                                    ui_element: 'getCountryCodeByIPHandler',
                                    from_where: 'loginToGSNCom',
                                    fb_uid: userId,
                                    platform: 'gsncom',
                                },
                                country: gdprCountry,
                                operatingSystem: this.state.operatingSystem,
                                appVersion: GSNCOM_APP_VERSION,
                                app_session_id: this.props.appSessionId,
                                eventName: "sys.logging",
                            };
                            this.state.logEngineeringEvents &&
                                analyticsManagerInstance
                                    .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                                    .then(() => {});
                            //Analytics - ENGINEERING-End
                        });
                })
                .catch((error) => {
                    console.error('Error: GSNCOM STARTUP BEGIN gsnLoginAsync', { message: error });
                    const gsnUserCredentials = this.state.FBResForDevice;
                    const userId =
                        gsnUserCredentials &&
                        gsnUserCredentials.authResponse &&
                        gsnUserCredentials.authResponse.userID;
                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'LOGIN',
                        eventSubCode: 'FAIL',
                        sessionToken: '',
                        eventAttributes: {
                            ui_element: 'loginToGSNCom',
                            from_where: 'fb_login',
                            attr1: 'seam',
                            attr2: '1',
                            fb_uid: userId ? null : userId,
                            platform: 'gsncom',
                            error_string: error,
                            key_attr3: error,
                            reference_value_5: error,
                            reference_value_6: userId ? null : userId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: userId ? null : userId,
                        appVersion: GSNCOM_APP_VERSION,
                        app_session_id: this.props.appSessionId,
                        eventName: "cs.rewards_and_ui",
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {});
                    //Analytics End
                });
        }
    }

    checkForRussianCountriesGsncom(gdprCountry, fbUserId, platform) {
        if(RUSSIAN_COUNTRY_LOOKUP.includes(this.state.countryCode)) {
            this.setState({showIncompatibleCountry: true});
        }else{
            this.proceedForGDPRForGSNCom(gdprCountry, fbUserId, platform);
        }
    }

    proceedForGDPRForGSNCom(gdprCountry, userId, platform) {
        if (COUNTRY_LOOKUP.includes(gdprCountry)) {
            console.log('STEP 4-1: GSNCOM GDPR STATUS - Required for Country ', gdprCountry);
            SeamServices.validateAndRetrieveHandler(userId, platform)
                .then((data) => {
                    console.log(
                        'STEP 4-1-1: GSN GDPR Consent Need to Accept',
                        data && data.requiresOptIn
                    );
                    if (data && data.requiresOptIn) {
                        //Analytics Start
                        const lodData = {
                            eventGroup: 'STARTUP',
                            eventCode: 'SCREEN',
                            eventSubCode: 'OPEN',
                            sessionToken: null,
                            eventAttributes: {
                                ui_element: 'gdpr',
                                from_where: 'authorizeAppWithSeam',
                                fb_uid: userId,
                                platform: 'gsncom',
                                reference_value_6: userId,
                            },
                            country: gdprCountry,
                            operatingSystem: this.state.operatingSystem,
                            deviceId: userId,
                            appVersion: GSNCOM_APP_VERSION,
                            app_session_id: this.props.appSessionId,
                            eventName: "cs.rewards_and_ui",
                        };
                        const payload = analyticsLogs.setAnalyticsData(lodData);
                        analyticsManagerInstance.logAnalytics(payload).then(() => {
                            console.log('STEP 4-1-2: GSN GDPR STARTUP SCREEN OPEN EVENT TRIGGRED');
                        });
                        //Analytics End
                        this.props.openModal('Gdpr', {
                            OptinResponse: data,
                            fbUserId: userId,
                            SeamUser: null,
                            proceedAfterConsent: this.proceedAfterGDPRConsentForGSNCom,
                            country: gdprCountry,
                            operatingSystem: this.state.operatingSystem,
                            platform: platform,
                            appSessionId: this.props.appSessionId
                        });
                    } else {
                        this.proceedAfterGDPRConsentForGSNCom();
                    }
                })
                .catch((error) => {
                    console.error('Error : Seam validateAndRetrieveHandler', { message: error });
                    //Analytics - ENGINEERING-start
                    const errorLog = {
                        eventGroup: ENG_ERROR.eventGroup,
                        eventCode: ENG_ERROR.eventCode,
                        deviceId: userId,
                        eventAttributes: {
                            ui_element: 'validateAndRetrieveHandler',
                            from_where: 'loginToGSNCom',
                            fb_uid: userId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        appVersion: GSNCOM_APP_VERSION,
                        app_session_id: this.props.appSessionId,
                        eventName: "sys.logging",
                    };
                    this.state.logEngineeringEvents &&
                        analyticsManagerInstance
                            .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                            .then(() => {});
                    //Analytics - ENGINEERING-End
                    PubSub.publish('SEAM_SERVICE_ERROR', true);
                });
        } else {
            console.log('STEP 4-2: GSN GDPR STATUS - NOT Required for Country ', gdprCountry);
            this.proceedAfterGDPRConsentForGSNCom();
        }
    }
    proceedAfterGDPRConsentForGSNCom() {
        const gsnUserCredentials = this.state.FBResForDevice;
        console.log('WHAT IS CREDENTIALS COMING:::', gsnUserCredentials);
        const userId =
            gsnUserCredentials.authResponse &&
            gsnUserCredentials.authResponse.userID &&
            gsnUserCredentials.authResponse.userID;

        const autoLogin =
            gsnUserCredentials.authResponse &&
            gsnUserCredentials.authResponse.autoLogin &&
            gsnUserCredentials.authResponse.autoLogin;
        const credential =
            gsnUserCredentials.authResponse &&
            gsnUserCredentials.authResponse.userModel &&
            gsnUserCredentials.authResponse.userModel.credentials;
        if (
            this.state.consentConfig &&
            this.state.consentConfig.CCPA &&
            this.state.consentConfig.CCPA.enabled
        ) {
            SeamServices.userConsentInfoGSNCOM(credential, this.state.fbUserCountryCode)
                .then((resp) => {
                    console.log('STEP 5: GSN CCPA CONSENT REQUIRED ::', resp);
                    const isShowAds = resp && resp.pi.toUpperCase() === 'ACCEPTED' ? false : true;
                    this.setState({ showAds_ccpa_users: isShowAds });
                    if (resp.usa === 'PENDING' ||  resp.usa === 'REJECTED') {
                        //Analytics Start
                        const logData = {
                            eventGroup: 'STARTUP',
                            eventCode: 'CPRA',
                            eventSubCode: 'REQUEST',
                            sessionToken: null,
                            eventAttributes: {
                                ui_element: 'cpra',
                                from_where: 'loginToGSNCom',
                                fb_uid: userId,
                                platform: 'gsncom',
                            },
                            country: this.state.fbUserCountryCode,
                            operatingSystem: this.state.operatingSystem,
                            deviceId: userId,
                            appVersion: GSNCOM_APP_VERSION,
                            app_session_id: this.props.appSessionId,
                            eventName: "cs.rewards_and_ui",
                        };
                        const payload = analyticsLogs.setAnalyticsData(logData);
                        analyticsManagerInstance.logAnalytics(payload).then(() => {
                            console.log('STEP 5-1: GSNCOM STARTUP CCPA REQUEST EVENT TRIGGRED');
                        });
                        //Analytics End
                        this.continueCcpaAccept(resp,'gsncom', logData);
                    } else if(resp.tos === 'PENDING' && !autoLogin){
                        this.checkTOSOthers(userId,resp,'gsncom');
                    }else {
                        console.log('STEP 5-1: GSNCOM CCPA CONSENT NOT REQUIRED ::', resp.ccpa);
                        this.proceedToSeamAuthForGSNCom(resp, resp, false, gsnUserCredentials);
                    }
                })
                .catch((error) => {
                    window.TrackJS && TrackJS.track("TOS Failed GDPR "+ error);
                    console.log('Error: Seam gdpr userConsentInfoGSNCOM ', { message: error });
                    //Analytics - ENGINEERING-start
                    const errorLog = {
                        eventGroup: ENG_ERROR.eventGroup,
                        eventCode: ENG_ERROR.eventCode,
                        deviceId: userId,
                        eventAttributes: {
                            ui_element: 'ccpa_consent',
                            from_where: 'loginToGSNCom',
                            fb_uid: userId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        appVersion: GSNCOM_APP_VERSION,
                        app_session_id: this.props.appSessionId,
                        eventName: "sys.logging",
                    };
                    this.state.logEngineeringEvents &&
                        analyticsManagerInstance
                            .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                            .then(() => {});
                    //Analytics - ENGINEERING-End
                    PubSub.publish('SEAM_SERVICE_ERROR', true);
                });
        } else {
            //this.proceedAfterConsent({});
            this.proceedToSeamAuthForGSNCom({}, {}, false, this.state.FBResForDevice);
        }
    }

    checkTOSOthers(userId, resp, from) {
        console.log("TOS for others, user ID#", userId);
        const fromWhere = from === PLATFORM_GSNCOM ? 'loginToGSNCom' : 'fb_login';
        this.setState({ fromwhereInTos: fromWhere });
        this.setState({ userIdForTos: userId });
        const appVersion = from === PLATFORM_GSNCOM ? GSNCOM_APP_VERSION : APP_VERSION;
        //TOD:: PI info
        const consentPayload = [
          {
            consentType: "TOS",
            userInput: "ACCEPTED",
            consentVersion: this.state.consentConfig.TOS.version,
            appVersion: appVersion,
          }];
        const usa = resp && resp.usa ? resp.tos : 'PENDING';
        const updatedConsentPayload = {tos: "ACCEPTED", usa: usa}
        if(from === PLATFORM_GSNCOM ){
            //GSNCOM Login
            this.proceedAfterCCPAConsentForGSNCom(consentPayload, updatedConsentPayload, true);
        } else {
            //FB Login
            this.proceedAfterCCPAConsent(consentPayload, updatedConsentPayload, true);
        }
    }

    continueCcpaAccept(resp, from, logData) {
        const appVersion = from === PLATFORM_GSNCOM ? GSNCOM_APP_VERSION : APP_VERSION;
        //Analytics Start
        logData.eventSubCode = "ACCEPT";
        logData.app_session_id = this.props.appSessionId;
        const payload = analyticsLogs.setAnalyticsData(logData);
        analyticsManagerInstance.logAnalytics(payload).then(() => {
            console.log('STEP 5-1: STARTUP CPRA ACCEPT EVENT TRIGGRED', logData);
        });
        //Analytics End
        console.info("Consent:", resp);
        const userPIInfo = resp && resp.pi === 'PENDING' ? 'REJECTED' : resp.pi;
        const usa ={
            consentType: 'USA',
            userInput: 'ACCEPTED',
            consentVersion: this.state.consentConfig.CCPA.version,
            appVersion: appVersion,
        };
        const pi = {
            consentType: "PI",
            userInput: userPIInfo,
            consentVersion: this.state.consentConfig.CCPA.version,
            appVersion: appVersion,
        }
        const tos = {
            consentType: "TOS",
            userInput: "ACCEPTED",
            consentVersion: this.state.consentConfig.TOS.version,
            appVersion: appVersion,
        }
        if(from === PLATFORM_GSNCOM ){
            //GSNCOM Login
            const consentGSNPayload = [usa,pi,tos];
            const updatedConsentPayloadGsn = {pi: userPIInfo,tos: "ACCEPTED",usa: 'ACCEPTED'}
            this.proceedAfterCCPAConsentForGSNCom(consentGSNPayload, updatedConsentPayloadGsn, true);
        } else {
            //FB Login
            const tos = resp && resp.tos ? resp.tos : "PENDING";
            const consentFBPayload = [usa,pi];
            const updatedConsentPayloadFB = {usa: 'ACCEPTED', pi: userPIInfo, tos:tos}
            this.proceedAfterCCPAConsent(consentFBPayload, updatedConsentPayloadFB, true);
        }
    }

    proceedAfterCCPAConsentForGSNCom(consentPayload, updatedConsentPayload, isRecordConsent) {
        //this.proceedAfterConsent(updatedConsentPayload);
        const isShowAds =
            updatedConsentPayload && updatedConsentPayload.pi && updatedConsentPayload.pi.toUpperCase() === 'ACCEPTED'
                ? false
                : true;
        this.setState({ showAds_ccpa_users: isShowAds });
        console.log('STEP 6-1: AFTER GSN CCPA - RECEIVED - consentPayload? ', consentPayload);
        console.log(
            'STEP 6-2: AFTER GSN CCPA - RECEIVED - updatedConsentPayload? ',
            updatedConsentPayload
        );
        console.log('STEP 6-3: AFTER GSN CCPA - RECEIVED - isRecordConsent? ', isRecordConsent);
        this.proceedToSeamAuthForGSNCom(
            consentPayload,
            updatedConsentPayload,
            isRecordConsent,
            this.state.FBResForDevice
        );
    }
    proceedToSeamAuthForGSNCom(
        consentPayload,
        updatedConsentPayload,
        isRecordConsent,
        gsnComLogin
    ) {
        console.log('STEP 7: GSN COM AFTER CCPA PROCEED TO SEAM AUTH');
        const userId = gsnComLogin && gsnComLogin.authResponse && gsnComLogin.authResponse.userID;
        const userModel =
            gsnComLogin && gsnComLogin.authResponse && gsnComLogin.authResponse.userModel;
        SeamServices.registerDeviceGsnCom(this.state.iswebstore, userId, this.state.countryCode).then(() => {
            this.authProceed(gsnComLogin)
                .then((loginResponse) => {
                    const authorizeAppWithSeam = function (v) {
                        return function () {
                            //todo: change authorization to normal authorization with webtoken
                            return SeamServices.authorizeSeamGSNCom(v);
                        };
                    };
                    return Promises.resolve([authorizeAppWithSeam(userModel)]).mapSeries((method) => {
                        return method();
                    });
                })
                .then((data) => {
                    const loginTimeSyncDelay = new Date().getTime();
                    this.setState({loginTimeSyncDelay: loginTimeSyncDelay});
                    console.log('WHAT IS AUTH RESPONSE ::: ', data[0]);
                    this.getFeatureConfigMiniGameMath();
                    const seamObj = data[0];
                    console.log('STEP 8: GSN SEAM AUTH USER RESPONSE', seamObj);
                    this.setState({seamUser: seamObj});
                    //Updating seamuser id in helpshift app
                    if(seamObj._id){
                        let tags = [];
                        if(seamObj.profile.isPayer) {
                            tags.push('casino_payer');
                        }

                        seamService.getUserBTDs(this.state.seamUser._id, this.state.seamUser.sessionToken).then((res) => {
                            this.setState({userSpend: res.value})
                            if(seamObj.profile.isVipPlayer || Number(this.state.userSpend)>5000) {
                                tags.push('casino_vip');
                            }
                            if (this.state.seamUser.meta.psToken) {
                                helpshiftWidgetConfig.userId = this.state.seamUser._id;
                                window.helpshiftWidgetConfig.userId = this.state.seamUser._id;
                                helpshiftWidgetConfig.userAuthToken = this.state.seamUser.meta.psToken;
                                window.helpshiftWidgetConfig.userAuthToken = this.state.seamUser.meta.psToken;
                                helpshiftWidgetConfig.cifs = {
                                    // Key of the Custom Issue Field
                                    user_id: {
                                        type: "singleline",
                                        value: this.state.seamUser._id,
                                    },
                                    app_version: {
                                        type: "singleline",
                                        value: GSNCOM_APP_VERSION,
                                    },
                                    scopely_lifetime_spend: {
                                        type: "number",
                                        value: res.value,
                                    }
                                }
                                window.helpshiftWidgetConfig.cifs = {
                                    // Key of the Custom Issue Field
                                    user_id: {
                                        type: "singleline",
                                        value: this.state.seamUser._id,
                                    },
                                    app_version: {
                                        type: "singleline",
                                        value: GSNCOM_APP_VERSION,
                                    },
                                    scopely_lifetime_spend: {
                                        type: "number",
                                        value: res.value,
                                    }
                                }
                            }
                            helpshiftWidgetConfig.tags = tags;
                            window.helpshiftWidgetConfig.tags = tags;
                            HelpshiftWidget("updateHelpshiftWidgetConfig");
                        });
                    }
                    const seamUserId = seamObj && seamObj._id;
                    const sessionToken = seamObj && seamObj.sessionToken;
                    window.TrackJS &&
                    TrackJS.configure({
                        userId: seamUserId,
                        sessionId: sessionToken,
                        version: GSNCOM_APP_VERSION,
                    });
                    isRecordConsent &&
                    SeamServices.recordUserConsent(consentPayload, seamUserId, sessionToken).then(
                        () => {
                            const fromWhere = this.state.fromwhereInTos;
                            //Analytics Start
                            const ccpaLogData = {
                                eventGroup: 'STARTUP',
                                eventCode: 'TOS',
                                eventSubCode: 'ACCEPT',
                                sessionToken: this.state.seamUser && this.state.seamUser.session_token,
                                eventAttributes: { ui_element: 'tos', from_where: fromWhere, fb_uid: this.state.userIdForTos, reference_value_4: APP_VERSION },
                                deviceId: this.state.fbUserId,
                                country: this.props.country,
                                operatingSystem: this.props.operatingSystem,
                                app_session_id: this.props.appSessionId,
                            };
                            analyticsManagerInstance.logAnalytics(analyticsLogs.setAnalyticsData(ccpaLogData)).then(() => {});
                            //Analytics End
                        }
                    ).catch((e) => {
                        window.TrackJS && TrackJS.track("TOS Failed "+ e);
                    });
                    SeamServices.linkDevice(sessionToken, userId)
                        .then((resp) => {
                            console.log('GSN SEAM LINK DEVICE FOR WEB_ID:', userId);
                            //Analytics Start
                            const lodData = {
                                eventGroup: 'STARTUP',
                                eventCode: 'LOGIN',
                                eventSubCode: 'SUCCESS',
                                sessionToken: sessionToken,
                                eventAttributes: {
                                    ui_element: 'authorizeAppWithSeam',
                                    from_where: 'loginToGSNCom',
                                    attr1: 'seam',
                                    attr2: '1',
                                    fb_uid: userId,
                                    platform: 'gsncom',
                                    reference_value_6: userId,
                                },
                                country: this.state.fbUserCountryCode,
                                operatingSystem: this.state.operatingSystem,
                                deviceId: userId,
                                appVersion: GSNCOM_APP_VERSION,
                                app_session_id: this.props.appSessionId,
                                eventName: "cs.rewards_and_ui",
                            };
                            const payload = analyticsLogs.setAnalyticsData(lodData);
                            analyticsManagerInstance.logAnalytics(payload).then(() => {
                            });
                            //Analytics End

                            if(!this.state.iswebstore) {
                                SeamServices.getFeatureConfigMigrationPopups(sessionToken).then((data) => {
                                    const showMigrationFcVal = data;
                                    const showMigrationFcValObj = showMigrationFcVal && JSON.parse(showMigrationFcVal);
                                    if(showMigrationFcValObj && showMigrationFcValObj.displayMigrationPopup) {
                                        this.setState({showMigrationPopup: true});
                                        // this.showCloseButtonOnMigrate();
                                    }else if(showMigrationFcValObj && showMigrationFcValObj.displayForceRedirectionPopup) {
                                        this.setState({showForceRedirectionPopup: true});
                                        setTimeout(() => {
                                            this.forceredirectToNewSite();
                                        }, 8000);
                                    }
                                });
                                //popup display analytic log
                                const lodData = {
                                    eventGroup: 'STARTUP',
                                    eventCode: 'SCREEN',
                                    eventSubCode: 'OPEN',
                                    sessionToken: data && data[0].sessionToken,
                                    eventAttributes: {
                                        ui_element: 'NewWebsite',
                                        reference_value_6: userId,
                                    },
                                    country: this.state.fbUserCountryCode,
                                    operatingSystem: this.state.operatingSystem,
                                    deviceId: userId,
                                    appVersion: GSNCOM_APP_VERSION,
                                    app_session_id: this.props.appSessionId,
                                    eventName: "cs.rewards_and_ui",
                                };
                                const payload = analyticsLogs.setAnalyticsData(lodData);
                                analyticsManagerInstance.logAnalytics(payload).then(() => {
                                });

                            }

                            // SeamServices.getIsPlaygamiPayment(sessionToken).then(data => {
                            //     let fcResponseValue = data;
                            //     if(fcResponseValue && (fcResponseValue == true || fcResponseValue=='true')) {
                            //         this.setState({isPlaygamipayment: true});
                            //     }
                            // })

                            //Get fcvale to check if db clear is needed
                            SeamServices.getIsClearDbRequired(sessionToken).then((data) => {
                                this.setState({needToClearIndexedDB: data})
                            }).catch((e) => {
                                console.log("exception occured while reading fc", e);
                            })

                            SeamServices.getRollOutFC(sessionToken)
                                .then((data) => {
                                    let appVersion;
                                    let loaderVersion;
                                    const appVersionValue = data;
                                    const appVersionObj =
                                        appVersionValue && JSON.parse(appVersionValue);
                                    if (appVersionObj && appVersionObj.enabled) {
                                        appVersion = appVersionObj.version;
                                        loaderVersion = appVersionObj.loaderVersion;
                                        SeamServices.registerDeviceGsnComWithAppid(
                                            this.state.iswebstore,
                                            userId,
                                            appVersion
                                        ).then((res) => {
                                            SeamServices.linkDevice(sessionToken, userId)
                                                .then((res) => {
                                                    this.proceedAfterConsentForGSNCom(
                                                        updatedConsentPayload,
                                                        loginTimeSyncDelay,
                                                        appVersion,
                                                        loaderVersion,
                                                        appVersionObj
                                                    );
                                                })
                                                .catch((e) => {
                                                    this.proceedAfterConsentForGSNCom(
                                                        updatedConsentPayload,
                                                        loginTimeSyncDelay,
                                                        appVersion,
                                                        loaderVersion,
                                                        appVersionObj
                                                    );
                                                });
                                        });
                                        this.setState({webglRolloutConfig: appVersion});
                                        this.setState({webglRolloutUnityVersion: loaderVersion});
                                        //Analytics Start
                                        const lodData = {
                                            eventGroup: ENG_EVENT.eventGroup,
                                            eventCode: ENG_EVENT.eventCode,
                                            eventSubCode: ENG_EVENT.eventSubCode,
                                            sessionToken: sessionToken,
                                            eventAttributes: {
                                                ui_element: 'authorizeAppWithSeam',
                                                from_where: 'gsncom_login',
                                                attr1: 'seam',
                                                attr2: '1',
                                                fb_uid: userId,
                                                platform: 'gsncom',
                                            },
                                            country: this.state.fbUserCountryCode,
                                            operatingSystem: this.state.operatingSystem,
                                            deviceId: userId,
                                            appVersion: appVersion,
                                            app_session_id: this.props.appSessionId,
                                            eventName: "sys.logging",
                                        };
                                        const payload = analyticsLogs.setAnalyticsData(lodData);
                                        analyticsManagerInstance.logAnalytics(payload).then(() => {
                                        });
                                        //Analytics End
                                    }
                                    window.TrackJS &&
                                    TrackJS.configure({
                                        userId: seamUserId,
                                        sessionId: sessionToken,
                                        version: appVersion
                                            ? appVersion
                                            : GSNCOM_APP_VERSION,
                                    });
                                })
                                .catch((error) => {
                                    //Analytics Start
                                    const lodData = {
                                        eventGroup: ENG_EVENT.eventGroup,
                                        eventCode: ENG_EVENT.eventCode,
                                        eventSubCode: ENG_EVENT.eventSubCode,
                                        sessionToken: sessionToken,
                                        eventAttributes: {
                                            ui_element: 'authorizeAppWithSeam',
                                            from_where: 'gsncom_login',
                                            attr1: 'seam',
                                            fb_uid: userId,
                                            platform: 'gsncom',
                                        },
                                        country: this.state.fbUserCountryCode,
                                        operatingSystem: this.state.operatingSystem,
                                        deviceId: userId,
                                        appVersion: GSNCOM_APP_VERSION,
                                        app_session_id: this.props.appSessionId,
                                        eventName: "sys.logging",
                                    };
                                    const payload = analyticsLogs.setAnalyticsData(lodData);
                                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                                    });
                                    //Analytics End
                                    window.TrackJS &&
                                    TrackJS.configure({
                                        userId: seamUserId,
                                        sessionId: sessionToken,
                                        version: GSNCOM_APP_VERSION,
                                    });
                                    this.proceedAfterConsentForGSNCom(
                                        updatedConsentPayload,
                                        loginTimeSyncDelay,
                                        undefined
                                    );
                                });
                        })
                        .catch((error) => {
                            console.error('Error: seam linkDevice', {message: error});
                            //Analytics - ENGINEERING-start
                            const errorLog = {
                                eventGroup: ENG_ERROR.eventGroup,
                                eventCode: ENG_ERROR.eventCode,
                                deviceId: userId,
                                eventAttributes: {
                                    ui_element: 'authorizeAppWithSeam',
                                    from_where: 'linkDevice',
                                    fb_uid: userId,
                                },
                                country: this.state.fbUserCountryCode,
                                operatingSystem: this.state.operatingSystem,
                                appVersion: GSNCOM_APP_VERSION,
                                app_session_id: this.props.appSessionId,
                                eventName: "sys.logging",
                            };
                            this.state.logEngineeringEvents &&
                            analyticsManagerInstance
                                .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                                .then(() => {
                                });
                            //Analytics - ENGINEERING-End
                            //Get fcvale to check if db clear is needed
                            SeamServices.getIsClearDbRequired(sessionToken).then((data) => {
                                this.setState({needToClearIndexedDB: data})
                            }).catch((e) => {
                                console.log("exception occured while reading fc", e);
                            })
                            SeamServices.getRollOutFC(sessionToken)
                                .then((data) => {
                                    let appVersion;
                                    let loaderVersion;
                                    const appVersionValue = data;
                                    const appVersionObj =
                                        appVersionValue && JSON.parse(appVersionValue);
                                    if (appVersionObj && appVersionObj.enabled) {
                                        appVersion = appVersionObj.version;
                                        loaderVersion = appVersionObj.loaderVersion;
                                        SeamServices.registerDeviceGsnComWithAppid(
                                            this.state.iswebstore,
                                            userId,
                                            appVersion
                                        ).then((res) => {
                                            SeamServices.linkDevice(sessionToken, userId)
                                                .then((res) => {
                                                    this.proceedAfterConsentForGSNCom(
                                                        updatedConsentPayload,
                                                        loginTimeSyncDelay,
                                                        appVersion,
                                                        loaderVersion,
                                                        appVersionObj
                                                    );
                                                })
                                                .catch((e) => {
                                                    this.proceedAfterConsentForGSNCom(
                                                        updatedConsentPayload,
                                                        loginTimeSyncDelay,
                                                        appVersion,
                                                        loaderVersion,
                                                        appVersionObj
                                                    );
                                                });
                                        });
                                        this.setState({webglRolloutConfig: appVersion});
                                        this.setState({webglRolloutUnityVersion: loaderVersion});
                                        //Analytics Start
                                        const lodData = {
                                            eventGroup: ENG_EVENT.eventGroup,
                                            eventCode: ENG_EVENT.eventCode,
                                            eventSubCode: ENG_EVENT.eventSubCode,
                                            sessionToken: sessionToken,
                                            eventAttributes: {
                                                ui_element: 'authorizeAppWithSeam',
                                                from_where: 'gsncom_login',
                                                attr1: 'seam',
                                                attr2: '1',
                                                fb_uid: userId,
                                                platform: 'gsncom',
                                            },
                                            country: this.state.fbUserCountryCode,
                                            operatingSystem: this.state.operatingSystem,
                                            deviceId: userId,
                                            appVersion: appVersion,
                                            app_session_id: this.props.appSessionId,
                                            eventName: "sys.logging",
                                        };
                                        const payload = analyticsLogs.setAnalyticsData(lodData);
                                        analyticsManagerInstance.logAnalytics(payload).then(() => {
                                        });
                                        //Analytics End
                                    }
                                    window.TrackJS &&
                                    TrackJS.configure({
                                        userId: seamUserId,
                                        sessionId: sessionToken,
                                        version: appVersion
                                            ? appVersion
                                            : GSNCOM_APP_VERSION,
                                    });
                                })
                                .catch((error) => {
                                    //Analytics Start
                                    const lodData = {
                                        eventGroup: ENG_EVENT.eventGroup,
                                        eventCode: ENG_EVENT.eventCode,
                                        eventSubCode: ENG_EVENT.eventSubCode,
                                        sessionToken: sessionToken,
                                        eventAttributes: {
                                            ui_element: 'authorizeAppWithSeam',
                                            from_where: 'gsncom_login',
                                            attr1: 'seam',
                                            fb_uid: userId,
                                            platform: 'gsncom',
                                        },
                                        country: this.state.fbUserCountryCode,
                                        operatingSystem: this.state.operatingSystem,
                                        deviceId: userId,
                                        appVersion: GSNCOM_APP_VERSION,
                                        app_session_id: this.props.appSessionId,
                                        eventName: "sys.logging",
                                    };
                                    const payload = analyticsLogs.setAnalyticsData(lodData);
                                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                                    });
                                    //Analytics End
                                    window.TrackJS &&
                                    TrackJS.configure({
                                        userId: seamUserId,
                                        sessionId: sessionToken,
                                        version: GSNCOM_APP_VERSION,
                                    });
                                    this.proceedAfterConsentForGSNCom(
                                        updatedConsentPayload,
                                        loginTimeSyncDelay,
                                        undefined
                                    );
                                });
                        });
                    console.log('STEP 8-2: GSN SEAM AUTH SUCCESSFULL COMPLETED');
                })
                .catch((error) => {
                    const e_error = error;
                    const l_error = {
                        status_code: e_error._statusCode,
                        message: e_error._message,
                    };
                    const messageToSend = {
                        type: 'Error',
                        msg: l_error,
                    };
                    window.parent.postMessage(JSON.stringify(messageToSend), '*');
                    console.error('Error: GSN SEAM AUTH FAILED RESPONSE', l_error, gsnComLogin);

                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'LOGIN',
                        eventSubCode: 'FAIL',
                        sessionToken: '',
                        eventAttributes: {
                            ui_element: 'authorizeAppWithSeam',
                            from_where: 'loginToGSNCom',
                            attr1: 'seam',
                            attr2: '1',
                            fb_uid: userId,
                            platform: 'gsncom',
                            error_string: error,
                            key_attr3: error,
                            reference_value_5: error,
                            reference_value_6: userId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: userId,
                        appVersion: GSNCOM_APP_VERSION,
                        app_session_id: this.props.appSessionId,
                        eventName: "cs.rewards_and_ui",
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                    });
                    //Analytics End
                    PubSub.publish('SEAM_SERVICE_ERROR', true);
                });
        })
    }
    proceedAfterConsentForGSNCom(consentPayload, loginTimeSyncDelay, appVersionRollOut, loaderVersion, appVersionObj) {
        const userId =
            this.state.FBResForDevice &&
            this.state.FBResForDevice.authResponse &&
            this.state.FBResForDevice.authResponse.userID;
        const seamObject = this.state.seamUser;
        console.log('STEP 10: GSN SEAM OBJECT AFTER LOGIN', seamObject);
        this.props.setSeamSession(seamObject);
        this.setState({ authenticateComplete: true });
        this.getFeatureConfigRefreshRate();
        this.getCountriesWhereAdsCanRefresh();
        this.getFeatureConfigGlobalToggle();
        this.getToken('mini_lobby_load');
        this.getUserTokenMiniGame();
        this.getFeatureConfigEngineeringFlag();
        const appVersion = getOptionalBuild('gsncom');
        console.log('OPTIONAL BUILD DETAILS::', appVersion);

        const webglPayload = {
            facebookUserObject: {
                seamId: seamObject._id,
                name: seamObject.profile.gsnUsername,
                email: seamObject.profile.gsnEmail,
                pictureUrl: seamObject.profile.gsnPictureUrl,
                sessionToken: seamObject.sessionToken,
                firstName: seamObject.profile.gsnUsername,
                countryCode: this.state.countryCode
                    ? this.state.countryCode
                    : 'DEFAULT',
                platformUserId: userId,
                lastLoginDate: seamObject.profile.lastLoginDate,
                previousLogin: seamObject.profile.previousLogin,
                firstTimePlayer: seamObject.profile.firstTimePlayer ? true : false,
                outOfToken: seamObject.profile.outOfToken,
                consentPayload: consentPayload,
                platform: this.props.platform,
                loginTimeSyncDelay: 0,
                profile: seamObject.profile,
                userData: seamObject.userData,
                analyticSessionId: this.props.appSessionId
            },
            analyticsLogs: analyticsLogs,
            FBResForDevice: this.state.FBResForDevice,
            userId: userId,
            logEngineeringEvents: this.state.logEngineeringEvents,
            country: this.state.countryCode,
            operatingSystem: this.state.operatingSystem,
            webAppVersion: appVersion
                ? appVersion
                : appVersionRollOut
                    ? appVersionRollOut
                    : GSNCOM_APP_VERSION,
            browserName: this.state.browserName,
            loginTimeSyncDelay: loginTimeSyncDelay,
            appVersionRollOut: appVersionRollOut,
            appVersionObj: appVersionObj,
            analyticSessionId: this.props.appSessionId
        };
        const log = {
            eventGroup: 'ENGINEERING',
            eventCode: 'SessionId',
            deviceId: userId,
            eventAttributes: {
                ui_element: 'mini_lobby',
                analyticSessionId: this.props.appSessionId,
            },
            sessionToken: seamObject.sessionToken,
            analyticSessionId: this.props.appSessionId,
            eventName: "sys.logging",
        };
        const payload = analyticsLogs.setAnalyticsData(log);
        analyticsManagerInstance.logAnalytics(payload).then(() => {
        });
        console.log('STEP 11: WEBGL PAYLOAD ', webglPayload);
        this.setState({ startTime: new Date().getTime() });
        setTimeout(() => {
            if (
                this.state.webGLSupport &&
                this.state.webGLSupport.flag &&
                !this.state.isCommonError
            ) {
                if (loaderVersion === 'v2'){
                    //check if need to clear indexed db
                    if (this.state.needToClearIndexedDB === 'true') {
                        //clear db
                        console.log("Clearing Indexed DB")
                        const promise = indexedDB.databases();
                        promise.then((databases) => {
                            console.log("indexed dbs ",databases);
                            databases.forEach((eachDb)=> {
                                indexedDB.deleteDatabase(eachDb);
                                console.log("deleting indexed db",databases);
                            })
                            console.info(`================================== Unity V2 Version :  ${loaderVersion}===========================`);
                            webglManagerV2(webglPayload);
                        }).catch((e) => {
                            console.info(`================================== Unity V2 Version :  ${loaderVersion}===========================`);
                            webglManagerV2(webglPayload);
                        });

                    }else{
                        console.log("Not Clearing Indexed DB")
                        console.info(`================================== Unity V2 Version :  ${loaderVersion}===========================`);
                        webglManagerV2(webglPayload);
                    }
                } else {
                    console.info(`================================== Unity V1 Version :  ${loaderVersion}===========================`);
                    webglManager(webglPayload);
                }
            }
        }, 2000);
        this.incrementGameNameCounter();
    }

    showAccountInfo() {
        this.setState({ isAccountInfo: true });
        PubSub.publish('BLOCK_USER_TYPING', 'false');
    }
    closeAccountInfo() {
        this.setState({ isAccountInfo: false });
        PubSub.publish('BLOCK_USER_TYPING', 'true');
    }

    logout() {
        const messageToSend = {
            type: 'info',
            msg: {
                data: 'logout',
            },
        };
        window.parent.postMessage(JSON.stringify(messageToSend), '*');
        console.log('I clicked logout ....');
    }

    paymentCallback(event) {
        console.info('Received callback inside paypal checkout page');
        const baseWebglUrl="payment-dev.classiccasino.com";
        // const baseWebglUrl=BASE_GSNCOM_URL;
        // if (event.origin.indexOf(baseWebglUrl)<0){
        //     return;
        // }
        console.info('Received callback', event.data);
        if(event.data && event.data.hasOwnProperty('state')) {
            const respState = event.data.state;
            console.info('Received callback inside paypal checkout page resp state', respState);
            this.paymentStatus(respState);
            switch(respState){
                case 'approved':
                    console.log('PAYPAL TRANSACTION APPROVED');
                    break;
                case 'completed':
                    this.setState({ messageToShowInRedirectedPopup : "Your Purchase is Successfully completed!" });
                    break;
                case 'transaction_verification_failed':
                    this.setState({ messageToShowInRedirectedPopup : "Purchase Failed! Please retry again!" });
                    break
                case 'close_clicked':
                    this.setState({ messageToShowInRedirectedPopup : "Payment Failed! User Cancelled Purchase!" });
                    break;
                }
            if(event.data.redirectedUserFromPayments) {
                PubSub.publish('IS_REDIRECTED_PAYMENT_USER', true);
                this.setState({ showRedirectSuccesfulPurchase : true });
            }
        }

    }

    paymentStatus(status) {
        const l_status = status;
        PubSub.publish('GSN_PAYPAL_PAYMENT_DONE', { status: l_status });
    }
    render() {
        const bg_reset = AWS_URL + 'bg-reset.jpg?ver=' + IMAGE_VERSION;
        let bg_with_frame =
            AWS_URL + 'Bg-with-frame-tiny.png?ver=' + IMAGE_VERSION;
        const tiles_game_loading =
            AWS_URL + 'tiles_games_loading.png?ver=' + IMAGE_VERSION;

        const buy_tokens_btn_disabled =
            AWS_URL + 'buy_tokens_btn_disabled.png?ver=' + IMAGE_VERSION;
        const coinImg = AWS_URL + 'coin_icon.png?ver=' + IMAGE_VERSION;
        const max_token_limit =
            AWS_URL + 'max-token-limit.png?ver=' + IMAGE_VERSION;
        const skipBtn = AWS_URL + 'skip.png?ver=' + IMAGE_VERSION;
        const sprite_footer =
            AWS_URL + 'sprite-newlobby-footer.png?ver=' + IMAGE_VERSION;
        const userIcon = AWS_URL + 'user_icon.png?ver=' + IMAGE_VERSION;

        let bgFrameCls = 'frame-bg';
        const showAds = this.state.showAds;
        const showAds_ccpa_users = this.state.showAds_ccpa_users;
        const showGameAds = this.state.showGameAds;
        const authenticateCompleted = this.state.authenticateComplete;
        let environment = Environment;
        const loaderVersion = this.state.webglRolloutUnityVersion;
        const adsRefreshRate = this.state.adsRefreshRate;
        const homeIcon =
            this.state.webglLoaded && !this.state.isCommonError
                ? css['home-pic-placeholder']
                : css['home-pic-placeholder-disabled'];
        const footerMargin = { marginTop: this.state.showWebGL ? `8.5%` : '9.5%' };
        const profileImage = this.state.userProfileImage ? this.state.userProfileImage : userIcon;
        const footerPlaceholderCSS = this.state.webglLoaded
            ? this.getClassFooter(this.state.screenW)
            : css['footer-placeholder'];
        const url = this.state.seamUser
            ? contactSupport(
                  this.state.seamUser,
                  this.state.FBResForDevice,
                  this.state.userAgent,
                  this.props.seamTokenBalance,
                  this.state.browserName,
                  this.state.browserVersion,
                  this.state.operatingSystem,
                  this.state.osVersion,
                  this.props.platform
              )
            : contactSupport(
                  '',
                  this.state.FBResForDevice,
                  this.state.userAgent,
                  this.props.seamTokenBalance,
                  this.state.browserName,
                  this.state.browserVersion,
                  this.state.operatingSystem,
                  this.state.osVersion,
                  this.props.platform
              );
        let high_low_game_visbale;
        let pre_lobby_loader_visbale;
        let acctInfoHeight;
        let acctInfoWidth;
        if (this.props.platform === PLATFORM_FB && this.state.showHighLow) {
            high_low_game_visbale =
                this.state.displayHiLoGame &&
                !this.state.isCommonError &&
                this.state.webGLSupport &&
                this.state.webGLSupport.flag &&
                this.props.noOfTimesPlayed <= 2
                    ? { display: 'block' }
                    : { display: 'none' };
            pre_lobby_loader_visbale =
                authenticateCompleted &&
                this.state.displayPreLoader &&
                !this.state.isCommonError &&
                this.state.webGLSupport &&
                this.state.webGLSupport.flag
                    ? { display: 'block' }
                    : { display: 'none' };
            bgFrameCls = 'frame-bg';
            bg_with_frame =
                AWS_URL + 'Bg-with-frame-tiny.png?ver=' + IMAGE_VERSION;
        } else {
            high_low_game_visbale =
                this.state.displayHiLoGame &&
                !this.state.isCommonError &&
                this.state.webGLSupport &&
                this.state.webGLSupport.flag &&
                this.props.noOfTimesPlayed <= 2 &&
                !this.state.isAccountInfo
                    ? { display: 'block' }
                    : { display: 'none' };
            pre_lobby_loader_visbale =
                authenticateCompleted &&
                this.state.displayPreLoader &&
                !this.state.isCommonError &&
                this.state.webGLSupport &&
                this.state.webGLSupport.flag &&
                !this.state.isAccountInfo
                    ? { display: 'block' }
                    : { display: 'none' };
            acctInfoHeight =
                !this.state.isCommonError && this.state.isAccountInfo && this.state.showWebGL
                    ? `${parseFloat(this.state.renderHeight) - 20}px`
                    : this.state.renderHeight;
            acctInfoWidth =
                !this.state.isCommonError && this.state.isAccountInfo && this.state.showWebGL
                    ? `${parseFloat(this.state.renderWidth) - 10}px`
                    : this.state.renderWidth;
            bgFrameCls = 'frame-bg-gsn';
            bg_with_frame =
                AWS_URL +
                'Bg-with-frame-gsn-tinny.png?ver=' +
                IMAGE_VERSION;
        }
        return (
            <div
                className={`${css['lobby-container']}`}
                style={{
                    height: this.state.renderHeight,
                    width: this.state.renderWidth,
                    margin: '0 auto',
                    overflow: 'visible',
                }}
            >
                <div id="lobby" className={css['main-lobby-parent-div']}>
                    <div className={`row ${css['header-parent-div']}`} />
                    {(!authenticateCompleted && this.state.showIncompatibleCountry) && (
                       <div><IncompatibleCountry
                       /></div>
                    )}
                        <PaymentComponent></PaymentComponent>


                    {(authenticateCompleted && !this.state.showWebGL && environment === 'development') && (<div onClick={this.removeJsLayer} className={css['toggleJSButton']}>Toggle JS Layer</div>)}

                    {this.state.redirectUserDisplayPayment && <div>
                        <Redirection
                            st={this.state.rediPaySes}
                            pparam={this.state.rediPayPparam}
                            oparam={this.state.rediPayOparam}></Redirection>
                    </div>}

                    {this.state.showRedirectSuccesfulPurchase && <div>
                        <SuccesPurchase
                            displaymsg={this.state.messageToShowInRedirectedPopup}></SuccesPurchase>
                    </div>}

                    {(authenticateCompleted && this.state.showMigrationPopup) &&
                        <div id="migrationPopup">
                            <div className={css['migrationPopupBg']}>
                                <img className={css['headerBg']} src={header}></img>
                                <div className={css['maintext']}>
                                    <div> GSN casino has a New Home! Join us on the</div>
                                    <div>New Website to continue the Casino fun and get exclusive Gifts!</div>
                                    <span className={css['secondtext']}>Head over now and keep spinning with</span>
                                </div>
                                <div className={css['newwebtext']}><span className={css['secondtextdata']}>www.classiccasino.com</span></div>
                                <img onClick={this.redirectToNewSite} className={css['letsGoButton']} src={letsgobuuton}></img>
                            </div>
                        </div>
                    }

                    {(authenticateCompleted && this.state.showForceRedirectionPopup) &&
                    <div id="migrationPopup">
                        <div className={css['redirectionpopup']}>
                        </div>
                    </div>
                    }

                    {(!authenticateCompleted && !this.state.showIncompatibleCountry) && <MiniLobbyLoader />}
                    {authenticateCompleted && (
                        <div className={`row ${css['middle-parent-div']}`} id="parentDiv">
                            {!this.state.showWebGL && (
                                <div
                                    className={`${css[bgFrameCls]}`}
                                    style={{
                                        width: `${this.state.renderWidth}`,
                                        height: `${this.state.renderHeight}`,
                                        backgroundImage: `url(${bg_with_frame})`,
                                    }}
                                    id="topSectionOnUnity"
                                >
                                    <div
                                        className={`row ${css['top-hud-parent-div']} `}
                                        style={{ justifyContent: 'center' }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '96.6%',
                                            }}
                                        >
                                            <div className={css['flex-style-1']}>
                                                <div
                                                    onClick={this.showContainer.bind(
                                                        this,
                                                        'home_button'
                                                    )}
                                                    className={homeIcon}
                                                />
                                            </div>
                                            <div className={css['flex-style-1']}>
                                                <img
                                                    src={profileImage}
                                                    className={css['profile-pic-icon']}
                                                />
                                            </div>
                                            <div className={css['flex-style-1']}>
                                                <img
                                                    src={coinImg}
                                                    className={css['coin-placeholder']}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flex: '7',
                                                    justifyContent: 'left',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div className={css['token-placeholder']}>
                                                    {this.props.seamTokenBalance &&
                                                        this.props.seamTokenBalance.toLocaleString()}
                                                </div>
                                            </div>
                                            <div className={css['flex-style-1']} />
                                            <div
                                                style={{ flex: '4' }}
                                                className={css['flex-style-generic']}
                                            >
                                                <div
                                                    className={css.buy_tokens_button}
                                                    onClick={this.showDebugger}
                                                    style={{
                                                        backgroundImage: `url(${buy_tokens_btn_disabled})`,
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{ flex: '10' }}
                                                className={css['flex-style-generic']}
                                            />
                                            <div className={css['flex-style-1']}>
                                                <Settings
                                                    cookies={this.props.cookies}
                                                    optOutConsent={this.optOutConsent}
                                                    userData={this.state.userFbData}
                                                    seamUser={this.state.seamUser}
                                                    userCountryCode={this.state.fbUserCountryCode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {this.props.platform === PLATFORM_FB &&
                                            this.state.showHighLow && (
                                                <div
                                                    className={`row ${css['game-height']}`}
                                                    style={high_low_game_visbale}
                                                >
                                                    <HighLowGame
                                                        logEngineeringEvents={
                                                            this.state.logEngineeringEvents
                                                        }
                                                        userId={this.state.seamUser._id}
                                                        country={this.state.fbUserCountryCode}
                                                        operatingSystem={this.state.operatingSystem}
                                                        appVersion={this.state.webglRolloutConfig}
                                                    />
                                                </div>
                                            )}
                                        {this.state.webglLoaded &&
                                            this.state.webGLSupport &&
                                            !this.state.isCommonError &&
                                            this.state.webGLSupport.flag &&
                                            !this.state.isAccountInfo &&
                                            this.props.platform === PLATFORM_FB &&
                                            this.state.showHighLow && (
                                                <div className={css.skip_placeholder}>
                                                    <div
                                                        className={css.skip_button}
                                                        onClick={this.showContainer.bind(
                                                            this,
                                                            'skip_button'
                                                        )}
                                                        style={{
                                                            backgroundImage: `url(${skipBtn})`,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        {this.props.platform === PLATFORM_FB &&
                                            this.state.showHighLow && (
                                                <div
                                                    className={`row ${css.pre_lobby_loader_height}`}
                                                    style={pre_lobby_loader_visbale}
                                                >
                                                    <PreLobbyLoader
                                                        openMiniGame={this.switchToGame}
                                                        displayContainer={this.showContainer.bind(
                                                            this,
                                                            'auto'
                                                        )}
                                                        userId={this.state.seamUser._id}
                                                        country={this.state.fbUserCountryCode}
                                                        operatingSystem={this.state.operatingSystem}
                                                        appVersion={this.state.webglRolloutConfig}
                                                    />
                                                </div>
                                            )}
                                        {this.state.displayMaxTokensReached &&
                                            this.state.webGLSupport &&
                                            this.state.webGLSupport.flag &&
                                            !this.state.isCommonError &&
                                            this.props.platform === PLATFORM_FB &&
                                            this.state.showHighLow && (
                                                <div className={css.reached_max_tokens}>
                                                    <div
                                                        className={css.max_tokens}
                                                        style={{
                                                            backgroundImage: `url(${max_token_limit})`,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        {this.state.webGLSupport &&
                                            this.state.webGLSupport.flag &&
                                            !this.state.isCommonError &&
                                            !this.state.isAccountInfo &&
                                            this.props.platform === PLATFORM_GSNCOM && (
                                                <div className={css.loading_Game_tiles}>
                                                    <div
                                                        className={css.game_tiles}
                                                        style={{
                                                            backgroundImage: `url(${tiles_game_loading})`,
                                                        }}
                                                    />
                                                    <div>
                                                        <div className={css.game_text}>
                                                            We are setting up the casino for you.
                                                        </div>
                                                        <div className={css.game_text_small}>
                                                            The loading may take a while.
                                                        </div>
                                                        <div
                                                            className={
                                                                css.loading_game_text_container
                                                            }
                                                        >
                                                            <span className={css.loading_game_text}>
                                                                {' '}
                                                                Loading{' '}
                                                                {this.state.currentGameloading}
                                                                ...{' '}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        {this.state.webGLSupport &&
                                            this.state.webGLSupport.flag &&
                                            !this.state.isCommonError &&
                                            !this.state.isAccountInfo &&
                                            (this.props.platform === PLATFORM_FB &&
                                                !this.state.showHighLow) && (
                                                <div className={css.loading_Game_tiles}>
                                                    <div
                                                        className={css.game_tiles}
                                                        style={{
                                                            backgroundImage: `url(${tiles_game_loading})`,
                                                        }}
                                                    />
                                                    <div>
                                                        <div className={css.game_text}>
                                                            We are setting up the casino for you.
                                                        </div>
                                                        <div className={css.game_text_small}>
                                                            The loading may take a while.
                                                        </div>
                                                        {getOS() !== 'MacOS' && (
                                                            <div
                                                                className={
                                                                    css.loading_game_text_container
                                                                }
                                                                style={{ marginTop: '0.5%' }}
                                                            >
                                                                <span
                                                                    className={
                                                                        css.loading_game_text
                                                                    }
                                                                >
                                                                    {' '}
                                                                    Loading{' '}
                                                                    {this.state.currentGameloading}
                                                                    ...{' '}
                                                                </span>
                                                            </div>
                                                        )}
                                                        {getOS() === 'MacOS' && (
                                                            <div
                                                                className={
                                                                    css.loading_game_text_container
                                                                }
                                                                style={{ marginTop: '4.5%' }}
                                                            >
                                                                <span
                                                                    className={
                                                                        css.loading_game_text
                                                                    }
                                                                >
                                                                    {' '}
                                                                    Loading{' '}
                                                                    {this.state.currentGameloading}
                                                                    ...{' '}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        {(this.props.gameIsOver || this.state.gameIsOver) &&
                                            !this.state.isCommonError &&
                                            this.state.webGLSupport &&
                                            this.state.webGLSupport.flag &&
                                            this.props.platform === PLATFORM_FB &&
                                            this.state.showHighLow && (
                                                <Navigation
                                                    switchToContainer={this.showContainer.bind(
                                                        this,
                                                        'auto'
                                                    )}
                                                />
                                            )}
                                    </div>
                                    {!this.state.isCommonError && (
                                        <div className={css.progress_bar_placeholder}>
                                            <ProgressBar />
                                        </div>
                                    )}
                                    {this.state.isCommonError &&
                                        this.state.webGLSupport &&
                                        this.state.webGLSupport.flag &&
                                        !this.state.showWebGL && (
                                            <CommonError
                                                errType={this.state.isContainerError}
                                                reloadApplication={this.reloadApplication}
                                                browserName={this.state.browserName}
                                                screensize={{
                                                    height: this.state.renderHeight,
                                                    width: this.state.renderWidth,
                                                }}
                                            />
                                        )}
                                    {!this.state.isCommonError &&
                                        this.state.isAccountInfo &&
                                        this.props.platform === PLATFORM_GSNCOM && (
                                            <AccountInformation
                                                seamUser={this.state.seamUser}
                                                closeAccountInfo={this.closeAccountInfo}
                                            />
                                        )}
                                    {this.state.webGLSupport &&
                                        !this.state.webGLSupport.flag &&
                                        !this.state.showWebGL && (
                                            <IncompatibleBrowser
                                                webGLSupport={this.state.webGLSupport}
                                                lobbyStartTime={this.state.lobbyStartTime}
                                                seamUser={this.state.seamUser}
                                                country={this.state.fbUserCountryCode}
                                                operatingSystem={this.state.operatingSystem}
                                            />
                                        )}

                                </div>
                            )}
                            {!this.state.isCommonError &&
                                this.state.isAccountInfo &&
                                this.state.showWebGL &&
                                this.props.platform === PLATFORM_GSNCOM && (
                                    <div
                                        className={`${css['frame-bg-acctInfo']}`}
                                        style={{
                                            width: acctInfoWidth,
                                            height: acctInfoHeight,
                                            backgroundImage: `url(${bg_reset})`,
                                        }}
                                    >
                                        <AccountInformation
                                            seamUser={this.state.seamUser}
                                            closeAccountInfo={this.closeAccountInfo}
                                        />
                                    </div>
                                )}

                            <div
                                id="gameContainerCover"
                                className={`${css['game-container-style']}`}
                                style={{
                                    height: parseInt(this.state.renderHeight.replace('px','')) - 20 + 'px',
                                    width:  parseInt(this.state.renderWidth.replace('px','')) - 10 + 'px'
                                }}
                            >
                                {loaderVersion === 'v1' && (<div
                                    id="gameContainer"
                                    className={css.gamecontainer}
                                    style={{
                                        margin: '0 auto',
                                        overflow: 'visible',
                                    }}
                                />)}

                                {loaderVersion==='v2' && (<canvas
                                    id="unity-canvas"
                                    className={css.unitycanvas}
                                    style={{
                                        margin: '0 auto',
                                        overflow: 'visible',
                                    }}></canvas>)}
                                <div id="unity-warning"> </div>
                            </div>
                        </div>
                    )}
                    {authenticateCompleted && !this.state.iswebstore && (
                        <div className={`${footerPlaceholderCSS}`} style={footerMargin}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className={css['social-icons']}>
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-fb']
                                            } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://www.facebook.com/GSNcasino"
                                            style={{ backgroundImage: `url(${sprite_footer})` }}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-instagram']
                                            } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://www.instagram.com/gsncasinoofficial/"
                                            style={{ backgroundImage: `url(${sprite_footer})` }}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-twitter']
                                            } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://twitter.com/gsngames"
                                            style={{ backgroundImage: `url(${sprite_footer})` }}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-youtube']
                                            } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://www.youtube.com/user/GSNGamesVideos"
                                            style={{ backgroundImage: `url(${sprite_footer})` }}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-linkedin']
                                            } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://www.linkedin.com/company/gsn_games?trk=ppro_cprof"
                                            style={{ backgroundImage: `url(${sprite_footer})` }}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-apple-store']
                                            } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://itunes.apple.com/us/app/gsn-casino-wheel-fortune-slots/id469231420?mt=8"
                                            style={{ backgroundImage: `url(${sprite_footer})` }}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-google-play']
                                            } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://play.google.com/store/apps/details?id=com.gsn.android.casino&hl=en"
                                            style={{ backgroundImage: `url(${sprite_footer})` }}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-amazon']
                                            } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="http://www.amazon.com/GSN-Casino-Fortune-Ghostbusters-American/dp/B008K6G8CK"
                                            style={{ backgroundImage: `url(${sprite_footer})` }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="row"
                                style={{
                                    marginBottom: '10px',
                                    borderBottom: '4px solid white',
                                    width: '80%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginTop: 'auto',
                                }}
                            >
                                <div className="col-md-12">
                                    <div className={css['support-links']}>
                                        <span
                                            className={css['fb-like-container']}
                                            href="https://www.facebook.com/GSNcasino"
                                            layout="button"
                                            width="49px"
                                        />
                                        <span className="link-container">
                                            <a
                                                className={css.link}
                                                rel="external"
                                                href="https://scopely.helpshift.com/hc/en/63-classic-casino/"
                                                target="_new"
                                            >
                                                FAQ
                                            </a>
                                        </span>
                                        <span className="link-container">
                                            <a
                                                className={css.link}
                                                onClick={this.openHelpshiftApp}>
                                                Contact Support
                                            </a>
                                        </span>
                                        <span className="link-container">
                                            <a
                                                className={css.link}
                                                rel="external"
                                                href="//scopely.com/en/legal?id=tos&langSection=en"
                                                target="_new"
                                                data-tracking="legalLinks|tos"
                                            >
                                                Terms of Service
                                            </a>
                                        </span>
                                        {this.props.platform === PLATFORM_GSNCOM && (
                                            <span
                                                className={`link-container ${css.myAccount}`}
                                                onClick={this.showAccountInfo}
                                            >
                                                My Account
                                            </span>
                                        )}
                                        {this.props.platform === PLATFORM_GSNCOM && (
                                            <span
                                                className={`link-container ${css.logout}`}
                                                onClick={this.logout}
                                            >
                                                Log Out
                                            </span>
                                        )}
                                        <span className="link-container last">
                                            <a
                                                rel="external"
                                                className={css.link}
                                                href="//scopely.com/en/legal?id=privacy&langSection=en"
                                                target="_new"
                                                data-tracking="legalLinks|privacy"
                                            >
                                                Privacy Policy
                                            </a>
                                        </span>

                                        <span className={css.copyright}>
                                            &copy; {new Date().getFullYear()} Game Show Network
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-1" />
                                <div className="col-md-5" style={{ marginLeft: '1%' }}>
                                    {showAds_ccpa_users &&
                                        showAds &&
                                        showGameAds &&
                                        this.props.platform === PLATFORM_FB && (
                                            <Ads300X50_1
                                                showGameAds={this.state.showGameAds}
                                                adsToken={this.state.adsToken}
                                                adsChannel={this.state.adsChannel}
                                                refreshEnabled={this.state.refreshEnabled}
                                                adsTargetArguments={this.state.adsTargetArguments}
                                            />
                                        )}
                                </div>
                                <div className="col-md-5">
                                    {showAds_ccpa_users &&
                                        showAds &&
                                        showGameAds &&
                                        this.props.platform === PLATFORM_FB && (
                                            <Ads300X50_2
                                                showGameAds={this.state.showGameAds}
                                                adsToken={this.state.adsToken}
                                                adsChannel={this.state.adsChannel}
                                                refreshEnabled={this.state.refreshEnabled}
                                                adsTargetArguments={this.state.adsTargetArguments}
                                            />
                                        )}
                                </div>
                                <div className="col-md-1" />
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ marginBottom: '10px' }}>
                                    {showAds_ccpa_users &&
                                        showAds &&
                                        showGameAds &&
                                        this.props.platform === PLATFORM_FB && (
                                            <AdsGame
                                                showGameAds={this.state.showGameAds}
                                                adsToken={this.state.adsToken}
                                                adsChannel={this.state.adsChannel}
                                                refreshEnabled={this.state.refreshEnabled}
                                                adsTargetArguments={this.state.adsTargetArguments}
                                            />
                                        )}
                                    {showAds_ccpa_users &&
                                        showAds &&
                                        !showGameAds &&
                                        this.props.platform === PLATFORM_FB && (
                                            <AdsHome
                                                showGameAds={this.state.showGameAds}
                                                adsToken={this.state.adsToken}
                                                adsChannel={this.state.adsChannel}
                                                refreshEnabled={this.state.refreshEnabled}
                                                adsTargetArguments={this.state.adsTargetArguments}
                                            />
                                        )}
                                </div>
                            </div>
                            <div>
                                <AdsVideo
                                    exitFullScreen={this.exitFromFullScreen}
                                    userData={this.state.userFbData}
                                    platform={this.props.platform}
                                />
                                <GoogleVideoAds
                                    exitFullScreen={this.exitFromFullScreen}
                                    userData={this.state.userFbData}
                                    platform={this.props.platform}
                                />
                            </div>
                        </div>
                    )}
                    {authenticateCompleted && this.state.iswebstore && (
                        <div>
                            <AdsVideo
                                exitFullScreen={this.exitFromFullScreen}
                                userData={this.state.userFbData}
                                platform={this.props.platform}
                            />
                            <GoogleVideoAds
                                exitFullScreen={this.exitFromFullScreen}
                                userData={this.state.userFbData}
                                platform={this.props.platform}
                            />
                        </div>
                    )}
                </div>
                {this.state.miniLobby_debug_popup &&
                    this.props.usersToHandle && (
                        <div
                            className="row"
                            style={{
                                top: '10%',
                                position: 'absolute',
                                left: '5%',
                                zIndex: '9',
                                backgroundColor: 'yellow',
                            }}
                        >
                            <div className="col-md-6">
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col-md-6" style={{ textAlign: 'left' }}>
                                        Width
                                    </div>
                                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                                        <input
                                            type="text"
                                            name="miniW"
                                            value={this.state.miniW}
                                            onChange={this.updateInputValueW}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col-md-6" style={{ textAlign: 'left' }}>
                                        Height
                                    </div>
                                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                                        <input
                                            type="text"
                                            name="miniH"
                                            value={this.state.miniH}
                                            onChange={this.updateInputValueH}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="row"
                                    style={{ marginTop: '20px', marginBottom: '10px' }}
                                >
                                    <div
                                        className="col-md-12"
                                        style={{
                                            textAlign: 'center',
                                            width: '70%',
                                            marginLeft: '50%',
                                        }}
                                    >
                                        <button type="button" onClick={() => this.setResolution()}>
                                            Click Me!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}
