//@flow
import * as React from 'react';
import css from './style.css';
import SeamServices from '../../services/seamService';
import { getOS } from '../../managers/resolutionManager';
import PubSub from 'pubsub-js';
import { IMAGE_VERSION, AWS_URL } from '../../constants/matcher';

type PropsT = {
    reloadApplication: func,
    errType: number,
    closeModal: func,
};
type StateT = {};

export default class AccountInformation extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            emailText: 'Please enter valid Email Address',
            sucessEmailText : 'Email change successfully!',
            successEmail:false,
            newEmailError:false,
            currEmailError:false,
            reEmailError:false,

            pwdText: 'Please enter valid Password',
            sucessPwdText : 'Password change successfully!',
            successPassword:false,
            passwordErr: false,
            currPwdError:false,
            newPwdError:false,
            rePwdError:false,
            isEmailVerified: false,
            isEmailConfimed: false,

        };
        this.currEmailRef = React.createRef();
        this.newEmailRef = React.createRef();
        this.reEmailRef = React.createRef();
        this.currPasswordRef = React.createRef();
        this.rePasswordRef = React.createRef();
        this.newPasswordRef = React.createRef();
        this.saveEmailChanges = this.saveEmailChanges.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.savePasswordChanges = this.savePasswordChanges.bind(this);
        this.setIfEmailVerified = this.setIfEmailVerified.bind(this);
        this.onClose = this.onClose.bind(this);

    }
    componentDidMount(){
        //this.hackWebGLKeyboard();
        //document.getElementByID('curremail').focus()

        console.log('Seam Users ::', this.props.seamUser);
        this.setIfEmailVerified();
    }

    setIfEmailVerified(){
        const isEmailVerified = this.props.seamUser && this.props.seamUser.profile && this.props.seamUser.profile.emailVerified ? 'true' : 'false';
        const isEmailConfimed = this.props.seamUser && this.props.seamUser.profile && this.props.seamUser.profile.emailConfirmed ? 'true' : 'false';

        this.setState({isEmailVerified: isEmailVerified});
        this.setState({isEmailConfimed: isEmailConfimed});
    }

    validateEmail() {
        const mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const error = {
            isValid:true,
            field:{
                currEmail:false,
                newEmail:false,
                confirmEmail:false,
                sameEmail:false,
            }
        }
        if (!this.currEmailRef.value.match(mailformat)) {
            error.isValid = false;
            error.field.currEmail = true;
        } else if (!this.newEmailRef.value.match(mailformat)) {
            error.isValid = false;
            error.field.newEmail = true;
        } else if (!this.reEmailRef.value.match(mailformat)) {
            error.isValid = false;
            error.field.confirmEmail = true;
        } else if(this.reEmailRef.value !== this.newEmailRef.value){
            error.isValid = false;
            error.field.sameEmail = true;
        }
        return error;
    }
    saveEmailChanges(){
        const error = this.validateEmail();
        if(error.isValid){
            const ChangeEmailPasswordModel = {
                changeEmail: true,
                newEmail: this.newEmailRef.value,
                oldEmail: this.currEmailRef.value,
            };
            const sessionToken = this.props.seamUser && this.props.seamUser.sessionToken;
            SeamServices.changeEmailPassword(ChangeEmailPasswordModel, sessionToken)
            .then((data)=>{
                this.setState({emailText:''});
                this.setState({ currEmailError: false });
                this.setState({successEmail:true});
                this.setState({isEmailVerified: 'true'});
            })
            .catch((error)=>{
                const errorMsg = error && error._message && error._message.includes('Email not validated') ? 'Email not validated.' : error._message;
                this.setState({successEmail:false});
                this.setState({emailText:errorMsg});
                this.setState({ currEmailError: true });
            })
        }else{
            this.setState({successEmail:false});
            if(error.field.sameEmail){
                console.log('NOT SAME EMAIL ERROR', error);
                this.setState({emailText:'Email should be same as new email!'});
                this.setState({ newEmailError: error.field.newEmail });
                this.setState({ currEmailError: error.field.currEmail });
                this.setState({ reEmailError: true });
            }else{
                this.setState({ newEmailError: error.field.newEmail });
                this.setState({ currEmailError: error.field.currEmail });
                this.setState({ reEmailError: error.field.confirmEmail });
            }
        }
    }

    validatePassword() {
        const validFormat = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;
        const error = {
            isValid: true,
            field:{
                currPwd:false,
                newPwd:false,
                confirmPwd:false,
                samePwd:false,
                sameAsOld: false,
            }
        }
        if(!this.currPasswordRef.value){
            error.isValid = false;
            error.field.currPwd = true;
        }else if(!this.newPasswordRef.value || !this.newPasswordRef.value.match(validFormat)){
            error.isValid = false;
            error.field.newPwd = true;
        }else if(!this.rePasswordRef.value || !this.rePasswordRef.value.match(validFormat)){
            error.isValid = false;
            error.field.confirmPwd = true;
        }else if(this.newPasswordRef.value === this.currPasswordRef.value){
            error.isValid = false;
            error.field.sameAsOld = true;
        }else if(this.newPasswordRef.value !== this.rePasswordRef.value){
            error.isValid = false;
            error.field.samePwd = true;
        }
        return error;
    }

    savePasswordChanges(){
        const error = this.validatePassword();
        if(error.isValid){
            const ChangeEmailPasswordModel = {
                changePassword: true,
                oldPassword: this.currPasswordRef.value,
                newPassword: this.newPasswordRef.value,
            };
            const sessionToken = this.props.seamUser && this.props.seamUser.sessionToken;
            SeamServices.changeEmailPassword(ChangeEmailPasswordModel, sessionToken)
            .then((data)=>{
                this.setState({pwdText:''});
                this.setState({ currPwdError: false });
                this.setState({successPassword:true});
            })
            .catch((error)=>{
                console.log('WHRT ERROR:::', error);
                this.setState({successPassword:false});
                this.setState({ pwdText: error._message });
                this.setState({ currPwdError: true });
            });
        }else{
            this.setState({successPassword:false});
            if(error.field.sameAsOld){
                this.setState({ pwdText: 'New password should not be same as current password' });
                this.setState({ newPwdError: error.field.newPwd });
                this.setState({ currPwdError: error.field.currPwd });
                this.setState({ rePwdError: true });
            }else if(error.field.samePwd){
                this.setState({ pwdText: 'Password should match with new password' });
                this.setState({ newPwdError: error.field.newPwd });
                this.setState({ currPwdError: error.field.currPwd });
                this.setState({ rePwdError: true });
            }else{
                this.setState({ newPwdError: error.field.newPwd });
                this.setState({ currPwdError: error.field.currPwd });
                this.setState({ rePwdError: error.field.confirmPwd });
            }
        }
    }
    onClose(){
        this.props.closeAccountInfo();
    }
    keyCurrPwdPress(e){
        const element =  document.getElementById('currpassword');
        const keycode = (e.keyCode ? e.keyCode : e.which);
        console.log('key code = '+keycode);
        if(keycode===8){
            element.value = '';
        }else{
            element.value+=(keycode === 13 || keycode === 13) ? '':e.key;
        }
    }
    keyNewPwdPress(e){
        const element =  document.getElementById('newpassword');
        const keycode = (e.keyCode ? e.keyCode : e.which);
        console.log('key code = '+keycode);
        if(keycode===8){
            element.value = '';
        }else{
            element.value+=(keycode === 13 || keycode === 13) ? '':e.key;
        }
    }
    keyRePwdPress(e){
        const element =  document.getElementById('rePassword');
        const keycode = (e.keyCode ? e.keyCode : e.which);
        console.log('key code = '+keycode);
        if(keycode===8){
            element.value = '';
        }else{
            element.value+=(keycode === 13 || keycode === 13) ? '':e.key;
        }
    }
    keyCurrEmailPress(e){
        const element =  document.getElementById('curremail');
        const keycode = (e.keyCode ? e.keyCode : e.which);
        console.log('key code = '+keycode);
        if(keycode===8){
            element.value = '';
        }else{
            element.value+=(keycode === 13 || keycode === 13) ? '':e.key;
        }
    }
    keyNewEmailPress(e){
        const element =  document.getElementById('newEmail');
        const keycode = (e.keyCode ? e.keyCode : e.which);
        console.log('key code = '+keycode);
        if(keycode===8){
            element.value = '';
        }else{
            element.value+=(keycode === 13 || keycode === 13) ? '':e.key;
        }
    }
    keyReEmailPress(e){
        const element =  document.getElementById('reEmail');
        const keycode = (e.keyCode ? e.keyCode : e.which);
        console.log('key code = '+keycode);
        if(keycode===8){
            element.value = '';
        }else{
            element.value+=(keycode === 13 || keycode === 13) ? '':e.key;
        }
    }

    getEmailText(profile){
        const gsnEmail = profile && profile.gsnEmail;
        const emailConfirmed = profile && profile.emailConfirmed ? true : false;
        const profile_email_verified = profile && profile.emailVerified ? 'true': 'false' ;
        if(emailConfirmed){
            return '';
        }else{
            if(profile_email_verified && profile_email_verified === 'false'){
                return "The email you have entered " + gsnEmail + " is invalid. Please update your email to secure your account." ;
            }else{
                return "Your email is unverified. Please check your inbox to verify your email and secure your account";
            }
        }
    }

    render() {
        const buttonImage =  AWS_URL + 'Green-btn.png?ver=' + IMAGE_VERSION;
        const CardBase =  AWS_URL + 'Small-base.png?ver=' + IMAGE_VERSION;
        const emailText = this.props.seamUser && this.props.seamUser.profile && this.getEmailText(this.props.seamUser.profile);
        return (
            <div id="acctInfo" className={`row ${css.fontfamily}`}>
                <div className="col-md-12" style={{position:'absolute', top:'15%'}}>
                    <div className="row">
                        <div className="col-sm-1" onClick={this.onClose} style={{ color: 'white', position:'absolute', left:'88%', fontSize:'2rem', top: '-10%', 'cursor': 'pointer' }}>X</div>
                    </div>
                    <div className="row" style={{ position: 'relative' }}>
                        <div className={` col-md-12 `}>
                            <div
                                className="container"
                                style={{ fontFamily: 'none', textShadow: 'none', color: 'black' }}
                            >
                                { (this.state.isEmailVerified === 'false' || this.state.isEmailConfimed ==='false') && (<div className="row">
                                    <div className="col-md-12" style={{color:'red', backgroundColor:'transparent'}}>{emailText}</div>
                                </div>)}
                                <div className="row">
                                    <div  className={`col-sm-5 col-md-5 col-lg-5 mx-auto ${css.card}`} style={{borderRadius:'1%',backgroundImage: `url(${CardBase})`}}>
                                        <div className={`card card-signin my-4 `} style={{backgroundColor:'unset', border: 'none'}}>
                                            <div className="card-body">
                                                <div className={`card-title text-center heading`} style={{color:'white',fontWeight:'bold'}}>
                                                    <b>Change Password</b>
                                                </div>
                                                {this.state.successPassword && (
                                                    <div className={css.success}>
                                                        {this.state.sucessPwdText}
                                                    </div>
                                                )}
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="currpassword"
                                                        id="currpassword"
                                                        className={`form-control ${css['form-control']}`}
                                                        // eslint-disable-next-line react/jsx-no-bind
                                                        ref={(currPasswordRef) => {
                                                            this.currPasswordRef = currPasswordRef;
                                                        }}
                                                        placeholder="Current Password"
                                                        // onKeyUp={this.keyCurrPwdPress}
                                                    />
                                                    {this.state.currPwdError && (
                                                    <div className={css.error}>
                                                        {this.state.pwdText}
                                                    </div>
                                                )}
                                                </div>

                                                <div className="form-group" style={{marginTop: '8%'}}>
                                                    <input
                                                        type="password"
                                                        name="newpassword"
                                                        id="newpassword"
                                                        className={`form-control ${css['form-control']}`}
                                                        // eslint-disable-next-line react/jsx-no-bind
                                                        ref={(newPasswordRef) => {
                                                            this.newPasswordRef = newPasswordRef;
                                                        }}
                                                        style={{marginTop:'2px'}}
                                                        placeholder="New Password"
                                                        contentEditable="true"
                                                        // onKeyUp={this.keyNewPwdPress}
                                                    />
                                                    {this.state.newPwdError && (
                                                    <div className={css.error}>
                                                        {this.state.pwdText}
                                                    </div>
                                                )}
                                                </div>

                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="rePassword"
                                                        id="rePassword"
                                                        className={`form-control ${css['form-control']}`}
                                                        // eslint-disable-next-line react/jsx-no-bind
                                                        ref={(rePasswordRef) => {
                                                            this.rePasswordRef = rePasswordRef;
                                                        }}
                                                        placeholder="Re-enter New Password"
                                                        // onKeyUp={this.keyRePwdPress}
                                                    />
                                                    {this.state.rePwdError && (
                                                    <div className={css.error}>
                                                        {this.state.pwdText}
                                                    </div>
                                                )}
                                                </div>

                                                <div style={{color: '#9ef7ff', fontFamily: 'barlowbold'}} className={`small ${css.requiredInfo}`}>
                                                    <span>8 characters min</span>
                                                    <br />
                                                    <span>1 Lowercase Letter</span>
                                                    <br />
                                                    <span>1 Uppercase Letter</span>
                                                    <br />
                                                    <span>1 Number</span>
                                                    <br />
                                                </div>

                                                <button
                                                    style={{width: '60%', marginLeft: '20%', backgroundImage: `url(${buttonImage})` }}
                                                    className={`btn btn-lg btn-block ${css.button_style}`}
                                                    type="submit"
                                                    onClick={this.savePasswordChanges}
                                                >
                                                    Save
                                                </button>
                                                <div
                                                    className={`text-center link-primary form-label-group-a`}
                                                >
                                                    {/* <a href="../forgotUserPassword/forgotPassword.html">Forgot password?</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-sm-5 col-md-5 col-lg-5 mx-auto ${css.card}`} style={{borderRadius:'1%',backgroundImage: `url(${CardBase})`}}>
                                        <div className={`card card-signin my-4 `} style={{backgroundColor:'unset', border: 'none'}}>
                                            <div className="card-body">
                                            <div className={`card-title text-center heading`} style={{color:'white',fontWeight:'bold'}}>
                                                    <b>Change Email Address</b>
                                                </div>
                                                {this.state.successEmail && (
                                                    <div className={css.success}>
                                                        {this.state.sucessEmailText}
                                                    </div>
                                                )}
                                                <div style={{color:'white', textAlign:'left', marginBottom:'1%'}}>Current Email Address</div>
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="curremail"
                                                        className={`form-control ${css['form-control']}`}
                                                        placeholder="Current Email Address"
                                                        ref={(currEmailRef) => {
                                                            this.currEmailRef = currEmailRef;
                                                        }}
                                                        // onKeyUp={this.keyCurrEmailPress}
                                                    />
                                                    {this.state.currEmailError && (
                                                    <div className={css.error}>
                                                        {this.state.emailText}
                                                    </div>
                                                )}
                                                </div>

                                                <div className="form-group" style={{marginTop: '8%'}}>
                                                    <input
                                                        type="email"
                                                        name="newEmail"
                                                        id="newEmail"
                                                        className={`form-control ${css['form-control']}`}
                                                        placeholder="New Email Address"
                                                        ref={(newEmailRef) => {
                                                            this.newEmailRef = newEmailRef;
                                                        }}
                                                        style={{marginTop:'2px'}}
                                                        // onKeyUp={this.keyNewEmailPress}
                                                    />
                                                    {this.state.newEmailError && (
                                                    <div className={css.error}>
                                                        {this.state.emailText}
                                                    </div>
                                                )}
                                                </div>

                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        name="reEmail"
                                                        id="reEmail"
                                                        className={`form-control ${css['form-control']}`}
                                                        placeholder="Re-enter Email Address"
                                                        ref={(reEmailRef) => {
                                                            this.reEmailRef = reEmailRef;
                                                        }}
                                                        // onKeyUp={this.keyReEmailPress}
                                                    />
                                                    {this.state.reEmailError && (
                                                    <div className={css.error}>
                                                        {this.state.emailText}
                                                    </div>
                                                )}
                                                </div>


                                                <button
                                                    style={{ width: '60%', marginLeft: '20%', backgroundImage: `url(${buttonImage})`}}
                                                    className={`btn btn-lg btn-block ${css.button_style}`}
                                                    type="submit"
                                                    onClick={this.saveEmailChanges}
                                                >
                                                    Save
                                                </button>
                                                <div
                                                    className={`text-center link-primary form-label-group-a`}
                                                >
                                                    {/* <a href="../forgotUserPassword/forgotPassword.html">Forgot password?</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <img id='bingo_bg' src={lobby} /> */}

            </div>
        );
    }
}
