/* eslint-disable react/jsx-no-bind */
// @flow

import * as React from 'react';
import css from './style.css';
import Card from '../Card';
import { CARD_DECK } from '../../constants/matcher';
import PubSub from 'pubsub-js';
import HighLowIcon from '../HighLowIcon';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';
import _ from 'lodash';
import { PLATFORM_GSNCOM, IMAGE_VERSION, AWS_URL } from '../../constants/matcher';

type PropsT = {
    openModal: func,
    maximumTokens: number,
    gameResult: func,
    seamTokenBalance: number,
    noOfTimesPlayed: number,
    updateWinCount: func,
    countWin: number,
    manageGame: func,
    gameRoundNo: number,
    updateGameRound: func,
    updateWinText: func,
    countWinText: number,
    totalWinAmount: number,
    seamSession: string,
    gsnCredentials: any,
    fbUserId: string,
    userId: string,
    tokensWonPerRound: number,
    setLeftCardVal: func,
    setRightCardVal: func,
    setButtonClicked: func,
    country: string,
    operatingSystem: string,
    imgUrlList: any,
    platform: string,
    appSessionId: string,
};
type StateT = {};

const analyticsLogs = new analyticslog();
const getRandomNumber = () => CARD_DECK[Math.floor(Math.random() * CARD_DECK.length)];
export default class GameSection extends React.Component<PropsT, StateT> {
    constructor(props) {
        super();
        this.state = {
            openCard: '',
            openCardSymbol: '',
            openCardValue: '',
            cardToReveal: 0,
            displayWinMessage: false,
            displayLostMessage: false,
            cardRevealedDetails: '',
            tokens: '',
            toDisplayWin: false,
            gameStarted: false,
            showTitle: false,
            flipButton: false,
            showBaseRightCard: false,
            resultTitle: false,
            showHighLowText: false,
            showHighLowButtons: false,
            showGameText: false,
            // nextRound: false,
        };
        this.checkHilo = this.checkHilo.bind(this);
        this.loseGame = this.loseGame.bind(this);
        this.showResultPage = this.showResultPage.bind(this);
        this.removeDisplayedCards = this.removeDisplayedCards.bind(this);
    }
    componentDidMount() {
        let userId;
        if(this.props.platform === PLATFORM_GSNCOM){
            userId = this.props.gsnCredentials && this.props.gsnCredentials.authResponse && this.props.gsnCredentials.authResponse.userID;
        }else{//FOR FB
            userId = this.props.fbUserId;
        }
        if (this.validateCards()) {
            this.props.updateGameRound(this.props.gameRoundNo + 1);
            // this.nextRoundTimer = setTimeout(() => {
            //     this.setState({ nextRound: true });
            // }, 1000);
            this.showTitleTimer = setTimeout(() => {
                this.setState({ showTitle: true });
            }, 100);
            if (this.props.gameRoundNo === 0) {
                this.showQsTimer = setTimeout(() => {
                    this.setState({ showQs: true });
                }, 1000);
                this.cardTimer = setTimeout(() => {
                    this.setState({ showCard: true });
                    PubSub.publish('CARD_LIST', this.props.imgUrlList);
                }, 2000);
                this.showBaseRightCardTimer = setTimeout(() => {
                    this.setState({ showBaseRightCard: true });
                    PubSub.publish('CARD_LIST', this.props.imgUrlList);
                }, 2500);
                this.flipButtonTimer = setTimeout(() => {
                    this.setState({ flipButton: true });
                }, 4000);
            } else {
                this.showQsTimer = setTimeout(() => {
                    this.setState({ showQs: true });
                }, 250);
                this.cardTimer = setTimeout(() => {
                    this.setState({ showCard: true });
                    PubSub.publish('CARD_LIST', this.props.imgUrlList);

                }, 300);
                this.showBaseRightCardTimer = setTimeout(() => {
                    this.setState({ showBaseRightCard: true });
                    PubSub.publish('CARD_LIST', this.props.imgUrlList);
                }, 200);
                this.flipButtonTimer = setTimeout(() => {
                    this.setState({ flipButton: true });
                }, 400);
            }
        }
        //Analytics Start
        const lodData = {
            eventGroup: 'STARTUP',
            eventCode: 'MINIGAME',
            eventSubCode: 'AVAILABLE',
            sessionToken: this.props.seamSession.sessionToken,
            eventAttributes: {
                game_name: 'hi-low',
                trigger: this.props.gameRoundNo === 0 ? 'generic_flow' : 'Reload',
            },
            deviceId: userId,
            userId: this.props.userId,
            country: this.props.country,
            operatingSystem: this.props.operatingSystem,
            appVersion: this.props.appVersion,
            app_session_id: this.props.appSessionId,
            eventName: "cs.rewards_and_ui",
        };
        const payload = analyticsLogs.setAnalyticsData(lodData);
        analyticsManagerInstance.logAnalytics(payload).then(() => {});
        //Analytics End
    }
    componentWillUnmount() {
        // clearTimeout(this.nextRoundTimer);
        clearTimeout(this.showTitleTimer);
        clearTimeout(this.cardTimer);
        clearTimeout(this.showQsTimer);
        clearTimeout(this.showBaseRightCardTimer);
        clearTimeout(this.flipButtonTimer);
        clearTimeout(this.resultTitleTimer);
        clearTimeout(this.TimerWin);
        clearTimeout(this.TimerLose);
        clearTimeout(this.Timer);
    }
    validateCards() {
        //TODO terminate condition for while loop
        let randomOpenCard = getRandomNumber();
        let randomOpenCardVal = randomOpenCard.value;
        while (randomOpenCardVal === 14 || randomOpenCardVal === 2) {
            randomOpenCard = getRandomNumber();
            randomOpenCardVal = randomOpenCard.value;
        }

        this.setState({ openCard: randomOpenCard });
        this.setState({ openCardSymbol: randomOpenCard.card.split('-')[0] });
        this.setState({ openCardValue: randomOpenCard.card.split('-')[1] });
        return randomOpenCardVal;
    }

    loseGame(id) {
        const lowCard = CARD_DECK[Math.floor(Math.random() * this.state.openCard.value.length)];
        this.setState({ cardToReveal: lowCard });
        this.setState({ cardToRevealSymbol: lowCard.card.split('-')[0] });
        this.setState({ cardToRevealValue: lowCard.card.split('-')[1] });
        this.openResultPopUp(false, true, id);
    }

    removeDisplayedCards(rightCard) {
        const newCardsSet = _.pullAllWith(CARD_DECK, [this.state.openCard, rightCard], _.isEqual);
        console.log('newCardsSet ', newCardsSet);
    }

    checkHilo(id) {
        this.setState({ gameStarted: true });
        let newCard = getRandomNumber();
        let winVal;
        while (this.state.openCard.value === newCard.value) {
            newCard = getRandomNumber();
        }
        PubSub.publish('SHAKE_CARD', true);
        this.props.setLeftCardVal(this.state.openCard.value);
        this.removeDisplayedCards(newCard);
        this.Timer = setTimeout(() => {
            this.setState({ cardToReveal: newCard });
            this.setState({ cardToRevealSymbol: newCard.card.split('-')[0] });
            this.setState({ cardToRevealValue: newCard.card.split('-')[1] });
            this.props.setRightCardVal(newCard.card.split('-')[1]);
        }, 1000);
        this.props.setButtonClicked(id);
        if (id === 'highbtn') {
            if (newCard.value > this.state.openCard.value) {
                winVal = true;
            }
        }
        if (id === 'lowbtn') {
            if (newCard.value < this.state.openCard.value) {
                winVal = true;
            }
        }
        this.resultTitleTimer = setTimeout(() => {
            this.setState({ resultTitle: true });
            this.showResultPage(winVal);
        }, 2000);
    }
    showResultPage(val) {
        if (val) {
            this.props.updateWinText(this.props.countWinText + 1);
            this.setState({ toDisplayWin: true });
            this.TimerWin = setTimeout(() => {
                this.props.gameResult('win');
            }, 1000);
        } else {
            this.setState({ toDisplayLose: true });
            this.TimerLose = setTimeout(() => {
                this.props.gameResult('lose');
            }, 1000);
        }
    }

    render() {
        const cardDetails = {
            cardValue: this.state.openCardValue,
            cardSymbol: this.state.openCardSymbol,
            cssName: 'cardDetailsCss',
        };

        const cardRevealedDetails = {
            cardValue: this.state.cardToRevealValue,
            cardSymbol: this.state.cardToRevealSymbol,
            cssName: 'cardRevealedDetailsCss',
        };
        const bg =  AWS_URL + 'bg.png?ver=' + IMAGE_VERSION;
        const btn_high_inactive =  AWS_URL + 'btn-high-inactive.png?ver=' + IMAGE_VERSION;
        const btn_high_normal =  AWS_URL + 'btn-high-normal.png?ver=' + IMAGE_VERSION;
        const btn_low_inactive =  AWS_URL + 'btn-low-inactive.png?ver=' + IMAGE_VERSION;
        const btn_low_normal =  AWS_URL + 'btn-low-normal.png?ver=' + IMAGE_VERSION;
        const Hi_Lo_logo_small =  AWS_URL + 'Hi-Lo-logo-small.png?ver=' + IMAGE_VERSION;
        const cardHigh  =  AWS_URL + 'next-card-text.png?ver=' + IMAGE_VERSION;
        const Text_good_guess  =  AWS_URL + 'Text-good-guess.png?ver=' + IMAGE_VERSION;
        const Text_gt8_job  =  AWS_URL + 'Text-gt8-job.png?ver=' + IMAGE_VERSION;
        const Text_nice_one  =  AWS_URL + 'Text-nice-one.png?ver=' + IMAGE_VERSION;
        const total_win_small  =  AWS_URL + 'total-win-small.png?ver=' + IMAGE_VERSION;
        const TryAgaintext  =  AWS_URL + 'TryAgaintext.png?ver=' + IMAGE_VERSION;

        return (
            <div className={css['high-low-game-placeholder']}>
                <div className={css['high-low-game']} style={{backgroundImage: `url(${bg})`}}>
                    {this.props.noOfTimesPlayed === 0 &&
                        this.state.showTitle && <HighLowIcon icon="play_game" />}
                    {this.props.noOfTimesPlayed > 0 && (
                        <div className={css['high-lo-small-icon-placeholder']}>
                            <div className={css['high-lo-small-icon']} style={{backgroundImage: `url(${Hi_Lo_logo_small})`}}/>
                        </div>
                    )}
                </div>
                {/* {this.state.nextRound && (
                    <div>
                        <div className={css['lose-msg-placeholder']}>
                            <div className={css['next-round-icon']} />
                        </div>
                    </div>
                )} */}
                {this.state.toDisplayWin &&
                    this.state.resultTitle && (
                        <div
                            className={
                                this.props.countWinText === 2
                                    ? css.result_text_placeholder_good_guess
                                    : css.result_text_placeholder
                            }
                        >
                            {this.props.countWinText === 1 && <div className={css.nice_one} style={{backgroundImage: `url(${Text_nice_one})`}} />}
                            {this.props.countWinText === 2 && <div className={css.good_guess} style={{backgroundImage: `url(${Text_good_guess})`}} />}
                            {this.props.countWinText === 3 && <div className={css.great_job} style={{backgroundImage: `url(${Text_gt8_job})`}}/>}
                        </div>
                    )}
                {this.state.toDisplayLose &&
                    this.state.resultTitle &&
                    this.props.noOfTimesPlayed !== 2 && (
                        <div className={css.result_text_placeholder_try}>
                            <div className={css.try_again} style={{backgroundImage: `url(${TryAgaintext})`}}/>
                        </div>
                    )}
                {!this.state.gameStarted &&
                    this.state.showQs && (
                        <div className={css.title_text_placeholder}>
                            <img src={cardHigh} style={{ width: '100%' }} />
                        </div>
                    )}
                {this.state.showCard && (
                    <Card cardDetails={cardDetails} />
                )}
                <div className={css['high-btn-placeholder']}>
                    {!this.state.gameStarted &&
                        this.state.flipButton && (
                            <div
                                className={css['high-btn']}
                                id="highbtn"
                                onClick={this.checkHilo.bind(this, 'highbtn')}
                                style={{backgroundImage: `url(${btn_high_normal})`}}
                            />
                        )}
                    {this.state.gameStarted && <div className={css['high-btn-disabled']}  style={{backgroundImage: `url(${btn_high_inactive})`}} />}
                </div>
                <div className={css['low-btn-placeholder']}>
                    {!this.state.gameStarted &&
                        this.state.flipButton && (
                            <div
                                className={css['low-btn']}
                                id="lowbtn"
                                onClick={this.checkHilo.bind(this, 'lowbtn')}
                                style={{backgroundImage: `url(${btn_low_normal})`}}
                            />
                        )}
                    {this.state.gameStarted && <div className={css['low-btn-disabled']} style={{backgroundImage: `url(${btn_low_inactive})`}} />}
                </div>
                {this.state.showBaseRightCard && (
                    <Card cardDetails={cardRevealedDetails}  />
                )}
                <div className={css.total_win_placeholder}>
                    {this.state.showBaseRightCard &&
                        this.props.countWin >= 1 && (
                            <div className={css.total_win} style={{backgroundImage: `url(${total_win_small})`}}>
                                <div className={`${css.score_placeholder} ${css.text_yellow}`}>
                                    {this.props.totalWinAmount &&
                                        this.props.totalWinAmount.toLocaleString()}
                                </div>
                            </div>
                        )}
                </div>
            </div>
        );
    }
}
