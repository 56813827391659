// @flow
import * as React from 'react';
import audioManagerInstance from '../../managers/audioManager';
import { COUNTRY_LOOKUP, IMAGE_VERSION, AWS_URL } from '../../constants/matcher';
import css from './style.css';
import PubSub from 'pubsub-js';

type PropsT = {
    isSfxEnabled: boolean,
    setLobbyMusicPlaying: func,
    isEuCitizen: boolean,
    cookies: any,
    optOutConsent: func,
    toggleLobbyMusic: func,
    baseUrl: string,
};
type StateT = {};

export default class Settings extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.toggleMusicPlayingState = this.toggleMusicPlayingState.bind(this);
        this.handleMouseHover = this.handleMouseHover.bind(this);
        this.optOutConsent = this.optOutConsent.bind(this);
        this.gotToPrivacyTerms = this.gotToPrivacyTerms.bind(this);
        const { cookies } = this.props;
        // let cookieForLobbyAudio = cookies.get('__gsn__lobbymusic');
        // if (cookieForLobbyAudio) cookieForLobbyAudio = cookieForLobbyAudio === 'true';
        this.state = {
            isHovering: false,
            sfxIconDisplay: props.isSfxEnabled,
            lobbyIconDisplay: false,
            lobbyMusic: '',
            lobbyMusicVal: window.localStorage.getItem('musicEnabled'),
            url: props.baseUrl + '/CasinoTheme_Disco.mp3',
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.playMusic = this.playMusic.bind(this);
        this.setLobbyMusic = this.setLobbyMusic.bind(this);
    }

    componentDidMount() {
        this.setLobbyMusic();
        const element = document.getElementById('casinoId');
        const lobbyMusicVal = this.state.lobbyMusicVal;
        if (lobbyMusicVal === 'true') {
            this.playAudio = setInterval(() => {
                this.playMusic(element);
            }, 500);
            document.addEventListener('mousedown', this.handleClickOutside);
            this.props.toggleLobbyMusic(true);
        } else {
            element && element.pause();
            this.setState({ lobbyMusic: false });
            this.props.toggleLobbyMusic(false);
        }
        PubSub.subscribe('WEBGL_IS_LOADED', (target, msg) => {
            if (msg) {
                clearInterval(this.playAudio);
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.playAudio);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setLobbyMusic() {
        if (this.state.lobbyMusicVal === 'true') {
            this.setState({ lobbyMusic: true });
        } else {
            this.setState({ lobbyMusic: false });
        }
    }

    playMusic(element) {
        const play = element.play();
        play &&
            play
                .then(() => {
                    this.handleClickOutside();
                })
                .catch((error) => {});
    }

    handleClickOutside() {
        setTimeout(() => {
            clearInterval(this.playAudio);
        }, 1000);
    }
    toggleMusicPlayingState() {
        const element = document.getElementById('casinoId');
        if (this.state.lobbyMusic) {
            element.pause();
            this.setState({ lobbyMusic: false });
            localStorage.setItem('musicEnabled', 'false');
        } else {
            element.play();
            this.setState({ lobbyMusic: true });
            localStorage.setItem('musicEnabled', 'true');
        }
    }
    optOutConsent() {
        //return this.props.optOutConsent();
        window.open(
            `https://gsnsocial.wufoo.com/forms/sq7ogl21ko7e8z/def/field10=${
                this.props.userData.id
            }&field12=${this.props.seamUser.id}`,
            '_blank'
        );
    }

    gotToPrivacyTerms() {
        window.open('https://www.scopely.com/en/legal?id=privacy&langSection=en', '_blank');
    }

    handleMouseHover() {
        this.setState(this.toggleHoverState);
    }

    toggleHoverState(state) {
        return {
            isHovering: !state.isHovering,
        };
    }

    render() {
        const euCountry = COUNTRY_LOOKUP.includes(this.props.userCountryCode);
        const btnClass = this.state.lobbyMusic ? css['unmute-button'] : css['mute-button'];
        const sfxbtnClass = this.props.isSfxEnabled
            ? css['sfx-button']
            : css['disabled-sfx-button'];
        const blockClass = euCountry ? 'container-block-drop-3' : 'container-block-drop-2';

        const menu_dropdown_spritesheet =  AWS_URL + 'menu-dropdown-spritesheet.png?ver=' + IMAGE_VERSION;
        const menu_icon_active =  AWS_URL + 'menu_icon_active.png?ver=' + IMAGE_VERSION;

        return (
            <div className={css['settings-placeholder']}>
                {/* <audio id="casinoId" autoPlay>
                    <source src={this.state.url} type="audio/mpeg" />
                </audio> */}
                <audio id="casinoId">
                    <source src={this.state.url} type="audio/mpeg" />
                </audio>

                <div
                    className={css.menu_button}
                    id="menuBtn"
                    onClick={this.handleMouseHover}
                    onMouseLeave={this.handleMouseHover}
                    style={{backgroundImage: `url(${menu_icon_active})`}}
                >
                    {this.state.isHovering && (
                        <div className={css[blockClass]} style={{backgroundImage: `url(${menu_dropdown_spritesheet})`}}>
                            <div style={{ position: 'relative' }}>
                                <button
                                    id="muteButton"
                                    className={btnClass}
                                    onClick={this.toggleMusicPlayingState}
                                    style={{backgroundImage: `url(${menu_dropdown_spritesheet})`}}
                                />
                            </div>

                            {euCountry && (
                                <div
                                    style={{
                                        position: 'relative',
                                        display: this.state.sfxIconDisplay ? 'block' : 'none',
                                    }}
                                >
                                    <button
                                        id="consentButton"
                                        className={css['consent-button']}
                                        onClick={this.optOutConsent}
                                        style={{backgroundImage: `url(${menu_dropdown_spritesheet})`}}
                                    />
                                </div>
                            )}
                            <div
                                style={{
                                    position: 'relative',
                                    display: this.state.sfxIconDisplay ? 'block' : 'none',
                                }}
                            >
                                <button
                                    id="gdprTermsButton"
                                    className={css['gdpr-button']}
                                    onClick={this.gotToPrivacyTerms}
                                    style={{backgroundImage: `url(${menu_dropdown_spritesheet})`}}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
