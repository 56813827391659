// @flow
import * as React from 'react';
import css from './style.css';
import { TweenMax, Circ } from 'gsap';
import PubSub from 'pubsub-js';
import SeamServices from '../../services/seamService';
import HighLowIcon from '../HighLowIcon/HighLowIcon';
import analyticslog from '../../constants/analyticslog';
import analyticsManagerInstance from '../../managers/analyticsManager';
import { PLATFORM_GSNCOM, IMAGE_VERSION, AWS_URL } from '../../constants/matcher';

type PropsT = {
    openHighLowGame: func,
    show: string,
    closeModal: func,
    registerGameCount: func,
    noOfTimesPlayed: number,
    openHighLowGame: func,
    updateTokenBalance: func,
    countWin: number,
    updateWinCount: func,
    isMiniGameOver: func,
    gameRoundNo: number,
    calculateTotalWinAmount: func,
    totalWinAmount: number,
    WebglLoaded: boolean,
    tokensAmountPerRound: number,
    updateTokensWonPerRound: func,
    tokensWonPerRound: number,
    gameMathModel: func,
    seamSession: string,
    gsnCredentials: any,
    fbUserId: string,
    userId: string,
    leftCardValue: number,
    rightCardValue: number,
    buttonClicked: string,
    seamTokenBalance: number,
    country: string,
    operatingSystem: string,
    platform: string,
    appSessionId: string,
};
type StateT = {};
const analyticsLogs = new analyticslog();

export default class HighLowResult extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            winningAmount: 0,
            enableButton: false,
            wonPlaceholder: false,
            showConfiti: false,
            nextRound: false,
            tokensAmountPerRound: 0,
            mathArr: props.gameMathModel,
            logEngineeringEvents: props.logEngineeringEvents,
        };
        this.playAgain = this.playAgain.bind(this);
        this.setCounter = this.setCounter.bind(this);
        this.calculateWinAmount = this.calculateWinAmount.bind(this);
    }

    componentDidMount() {
        let userId;
        if(this.props.platform === PLATFORM_GSNCOM){
            userId = this.props.gsnCredentials && this.props.gsnCredentials.authResponse && this.props.gsnCredentials.authResponse.userID;
        }else{//FOR FB
            userId = this.props.fbUserId;
        }
        this.setCounter(userId);
        this.wonPlaceholderTimer = setTimeout(() => {
            this.setState({ wonPlaceholder: true });
        }, 500);
        this.enableButtonTimer = setTimeout(() => {
            this.setState({ enableButton: true });
        }, 700);
        this.showTextTimer = setTimeout(() => {
            this.setState({ showConfiti: true });
        }, 900);
    }

    componentWillUnmount() {
        clearTimeout(this.wonPlaceholderTimer);
        clearTimeout(this.enableButtonTimer);
        clearTimeout(this.showTextTimer);
    }
    calculateWinAmount(userId) {
        const winVal = Math.random();
        let tokens = this.state.tokensAmountPerRound;
        let gameProbabilities = JSON.parse(this.state.mathArr);
        gameProbabilities = gameProbabilities.highLow;

        gameProbabilities.map((key, index) => {
            const val = gameProbabilities[index];
            if (winVal >= val.min && winVal <= val.max) {
                this.setState({ tokensAmountPerRound: val.tokens });
                tokens = val.tokens;
            }
        });

        SeamServices.setTokenBalance(tokens, this.props.seamSession)
            .then(() => {
                SeamServices.tokenBalance(this.props.seamSession)
                    .then((data) => {
                        if(Number.isInteger(data)) {
                            this.props.updateTokenBalance(data);
                            //Analytics Start
                            const lodData = {
                                eventGroup: 'STARTUP',
                                eventCode: 'MINIGAME',
                                eventSubCode: 'REWARD',
                                sessionToken: this.props.seamSession.sessionToken,
                                eventAttributes: {
                                    game_name: 'hi-low',
                                    trigger: this.props.gameRoundNo === 1 ? 'generic_flow' : 'Reload',
                                    initCard: this.props.leftCardValue,
                                    revCard: this.props.rightCardValue,
                                    action: this.props.buttonClicked,
                                    coins_balance: data.toString(),
                                    coins_gained: tokens.toString(),
                                    reference_value_1: this.props.leftCardValue.toString(),
                                    reference_value_2: this.props.rightCardValue,
                                    reference_value_3: this.props.buttonClicked,
                                },
                                deviceId: userId,
                                userId: this.props.userId,
                                country: this.props.country,
                                operatingSystem: this.props.operatingSystem,
                                appVersion: this.props.appVersion,
                                app_session_id: this.props.appSessionId,
                                eventName: "cs.rewards_and_ui",
                            };
                            const payload = analyticsLogs.setAnalyticsData(lodData);
                            analyticsManagerInstance.logAnalytics(payload).then(() => { });
                            //Analytics End
                        }else{
                            window.TrackJS && TrackJS.track("Data in High low is not an integer", data);
                        }

                    })
                    .catch((error) => {
                        console.error('Exception:: Seam updateTokenBalance Service', {
                            message: error,
                        });
                        //Analytics - ENGINEERING-start
                        const errorLog = {
                            eventGroup: 'ENGINEERING',
                            eventSubCode: 'REWARD',
                            eventCode: 'GET_TOKEN_MINI_GAME_ERROR',
                            sessionToken:
                            this.props.seamSession && this.props.seamSession.sessionToken,
                            eventAttributes: {
                                game_name: 'hi-low',
                                trigger: this.props.gameRoundNo === 1 ? 'generic_flow' : 'Reload',
                            },
                            deviceId:userId,
                            country: this.props.country,
                            operatingSystem: this.props.operatingSystem,
                            appVersion: this.props.appVersion,
                            app_session_id: this.props.appSessionId,
                            eventName: "sys.logging",
                        };
                        this.state.logEngineeringEvents && analyticsManagerInstance
                            .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                            .then(() => { });
                        //Analytics - ENGINEERING-End
                        PubSub.publish('SEAM_SERVICE_ERROR', error);
                    });
            })
            .catch((err) => {
                console.error('Exception:: Seam set token service', {
                    message: err,
                });
                //Analytics - ENGINEERING-start
                const errorLog = {
                    eventGroup: 'ENGINEERING',
                    eventSubCode: 'REWARD',
                    eventCode: 'SET_TOKEN_MINI_GAME_ERROR',
                    sessionToken: this.props.seamSession && this.props.seamSession.sessionToken,
                    eventAttributes: {
                        game_name: 'hi-low',
                        trigger: this.props.gameRoundNo === 1 ? 'generic_flow' : 'Reload',
                    },
                    deviceId: userId,
                    appVersion: this.props.appVersion,
                    app_session_id: this.props.appSessionId,
                    eventName: "sys.logging",
                };
                this.state.logEngineeringEvents && analyticsManagerInstance
                    .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                    .then(() => { });
                //Analytics - ENGINEERING-End
                PubSub.publish('SEAM_SERVICE_ERROR', err);
            });

        this.props.updateTokensWonPerRound(tokens);
        this.props.calculateTotalWinAmount(this.props.totalWinAmount + tokens);
        return this.props.totalWinAmount + tokens;
    }
    setCounter(userId) {
        let amount;
        let winCount = this.props.countWin || 0;
        if (this.props.show === 'win') {
            winCount = this.props.countWin + 1;
            this.props.updateWinCount(this.props.countWin + 1);
            amount = this.calculateWinAmount(userId);
        } else if (this.props.show === 'lose') {
            //Analytics Start
            const lodData = {
                eventGroup: 'STARTUP',
                eventCode: 'MINIGAME',
                eventSubCode: 'REWARD',
                sessionToken: this.props.seamSession.sessionToken,
                eventAttributes: {
                    game_name: 'hi-low',
                    trigger: this.props.gameRoundNo === 1 ? 'generic_flow' : 'Reload', //    TODO - First Time >generic_flow, secondtime>>>>’Reload
                    initCard: this.props.leftCardValue, // Card Value of initial card,
                    revCard: this.props.rightCardValue, // Card value of revield ,
                    action: this.props.buttonClicked, // Button high or Low,
                    coins_balance: this.props.seamTokenBalance,
                    coins_gained: '0',
                    reference_value_1: this.props.leftCardValue,
                    reference_value_2: this.props.rightCardValue,
                    reference_value_3: this.props.buttonClicked,
                },
                deviceId: userId,
                userId: this.props.userId,
                country: this.props.country,
                operatingSystem: this.props.operatingSystem,
                appVersion: this.props.appVersion,
                app_session_id: this.props.appSessionId,
                eventName: "cs.rewards_and_ui",
            };
            const payload = analyticsLogs.setAnalyticsData(lodData);
            analyticsManagerInstance.logAnalytics(payload).then(() => { });
            //Analytics End
        }

        const counter = { winCounter: 0 };
        amount = amount || this.props.totalWinAmount;
        const scoreId = document.getElementById('score');
        const noScore =
            (this.props.gameRoundNo === 2 && this.props.show === 'lose') ||
            (this.props.gameRoundNo === 2 && winCount === 1);
        if (this.props.gameRoundNo > 1 && winCount > 0) {
            if (!noScore) {
                TweenMax.to(counter, 1, {
                    winCounter: amount,
                    onUpdate: function () {
                        scoreId.innerHTML = Math.ceil(counter.winCounter).toLocaleString();
                    },
                    delay: 0.8,
                    ease: Circ.easeOut,
                });
            }
        }

        if (this.props.gameRoundNo === 3) {
            this.Timer = setTimeout(() => {
                this.props.isMiniGameOver(true);
            }, 5000);
        }
    }
    playAgain() {
        this.props.openHighLowGame();
    }

    render() {
        const userWon = this.props.show === 'win';
        const displayYouWon = userWon && this.props.gameRoundNo < 3; // alteast 2 won
        const eachRoundResult = userWon && this.props.countWin === 1; // won first time
        const displayAwesome = this.props.countWin === 3; // you have won atleast 1
        const displayCongrats = this.props.gameRoundNo === 3 && this.props.countWin > 0; // you have won atleast 1

        const awesome_text =  AWS_URL + 'awesome-text.png?ver=' + IMAGE_VERSION;
        const bgimg =  AWS_URL + 'bg.png?ver=' + IMAGE_VERSION;
        const btn_play_again =  AWS_URL + 'btn-play-again.png?ver=' + IMAGE_VERSION;
        const coins_fly =  AWS_URL + 'coins-fly.png?ver=' + IMAGE_VERSION;
        const confitiimg =  AWS_URL + 'confiti.png?ver=' + IMAGE_VERSION;
        const text_betterluck =  AWS_URL + 'text-better-luck.png?ver=' + IMAGE_VERSION;
        const text_congratulations =  AWS_URL + 'text-congratulations.png?ver=' + IMAGE_VERSION;
        const text_you_won =  AWS_URL + 'text-you-won.png?ver=' + IMAGE_VERSION;
        const total_win_big01 =  AWS_URL + 'total-win-big-01.png?ver=' + IMAGE_VERSION;

        const showTotalWin =
            (this.props.gameRoundNo === 2 && userWon && this.props.countWin > 1) || displayCongrats;
        const showWonOrCongrats = displayYouWon || eachRoundResult || displayCongrats;
        return (
            <div className={css['high-low-game-placeholder']}>
                <div className={css['high-low-game']} style={{backgroundImage: `url(${bgimg})`}}/>
                {this.props.show === 'lose' &&
                    this.props.gameRoundNo !== 3 && (
                        <div>
                            <HighLowIcon icon="try_again" />
                            {this.state.enableButton && (
                                <div className={css.lets_play_placeholder}>
                                    <div
                                        className={css.play_again_button}
                                        onClick={this.playAgain}
                                        style={{backgroundImage: `url(${btn_play_again})`}}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                {this.props.show === 'lose' &&
                    this.props.countWin === 0 &&
                    this.props.gameRoundNo === 3 && (
                        <div>
                            {this.state.enableButton && (
                                <div className={css.better_luck_placeholder}>
                                    <div className={css.better_luck} style={{backgroundImage: `url(${text_betterluck})`}}/>
                                </div>
                            )}
                        </div>
                    )}
                {showWonOrCongrats &&
                    !displayAwesome && (
                        <div style={{ justifyContent: 'center' }}>
                            <div
                                className={
                                    displayCongrats
                                        ? css['result-congrats-placeholder']
                                        : css['result-youwon-placeholder']
                                }
                            >
                                {displayCongrats && (
                                    <div
                                        className={css.congratulations}
                                        style={{backgroundImage: `url(${text_congratulations})`}}
                                    />
                                )}

                                {!displayCongrats && (
                                    <div
                                        className={css.you_won}
                                        style={{backgroundImage: `url(${text_you_won})`}}
                                    />
                                )}

                            </div>
                            {this.state.wonPlaceholder &&
                                !displayCongrats && (
                                    <div
                                        className={css['won-placeholder']}
                                        style={{
                                            // justifyContent: 'center',
                                            // display: 'flex',
                                            // flex: '1',
                                            position: 'absolute',
                                            top: '27%',
                                            textAlign: 'center',
                                            marginTop: 'unset',
                                            width: '100%',
                                        }}
                                    >
                                        <div
                                            style={
                                                {
                                                    // display: 'flex',
                                                    // width: '54%',
                                                }
                                            }
                                        >
                                            <div
                                            // style={{ flex: '3', justifyContent: 'flex-end' }}
                                            // className={css['flex-style']}
                                            >
                                                <span className={css.text_yellow}>
                                                    {this.state.tokensAmountPerRound}{' '}
                                                    <span style={{ color: '#ffffff' }}>tokens</span>
                                                </span>
                                            </div>
                                            {/* <div
                                                style={{ flex: '3', justifyContent: 'flex-start' }}
                                                className={css['flex-style']}
                                            >
                                                <span className={css.text_white}>tokens</span>
                                            </div> */}
                                        </div>
                                    </div>
                                )}
                        </div>
                    )}
                {userWon &&
                    displayAwesome && (
                        <div className={css.awesome_result_placeholder}>
                            <div className={css.awesome} style={{backgroundImage: `url(${awesome_text})`}}/>
                            {this.state.wonPlaceholder && (
                                <div className={css['guessed-text-placeholder']}>
                                    <div className={css.guessed_text_1}> You guessed all</div>
                                    <div className={css.guessed_text_2}>  three right</div>
                                </div>
                            )}
                        </div>
                    )}

                {showWonOrCongrats &&
                    this.state.showConfiti && (
                        <div>
                            <div className={css.coins_fly_placeholder}>
                                <div className={css.coins_fly} style={{backgroundImage: `url(${coins_fly})`}}/>
                            </div>
                            {!displayYouWon && (
                                <div className={css.confiti_placeholder}>
                                    <div className={css.confiti} style={{backgroundImage: `url(${confitiimg})`}}/>
                                </div>
                            )}
                        </div>
                    )}

                {showTotalWin && (
                    <div>
                        <div className={css.total_win_placeholder}>
                            <div className={css.total_win_big} style={{backgroundImage: `url(${total_win_big01})`}}/>
                        </div>
                    </div>
                )}
                {this.props.gameRoundNo > 1 && (
                    <div
                        className={`${css.text_score} ${css.text_yellow_score}`}
                        style={{
                            width: '36%',
                            /* background-color: antiquewhite; */
                            left: '31%',
                        }}
                        id="score"
                    />
                )}
                {userWon &&
                    this.state.enableButton &&
                    this.props.gameRoundNo !== 3 && (
                        <div
                            className={
                                eachRoundResult
                                    ? css.play_again_placeholder
                                    : css.collect_play_button_placeholder
                            }
                        >
                            <div className={css.play_again_button} onClick={this.playAgain}   style={{backgroundImage: `url(${btn_play_again})`}}/>
                        </div>
                    )}
            </div>
        );
    }
}
