/* eslint-disable */
'use strict';
import adsData from './tests/ads_mock.json';
import _ from 'lodash';
import promises from 'bluebird';
import {
    FC_ADS_TOGGLE,
    FC_ADS_REFRESH_RATE,
    FC_MINI_GAME_MATH,
    FC_MINI_GAME_MAX_TOKENS,
    FC_EVENTS_ENGINEERING,
    FC_ADS_WHITELIST,
    FC_CONSENT_CONFIG,
    FC_ADS_RewardVideo,
    PLATFORM_GSNCOM,
    FC_SHOW_HILO,
    FC_LOADING_GAMESLIST,
    FC_WEBGL_ROLLOUT,
    FC_LOADING_MIGRATIONPOPUP,
    FC_ISPLAYGAMI_PAYMENT,
    FC_WEB_ADS_PROVIDER,
    FC_CLEAR_INDEXDB,
    Classic_Casino_Url_backend,
    APP_VERSION_ROLLOUT,

} from '../constants/matcher';
// eslint-disable-next-line import/no-webpack-loader-syntax
import uuid from 'uuid';
import { async } from 'rxjs/internal/scheduler/async';
import {getOptionalBuild} from '../constants/loadOptionalBuild';
import { GSNCOM_APP_VERSION, APP_VERSION, SEAM_ENVIRONMENT } from '../constants/matcher';

const findItems = (data, criteria) => _.find(data, ['_key', criteria]);

class SeamServices {
    constructor() {
        this.classicCasinoUrl = Classic_Casino_Url_backend + 'auth/canvas_classic'
        this._noSeamError = 'No SEAM Service Available';
        this._noSeamErrorAuthFail = 'SEAM Service Authentication Failed';
        this._deviceRegError = 'Error in SEAM Device Registration';
        this._deviceLinkError = 'Error in SEAM Device Linking';
        this._deviceGameTokenError = 'Error in SEAM Game token';
        this._updateTokenBalanceError = 'Error in SEAM token updation';
        this._getTokenBalanceError = 'Error in getting SEAM token Balance';
        this._getGDPR = 'Error in getting GDPR country Code';
        this._getCCPA = 'Error in getting CCPA Retrive';
        this._getFCError = 'Error in getting FC';
        this._getEngineeringEventFlag = false;
        this.authorizeSeam = this.authorizeSeam.bind(this);
        this.responseGame = null;
        this.tokenBalance = this.tokenBalance.bind(this);
        this.getLogAnalytics = this.getLogAnalytics.bind(this);
        this.registerDevice = this.registerDevice.bind(this);
        this.registerDeviceGsnCom = this.registerDeviceGsnCom.bind(this);
        this.linkDevice = this.linkDevice.bind(this);
        this.setTokenBalance = this.setTokenBalance.bind(this);
        this.getUserGameToken = this.getUserGameToken.bind(this);
        this.getFeatureConfigEngineering = this.getFeatureConfigEngineering.bind(this);
        this.getCountriesWhereAdsCanRefresh = this.getCountriesWhereAdsCanRefresh.bind(this);
        this.userConsentInfo = this.userConsentInfo.bind(this);
        this.userConsentInfoGSNCOM = this.userConsentInfoGSNCOM.bind(this);
        this.recordUserConsent = this.recordUserConsent.bind(this);
        this.getOrderSignature = this.getOrderSignature.bind(this);
        this.getUnityAppVersion = this.getUnityAppVersion.bind(this);
        this.updateUserInfo = this.updateUserInfo.bind(this);
        this.doApiCall = this.doApiCall.bind(this);
        this.sessionTokenOfUser;
        this.userLevel = null;
        this.loadedwebglbuild = APP_VERSION_ROLLOUT;
    }


    getSeamBaseURL() {
       if(SEAM_ENVIRONMENT === 'production') {
           return 'https://classic-casino-us-east-1.api-prod.classiccasino.com/production'
       }else{
           return 'https://classic-casino-us-east-1.api-dev.classiccasino.com/qa'
       }
    }

    doApiCall(body) {
        return fetch(this.classicCasinoUrl, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin' : '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'appversion': this.loadedwebglbuild,
                'playerLevel': this.userLevel
                //     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGVjNTVjNDQxNDY5MjFhYjYzYzY2NjciLCJzZXNzaW9uVG9rZW4iOiJHaDNUbjNnL1BiVzRxWk4zRm0wNi94aWxBamRYbHZ4Q2Q3YW1zVkxQN0RMYis5OFBBNjdYMnc9PSIsImxvZ2luVHlwZSI6ImVtYWlsIiwiaWRlbnRpZmllciI6Im1hbmFzYWEiLCJpYXQiOjE3MDE3NzAwMjYsImV4cCI6MTcwODY4MjAyNn0.28LiaGbjoDqKsHkxYcn2bUaF-GRrZGFTPEmQLR1ftcs',
            },
            body: body,
            mode: 'cors',
        });
    }

    updateUserInfo() {
        return new Promise((resolve, reject) => {
            const payload =  {
                credentials: [
                    {
                        type: 'session',
                        sessionToken: this.sessionTokenOfUser
                    }
                ]
            }
            let body = JSON.stringify({
                product: {game_type: 'CASINO'},
                target: '/users/authenticate',
                requestBody: payload,
                requestType: 'POST',
                appmode: 'APP'
            });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in updateUserInfo');
            }).then((data) => {
                this.sessionTokenOfUser = data.sessionToken;
                this.userLevel = data.profile.level;
                resolve(data);
            }).catch((err) => {
                reject(err);
            });

        });
    }

    authorizeSeam(facebookResposne) {
        return new Promise((resolve, reject) => {
            const userModal = {"profile":{"hasGsnPortal":false},
                "credentials":[ {
                    type: 'facebook',
                    facebookId: facebookResposne.authResponse.userID,
                    accessToken: facebookResposne.authResponse.accessToken,
                }]}
                const appvers = this.getUnityAppVersion();
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/users/canvas/authenticate', requestBody: userModal, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in authorizeSeam');
                }).then((data) => {
                    this.sessionTokenOfUser = data.sessionToken;
                    this.userLevel = data.profile.level;
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });


        });
    }
    authorizeSeamGSNCom(gsnComResponse) {
        return new Promise((resolve, reject) => {

                const userModel = gsnComResponse;
                const appvers = this.getUnityAppVersion();
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/users/authenticate', requestBody: userModel, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in authorizeSeamGSNCom');
                }).then((data) => {
                    this.sessionTokenOfUser = data.sessionToken;
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });


        });
    }
    changeEmailPassword(payload, sessionToken) {
        return new Promise((resolve, reject) => {
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/users/update/emailpassword', requestBody: payload, requestType: 'POST', appmode: 'USER', session: sessionToken });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in changeEmailPassword');
                }).then((data) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });

        });
    }

    getUnityAppVersion() {
        let appVersion = getOptionalBuild('facebook');
        if(appVersion == ' ' || appVersion == '' || appVersion==undefined || appVersion==null) {
            appVersion = APP_VERSION;
        }
        return appVersion;
    }

    getFeatureConfigGlobalToggle() {
        return new Promise((resolve, reject) => {

                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_ADS_TOGGLE],
                    context:{"user":{},"device":{"appVersion":appver}}
                }

                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in getFeatureConfigGlobalToggle');
                }).then((data) => {
                    resolve(data[0].value[FC_ADS_TOGGLE]);
                }).catch((err) => {
                    reject(err);
                });

        });
    }

    getFeatureConfigDisplayHighLowGame() {
        return new Promise((resolve, reject) => {

                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_SHOW_HILO],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in getFeatureConfigDisplayHighLowGame');
                }).then((data) => {
                    resolve(data[0].value[FC_SHOW_HILO]);
                }).catch((err) => {
                    reject(err);
                });

        });
    }

    getFeatureConfigGamesList() {
        return new Promise((resolve, reject) => {

                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_LOADING_GAMESLIST],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in getFeatureConfigGamesList');
                }).then((data) => {
                    resolve(data[0].value[FC_LOADING_GAMESLIST]);
                }).catch((err) => {
                    reject(err);
                });

        });
    }

    getFeatureConfigEngineering() {
        return new Promise((resolve, reject) => {

                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_EVENTS_ENGINEERING],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in getFeatureConfigEngineering');
                }).then((data) => {
                    resolve(data[0].value[FC_EVENTS_ENGINEERING]);
                }).catch((err) => {
                    reject(err);
                });

        });
    }
    getCountriesWhereAdsCanRefresh() {
        return new Promise((resolve, reject) => {

                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_ADS_WHITELIST],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in getCountriesWhereAdsCanRefresh');
                }).then((data) => {
                    resolve(data[0].value[FC_ADS_WHITELIST]);
                }).catch((err) => {
                    reject(err);
                });
        });
    }

    getFeatureConfigRefreshRate() {
        return new Promise((resolve, reject) => {
                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_ADS_REFRESH_RATE],
                    context:{"user":{},"device":{"appVersion":appver}}
                }

                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in getFeatureConfigRefreshRate');
                }).then((data) => {
                    resolve(data[0].value[FC_ADS_REFRESH_RATE]);
                }).catch((err) => {
                    reject(err);
                });

        });
    }
    getFeatureConfigConsentConfig() {
        return new Promise((resolve, reject) => {

                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_CONSENT_CONFIG],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in getFeatureConfigConsentConfig');
            }).then((data) => {
                resolve(data[0].value[FC_CONSENT_CONFIG]);
            }).catch((err) => {
                    reject(err);
                });

        });
    }
    getFeatureConfigMiniGameMath() {
        return new Promise((resolve, reject) => {

                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_MINI_GAME_MATH],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in getFeatureConfigMiniGameMath');
            }).then((data) => {
                resolve(data[0].value[FC_MINI_GAME_MATH]);
            }).catch((err) => {
                reject(err);
            });

        });
    }
    getFeatureConfigMaxTokens() {
        return new Promise((resolve, reject) => {

                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_MINI_GAME_MAX_TOKENS],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in getFeatureConfigMaxTokens');
            }).then((data) => {
                resolve(data[0].value[FC_MINI_GAME_MAX_TOKENS]);
            }).catch((err) => {
                reject(err);
            });


        });
    }

    tokenBalance(seamAuth) {
        return new Promise((resolve, reject) => {
            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/balance/v1/balance/seam/classic-casino/user/'+seamAuth._id+'/token/' , session: seamAuth.sessionToken, requestType: 'GET', appmode: 'USER' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in tokenBalance');
            }).then((data) => {
                resolve(data.value);
            }).catch((err) => {
                reject(err);
            });


        });
    }

    setTokenBalance(v, seamAuth) {
        return new Promise((resolve, reject) => {
                const params = {};
                params.getIdProvider = () => "seam";
                params.getAppId = () => "classic-casino";
                params.getEntityType = () => "user";
                params.getEntityId = () => seamAuth._id;
                params.getBalanceType = () => "token";

                const balanceWithParams = {
                    balanceId: null,
                    value: v,
                    clientCreatedDate: new Date(),
                    clientUUID: uuid.v1(),
                    source: 'highLow',
                    gameId: 'highlow',
                };

            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/balance/v1/balance/adjustment/seam/classic-casino/user/'+seamAuth._id+'/token/' , requestBody: balanceWithParams, session: seamAuth.sessionToken, requestType: 'POST', appmode: 'USER' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in setTokenBalance');
            }).then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err);
            });


        });
    }
    getUserGameToken(seamAuth) {
        const pathParams = {
            "gameId": 'highlow',
        }

        return new Promise((resolve, reject) => {
            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/balance/v1/balance/game/adjustments/seam/classic-casino/user/'+seamAuth._id+'/token', query: '?gameId=highlow' , requestBody: pathParams ,session: seamAuth.sessionToken, requestType: 'GET', appmode: 'USER' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in getUserGameToken');
            }).then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err);
            });


        });
    }

    registerDevice(fbId, countryCode) {
        return new Promise((resolve, reject) => {
                var appId = APP_VERSION
                if(fbId === null || fbId === ''){
                    console.error("Null User Id occured while registering the device", fbId);
                }
                if(getOptionalBuild('facebook') !== undefined){
                    appId = getOptionalBuild('facebook');
                }
                var deviceToken = null;
                const analyticsContext = localStorage.getItem('com.scopely:analytics:ctx');
                console.log('Analytics Context:', analyticsContext);
                if (analyticsContext) {
                    try {
                        var parsedContext = JSON.parse(analyticsContext);
                        deviceToken = parsedContext.deviceToken;
                        console.log('Device Token:', deviceToken);
                    } catch (error) {
                        console.error('Error parsing analytics context:', error);
                    }
                } else {
                    console.log('No value found for com.scopely:analytics:ctx in localStorage.');
                }
                const params = {
                    id: `WEB_ID:${fbId}`,
                    internalDeviceId: null,
                    info: {
                        platform: 'web',
                        device: 'Browser',
                        deviceType: 'Desktop',
                        gamesCanvasId: fbId,
                        appVersion: appId,
                        country: countryCode,
                        playgamiDeviceToken: deviceToken,
                    }
                };

            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/devices/v1/register/' , requestBody: params, requestType: 'POST', appmode: 'APP' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in registerDevice');
            }).then((data) => {
                resolve(data.id);
            }).catch((err) => {
                reject(err);
            });

        });
    }

    registerDeviceWithAppid(fbId, appId, countryCode) {
        return new Promise((resolve, reject) => {
                if(getOptionalBuild('facebook') !== undefined){
                    appId = getOptionalBuild('facebook');
                }
                if(fbId === null || fbId === ''){
                    console.error("Null User Id occured while registering the device with appid", fbId);
                }
                var deviceToken = null;
                const analyticsContext = localStorage.getItem('com.scopely:analytics:ctx');
                console.log('Analytics Context:', analyticsContext);
                if (analyticsContext) {
                    try {
                        var parsedContext = JSON.parse(analyticsContext);
                        deviceToken = parsedContext.deviceToken;
                        console.log('Device Token:', deviceToken);
                    } catch (error) {
                        console.error('Error parsing analytics context:', error);
                    }
                } else {
                    console.log('No value found for com.scopely:analytics:ctx in localStorage.');
                }
                const params = {
                    id: `WEB_ID:${fbId}`,
                    internalDeviceId: null,
                    info: {
                        platform: 'web',
                        device: 'Browser',
                        deviceType: 'Desktop',
                        gamesCanvasId: fbId,
                        appVersion: appId,
                        country: countryCode,
                        playgamiDeviceToken: deviceToken,
                    }
                };

                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/devices/v1/register/' , requestBody: params, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in registerDeviceWithAppid');
                }).then((data) => {
                    resolve(data.id);
                }).catch((err) => {
                    reject(err);
                });

        });
    }

    registerDeviceGsnCom(iswebstore, gsnId, countryCode) {
        return new Promise((resolve, reject) => {

                var appId = GSNCOM_APP_VERSION;
                if(gsnId === null || gsnId === ''){
                    console.error("Null gsn Id occured", gsnId);
                }
                if(getOptionalBuild('facebook') !== undefined){
                    appId = getOptionalBuild('facebook');
                }
                var platform  = PLATFORM_GSNCOM;
                if(iswebstore) {
                    platform = 'webstore';
                }
                var deviceToken = null;
                const analyticsContext = localStorage.getItem('com.scopely:analytics:ctx');
                console.log('Analytics Context:', analyticsContext);
                if (analyticsContext) {
                    try {
                        var parsedContext = JSON.parse(analyticsContext);
                        deviceToken = parsedContext.deviceToken;
                        console.log('Device Token:', deviceToken);
                    } catch (error) {
                        console.error('Error parsing analytics context:', error);
                    }
                } else {
                    console.log('No value found for com.scopely:analytics:ctx in localStorage.');
                }
                const params = {
                    id: `WEB_ID:${gsnId}`,
                    internalDeviceId: null,
                    info: {
                        platform: platform,
                        device: 'Browser',
                        deviceType: 'Desktop',
                        gamesCanvasId: gsnId,
                        appVersion: appId,
                        country: countryCode,
                        playgamiDeviceToken: deviceToken,
                    }
                };
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/devices/v1/register/' , requestBody: params, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in registerDeviceGsnCom');
                }).then((data) => {
                    resolve(data.id);
                }).catch((err) => {
                    reject(err);
                });


        });
    }

    registerDeviceGsnComWithAppid(iswebstore, gsnId, appid) {
        return new Promise((resolve, reject) => {

                if(gsnId === null || gsnId === ''){
                    console.error("Null User Id occured in gsncom", gsnId);
                }
                if(getOptionalBuild('facebook') !== undefined){
                    appid = getOptionalBuild('facebook');
                }
                var platform  = PLATFORM_GSNCOM;
                if(iswebstore) {
                    platform = 'webstore';
                }
                var deviceToken = null;
                const analyticsContext = localStorage.getItem('com.scopely:analytics:ctx');
                console.log('Analytics Context:', analyticsContext);
                if (analyticsContext) {
                    try {
                        var parsedContext = JSON.parse(analyticsContext);
                        deviceToken = parsedContext.deviceToken;
                        console.log('Device Token:', deviceToken);
                    } catch (error) {
                        console.error('Error parsing analytics context:', error);
                    }
                } else {
                    console.log('No value found for com.scopely:analytics:ctx in localStorage.');
                }
                const params = {
                    id: `WEB_ID:${gsnId}`,
                    internalDeviceId: null,
                    info: {
                        platform: platform,
                        device: 'Browser',
                        deviceType: 'Desktop',
                        gamesCanvasId: gsnId,
                        appVersion: appid,
                        playgamiDeviceToken: deviceToken,
                    }
                };
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/devices/v1/register/' , requestBody: params, requestType: 'POST', appmode: 'APP' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in registerDeviceGsnComWithAppid');
            }).then((data) => {
                resolve(data.id);
            }).catch((err) => {
                reject(err);
            });

        });
    }

    linkDevice(sessionToken, userId) {
        return new Promise((resolve, reject) => {

                if(userId === null || userId === ''){
                    console.error("Null User Id occured while linking device", sessionToken);
                }

                const queryParam = {
                    deviceId: `WEB_ID:${userId}`,
                }
                // /qa/devices/v2/link?deviceId=WEB_ID%3A184184292070367
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/devices/v2/link' ,query: '?deviceId=WEB_ID:'+userId, requestType: 'GET', appmode: 'USER', session: sessionToken });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in linkDevice');
                }).then((data) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });


        });
    }

    getLogAnalytics(logData) {
        return new Promise((resolve, reject) => {
            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/analytics/v1/event/postEvent', requestBody: logData, requestType: 'POST', appmode: 'APP' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in getLogAnalytics');
            }).then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err);
            });

        });
    }



    handleFacbookPayment(fbPaymentResponse, orderId) {
        return new Promise((resolve, reject) => {
            const params = {
                "payment_id": fbPaymentResponse.payment_id,
                "order_id": orderId
            };
            try {
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/store/v1/facebook/canvas/payment', requestBody: params, requestType: 'POST', appmode: 'USER', session: this.sessionTokenOfUser });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in handleFacbookPayment');
                }).then((data) => {
                    this.updateUserInfo();
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });



            } catch (error) {
                reject(error);
            }
        })
    }

    validateAndRetrieveHandler(userId, platform) {
        return new Promise((resolve, reject) => {
            try {
                    const ValidateAndRetrieveRequestModel = {
                        "fbuid": platform !== PLATFORM_GSNCOM ? userId : null,
                        "gsnUserId": platform === PLATFORM_GSNCOM ? userId : null,
                    };

                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/gdpr/v1/validateandretrieve', requestBody: ValidateAndRetrieveRequestModel, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in validateAndRetrieveHandler');
                }).then((data) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            } catch (error) {
                reject(error);
            }

        });
    }

    userConsentInfoGSNCOM(credentials, country) {
        return new Promise((resolve, reject) => {
            try {
                    const userConsentRequestModel = {
                        credentials : credentials,
                        country : country,
                        timeZone : '',
                    };
                    let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/gdpr/v1/consent/retrieve', requestBody: userConsentRequestModel, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in userConsentInfoGSNCOM');
                }).then((data) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });

            } catch (error) {
                reject(error);
            }

        });
    }
    userConsentInfo(facebookResposne, country) {
        return new Promise((resolve, reject) => {
            try {
                    const userConsentRequestModel = {
                        credentials : [{
                            type: 'facebook',
                            facebookId: facebookResposne.authResponse.userID,
                            accessToken: facebookResposne.authResponse.accessToken,
                        }],
                        country : country,
                        timeZone : '',
                    };
                    let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/gdpr/v1/consent/retrieve', requestBody: userConsentRequestModel, requestType: 'POST', appmode: 'APP' });
                    this.doApiCall(body).then((response) => {
                        if (response.ok) {
                            return response.json()
                        }
                        throw new Error('Something went wrong in userConsentInfo');
                    }).then((data) => {
                        resolve(data);
                    }).catch((err) => {
                        reject(err);
                    });


            } catch (error) {
                reject(error);
            }

        });
    }

    recordUserConsent(consent, seamUserId, sessionToken){
        return new Promise((resolve, reject) => {
            try {
                    const userConsentRecordModel = {
                        seamUserId : seamUserId,
                        consent : consent,
                    };

                    let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/gdpr/v1/consent/record', requestBody: userConsentRecordModel, requestType: 'POST', appmode: 'USER', session: sessionToken });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in recordUserConsent');
                }).then((data) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });

            } catch (error) {
                reject(error);
            }

        });
    }



    getCountryCodeByIPHandler() {
        return new Promise((resolve, reject) => {
            try {
                    let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/gdpr/v1/country/code',  requestType: 'GET', appmode: 'APP'});
                    this.doApiCall(body).then((response) => {
                        if (response.ok) {
                            return response.text()
                        }
                        throw new Error('Something went wrong in country code handler');
                    }).then((data) => {
                        resolve(String(data))
                        })
                        .catch((err) => {
                            reject(err);
                        });
            } catch (error) {
                reject(error);
            }

        });
    }

    //Service that gets user lifetime purchase amount
    getUserBTDs(seamUserId, sessionToken){
        return new Promise((resolve, reject) => {
            try {
                    let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/balance/v1/balance/seam/classic-casino/user/'+seamUserId+'/payment',  requestType: 'GET', appmode: 'USER', session: sessionToken });
                    this.doApiCall(body).then((response) => response.json())
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((err) => {
                            reject(err);
                        });

            } catch (error) {
                reject(error);
            }

        });
    }

    getPromisifyData(data) {
        return new Promise((resolve, reject) => {
            resolve(data);
        });
    }

    optInHandler(fbdata, platform) {
        return new Promise((resolve, reject) => {
            try {
                const params = {
                    "fbuid": platform !== PLATFORM_GSNCOM  ? fbdata : null,
                    "gsnUserId": platform === PLATFORM_GSNCOM ? fbdata : null
                };
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/gdpr/v1/optin', requestBody: params, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in optInHandler');
                }).then((data) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });



            } catch (error) {
                reject(error);
            }

        });
    }

    optOutHandler(fbdata) {
        return new Promise((resolve, reject) => {
            try {
                const params = {
                    "fbuid": fbdata.id
                };
                let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/gdpr/v1/optout', requestBody: params, requestType: 'POST', appmode: 'APP' });
                this.doApiCall(body).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Something went wrong in optOutHandler');
                }).then((data) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            } catch (error) {
                reject(error);
            }

        });
    }

    getOrderSignature(payload,sessionToken){
        return new Promise((resolve, reject) => {
            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : 'store/v1/orders/'+ payload.item.item_id +'/signature',  requestType: 'GET', appmode: 'USER', session: sessionToken });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in getOrderSignature');
            }).then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err);
            });
        });
    }
    getRollOutFC(sessionToken){
        return new Promise((resolve, reject) => {
            const appver = this.getUnityAppVersion();
            const featureContextConfigModel = {
                keys: [FC_WEBGL_ROLLOUT],
                context:{"user":{},"device":{"appVersion":appver}}
            }
            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/user', query:'?keys=%5B%22canvas.casino.featureConfig.webglRollout%22%5D', requestBody: featureContextConfigModel, requestType: 'GET', appmode: 'USER', session: sessionToken });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in getRollOutFC');
            }).then((data) => {
                this.loadedwebglbuild = JSON.parse(data[0].value[FC_WEBGL_ROLLOUT]).version;
                resolve(data[0].value[FC_WEBGL_ROLLOUT]);
            }).catch((err) => {
                reject(err);
            });


        });
    }
    getIsClearDbRequired(sessionToken){
        return new Promise((resolve, reject) => {
            const appver = this.getUnityAppVersion();
            const featureContextConfigModel = {
                keys: [FC_CLEAR_INDEXDB],
                context:{"user":{},"device":{"appVersion":appver}}
            }
            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/user', query:'?keys=%5B%22canvas.casino.featureConfig.isClearIndexdbRequired%22%5D', requestBody: featureContextConfigModel, requestType: 'GET', appmode: 'USER', session: sessionToken });
            this.doApiCall(body).then((response) => response.json())
                .then((data) => {
                    console.log("Test2", data, data[0].value[FC_CLEAR_INDEXDB] );
                    resolve(data[0].value[FC_CLEAR_INDEXDB]);
                })
                .catch((err) => {
                    console.log("Test2", err );
                    reject(err);
                });

        });
    }

    getIsPlaygamiPayment(sessionToken){
        return new Promise((resolve, reject) => {

            const appver = this.getUnityAppVersion();
            const featureContextConfigModel = {
                keys: [FC_ISPLAYGAMI_PAYMENT],
                context:{"user":{},"device":{"appVersion":appver}}
            }

            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in getIsPlaygamiPayment');
            }).then((data) => {
                resolve(data[0].value[FC_ISPLAYGAMI_PAYMENT]);
            }).catch((err) => {
                reject(err);
            });


        });
    }

    getWebAdsProvider(){
        return new Promise((resolve, reject) => {
            const appver = this.getUnityAppVersion();
            const featureContextConfigModel = {
                keys: [FC_WEB_ADS_PROVIDER],
                context:{"user":{},"device":{"appVersion":appver}}
            }
            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/user', query:'?keys=%5B%22canvas.casino.featureConfig.webAdsProvider%22%5D', requestBody: featureContextConfigModel, requestType: 'GET', appmode: 'USER', session: this.sessionTokenOfUser });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in getWebAdsProvider');
            }).then((data) => {
                resolve(data[0].value[FC_WEB_ADS_PROVIDER]);
            }).catch((err) => {
                reject(err);
            });


        });
    }

    getFeatureConfigMigrationPopups(sessionToken){
        return new Promise((resolve, reject) => {
            const appver = this.getUnityAppVersion();
            const featureContextConfigModel = {
                keys: [FC_LOADING_MIGRATIONPOPUP],
                context:{"user":{},"device":{"appVersion":appver}}
            }

            let  body = JSON.stringify({product: {game_type: 'CASINO'}, target : '/featureconfig/v2/context', requestBody: featureContextConfigModel, requestType: 'POST', appmode: 'APP' });
            this.doApiCall(body).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Something went wrong in getFeatureConfigMigrationPopups');
            }).then((data) => {
                resolve(data[0].value[FC_LOADING_MIGRATIONPOPUP]);
            }).catch((err) => {
                reject(err);
            });



        });
    }
}

export default new SeamServices();
