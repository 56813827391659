'use strict';
import { Logger, LOG_LEVEL } from 'beaver-logger';

const $logger = Logger({
    prefix: '[canvas2.0]',
    logLevel: LOG_LEVEL.INFO,
    url: '/client/log',
});

export default $logger;
